import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

export default class TabMoneyOwnerExpend extends Component{
  constructor(props){
    super(props);
    this.state={
      searchs:{
          cargoId:'',
          clauseId:-1,
          accountId:-1,
          companyId:-1,
          payState:-1,
          startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
          endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
      },
      expendSearchDtoObj:{},
      wholesaleCargosData:[],
      ownerExpendsAll:[],
      accountsAll:[],
      ownerExpendItemList:[],
      companysAll:[],
      totalNum:'',//总记录数
      currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
      goValue:'',
      totalPage:'',//总{stringUtil.language(1199)}数
      addShowPop:false,addShowPopIndex:0,
      infoShowPop:false,
      delShowPop:false,
      ownerExpendInsertItem:{},
      currOwnerExpendItem:{},
      keyTagVal:-1,
    };
  }
  componentDidMount(){
    this.getDataList();
    stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({ownerExpendsAll:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
          state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
          cargoMode:-1,
          currPage:this.state.currentPage-1,
          pageSize:1000
      },['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              let ownerCargosResultObj= successResponse.data;
              this.state.wholesaleCargosData = ownerCargosResultObj.items;
              this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.accountsAll= successResponse.data;
              this.setState({accountsAll: this.state.accountsAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.OAUTH_COMPANY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.companysAll=successResponse.data;
                this.state.companysAll.unshift({id:0,noteName:stringUtil.language(310)});
                this.setState({companysAll:this.state.companysAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  getDataList(){
      stringUtil.httpRequest(urlUtil.FINANCE_OWNER_EXPEND_URL,{
          cargoId:this.state.searchs.cargoId,
          clauseId:this.state.searchs.clauseId,
          accountId:this.state.searchs.accountId,
          payState:this.state.searchs.payState,
          companyId:this.state.searchs.companyId,
          startTime:arithUtil.settleSub(this.state.searchs.startTime),
          endTime:arithUtil.settleSub(this.state.searchs.endTime),
          currPage:this.state.currentPage-1,
          pageSize:20
      },['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.expendSearchDtoObj = successResponse.data;
              this.state.totalNum = this.state.expendSearchDtoObj.count;
              this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
              this.state.ownerExpendItemList = JSON.parse(JSON.stringify(this.state.expendSearchDtoObj.items));
              this.setState({
                  totalNum:this.state.totalNum,
                  totalPage:this.state.totalPage,
                  ownerExpendItemList:this.state.ownerExpendItemList
              });
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClick(){//上一步
      var _this = this;
      let cur = this.state.currentPage;
      if(cur > 1) _this.pageClick( cur - 1);
  }
  goNext(){//下一步
      var _this = this;
      let cur = _this.state.currentPage;
      if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
  }
  goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
    var _this= this;
    _this.setState({goValue : e.target.value})
    var value = e.target.value;
    if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
    else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
    else _this.pageClick(value);
  }
  pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
      let _this = this;
      if(pageNum != _this.state.currentPage){
          _this.state.currentPage = pageNum
      }
      this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:7,paddingRight:7}}>
            <select style={{width:180,height:32,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                this.state.searchs.cargoId = JSON.parse(event.target.value).id;
                this.state.searchs.targetType = JSON.parse(event.target.value).cargoMode;
                this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                this.state.searchs.targetName = JSON.parse(event.target.value).targetName;
                this.state.searchs.cargoNo = JSON.parse(event.target.value).cargoNo;
                this.getDataList();
            }}><option value={JSON.stringify({id:'',cargoNo:null})}>{stringUtil.language(571)}</option>
                {this.state.wholesaleCargosData.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.cargoNo?(item.cargoMode==1?stringUtil.language(1051)+'_'+item.targetId:item.targetName)+' / '+item.cargoNo:stringUtil.language(571)}</option>)
                })}
            </select>
            <select style={{width:120,height:32,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                this.state.searchs.clauseId = JSON.parse(event.target.value).id;
                this.getDataList();
            }}><option value={JSON.stringify({id:-1,name:stringUtil.language(702)})}>{stringUtil.language(702)}</option>
                {this.state.ownerExpendsAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                })}
            </select>
            <select style={{width:120,height:32,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                this.state.searchs.accountId = JSON.parse(event.target.value).id;
                this.getDataList();
            }}><option value={JSON.stringify({id:-1,name:stringUtil.language(695)})}>{stringUtil.language(695)}</option>
                {this.state.accountsAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                })}
            </select>
            <Select showSearch defaultValue={stringUtil.language(710)} style={{width:150,height:32,fontSize:12}} onChange={(event)=>{
                this.state.searchs.companyId = JSON.parse(event).id;
                this.getDataList();
            }}><option value={JSON.stringify({id:-1,noteName:stringUtil.language(710)})}>{stringUtil.language(710)}</option>
                {this.state.companysAll.map((item,key)=>{
                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                })}
            </Select>
            <select style={{width:70,height:32,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                this.state.searchs.payState = JSON.parse(event.target.value).payState;
                this.getDataList();
            }}><option value={JSON.stringify({payState:-1,txt:stringUtil.language(1097)})}>{stringUtil.language(1097)}</option>
                {[{payState:0,txt:stringUtil.language(982)},{payState:1,txt:stringUtil.language(983)}].map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.txt}</option>)
                })}
            </select>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
                <div style={styles.time}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                        this.getDataList();
                    }} />
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                </div> {stringUtil.language(1174)} <div style={styles.time}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                        this.getDataList();
                    }} />
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                </div>
            </div>
        </div>
        <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
            <div style={{width:'85%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1139)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#A9A9A9'})}>{stringUtil.language(456)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#A9A9A9'})}>{stringUtil.language(711)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1041)}</span>
            </div>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:'77%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
            {this.state.ownerExpendItemList.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <div style={{width:'85%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',textAlign:'left'})}>KZ.{item.ownerExpendNo}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.clauseName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',textAlign:'left'})}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',textAlign:'left'})}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#F00',fontSize:15,fontWeight:'bold',textAlign:'right'})}>{arithUtil.fenToYuan(item.money)}</span>
                        </div>
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'43%',color:'#808080',fontSize:13,textAlign:'left'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                            <span style={{width:'20%',color:'#808080',fontSize:13}}>{item.cargoNo}</span>
                            <div style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <span style={{color:'#808080',fontSize:13}}>{item.companyName?stringUtil.subStr(item.companyName,10):''}</span>
                                <span style={{color:'#808080',fontSize:13}}>{item.accountName}{item.companyId&&!item.payState?'('+arithUtil.formatToTimeStr(new Date(item.payTime),'Y-M-D')+')'+stringUtil.language(1193):''}</span>
                            </div>
                        </div>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                        <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.currOwnerExpendItem = JSON.parse(JSON.stringify(item));
                            this.setState({
                                infoShowPop: !this.state.infoShowPop,
                                currOwnerExpendItem:this.state.currOwnerExpendItem
                            });
                        }}>&#xe64c;</i>
                        {otherUtil.authorityFindCode("030304")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currOwnerExpendItem = JSON.parse(JSON.stringify(item));
                            this.setState({
                                delShowPop: !this.state.delShowPop,
                                currOwnerExpendItem:this.state.currOwnerExpendItem
                            });
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                        {item.payState?<div style={{width:60,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.ownerExpendInsertItem = {id:item.id};
                            this.setState({addShowPopIndex:this.state.addShowPopIndex+1,delayAddShowPop: !this.state.delayAddShowPop});
                        }}><div style={{width:'95%',height:30,backgroundColor:'#268f6b',borderRadius:3,display:'flex',justifyContent:'center',alignItems:'center'}}><span style={{color:'#FFF',fontSize:12}}>{stringUtil.language(1043)}</span></div>
                        </div>:<div style={{width:60,height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:10,color:'#C0C0C0'}}>—</span></div>}
                    </div>
                </div>);
            })}
        </div>
        <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
            {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                current={this.state.currentPage}
                totalPage={this.state.totalPage}
                goValue={this.state.goValue}
                pageClick={this.pageClick.bind(this)}
                goPrev={this.goPrevClick.bind(this)}
                goNext={this.goNext.bind(this)}
                switchChange={this.goSwitchChange.bind(this)}/>:''}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                {otherUtil.authorityFindCode("030302")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer',marginRight:20}} onClick={()=>{
                    this.state.ownerExpendInsertItem = {time:arithUtil.getCurrDateTime(0)};
                    this.setState({ addShowPopIndex:this.state.addShowPopIndex+1,addShowPop: !this.state.addShowPop, });
                }}>
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                    <span style={{color:'#52A056'}}> {stringUtil.language(243)}</span>
                </div>:<div style={{position:'absolute'}}/>}
                <span style={{color:'#F00'}}>{stringUtil.language(1182)}{this.state.totalNum}{stringUtil.language(1086)}{arithUtil.fenToYuan(this.state.expendSearchDtoObj.money)}{stringUtil.currency(1)}</span>
            </div>
        </div>
        <MaskModal title={stringUtil.language(244)} visible={this.state.delayAddShowPop?true:false} width={arithUtil.pxToDp(400)}
            content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(317)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.accountId = JSON.parse(event.target.value).id;
                            this.state.ownerExpendInsertItem.accountName = JSON.parse(event.target.value).name;
                          }}><option style={{display:'none'}}></option>
                          {this.state.accountsAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                          })}
                        </select>
                    </div>
                </div>}
            okBnEvents={(event) => {
                if (this.state.ownerExpendInsertItem.accountId==null) {
                    ToastBox.error(stringUtil.language(95));return;
                }
                this.setState({ delayAddShowPop: false });
                stringUtil.httpRequest(urlUtil.FINANCE_OWNER_EXPEND_PAY_URL+'/'+this.state.ownerExpendInsertItem.id,{accountId:this.state.ownerExpendInsertItem.accountId},['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({ delayAddShowPop: false, });}}/>
        <MaskModal title={stringUtil.language(243)} visible={this.state.addShowPop} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
            content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={styles.modalRow}>
                        <span style={{width:100}}>{stringUtil.language(311)}</span>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.ownerExpendInsertItem.time)} onChange={(date) => {
                          this.state.ownerExpendInsertItem.time = arithUtil.formatToTimeStr(date,'Y-M-D h:m:s');
                          this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                        }} />
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(312)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.cargoId = JSON.parse(event.target.value).id;
                            this.state.ownerExpendInsertItem.targetType = JSON.parse(event.target.value).cargoMode;
                            this.state.ownerExpendInsertItem.targetId = JSON.parse(event.target.value).targetId;
                            this.state.ownerExpendInsertItem.targetName = JSON.parse(event.target.value).targetName;
                            this.state.ownerExpendInsertItem.cargoNo = JSON.parse(event.target.value).cargoNo;
                          }}><option style={{display:'none'}}></option>
                          {this.state.wholesaleCargosData.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.cargoMode==1?stringUtil.language(426)+'_'+item.targetId:item.targetName} / {item.cargoNo}</option>)
                          })}
                        </select>
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(313)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.clauseId = JSON.parse(event.target.value).id;
                            this.state.ownerExpendInsertItem.clauseName = JSON.parse(event.target.value).name;
                          }}><option style={{display:'none'}}></option>
                          {this.state.ownerExpendsAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                          })}
                        </select>
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(314)}</span>
                        <Select showSearch defaultValue={stringUtil.language(310)} style={{width:150,height:30,fontSize:10}} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.companyId = JSON.parse(event).id;
                            this.state.ownerExpendInsertItem.companyName = JSON.parse(event).noteName;
                        }}>
                            {this.state.companysAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                        </Select>
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(315)}</span>
                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(200)})} type='number' placeholder='0' onChange={(event)=>{
                            this.state.ownerExpendInsertItem.money = arithUtil.yuanToFen(event.target.value);
                        }}/><span> {stringUtil.currency(1)}</span>
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(316)}</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.ownerExpendInsertItem.payState = 0;
                                this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                            }}>{!this.state.ownerExpendInsertItem.payState?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(531)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(531)}</i>}</div>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                this.state.ownerExpendInsertItem.payState = 1;
                                this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                            }}>{this.state.ownerExpendInsertItem.payState?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(532)}</i>:
                            <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(532)}</i>}</div>
                        </div>
                    </div>
                    <div style={styles.modalRow}>
                        <span>{stringUtil.language(317)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.accountId = JSON.parse(event.target.value).id;
                            this.state.ownerExpendInsertItem.accountName = JSON.parse(event.target.value).name;
                          }}><option style={{display:'none'}}></option>
                          {this.state.accountsAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                          })}
                        </select>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.modalRow,{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'})}>
                        <span>{stringUtil.language(318)}</span>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:'70%',height:arithUtil.pxToDp(70),textAlign:'left',textAlignVertical:'top'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                            this.state.ownerExpendInsertItem.describe = event.target.value;
                        }}/>
                    </div>
                </div>}
            okBnEvents={(event) => {
                if (this.state.ownerExpendInsertItem.clauseId==null) {
                    ToastBox.error(stringUtil.language(116));return;
                }
                if (this.state.ownerExpendInsertItem.cargoId==null) {
                    ToastBox.error(stringUtil.language(117));return;
                }
                if (!this.state.ownerExpendInsertItem.payState&&this.state.ownerExpendInsertItem.accountId==null) {
                    ToastBox.error(stringUtil.language(95));return;
                }
                if (this.state.ownerExpendInsertItem.money==null||this.state.ownerExpendInsertItem.money==0) {
                    ToastBox.error(stringUtil.language(118));return;
                }
                if(this.state.ownerExpendInsertItem.payState&&!this.state.ownerExpendInsertItem.companyId){
                    ToastBox.error(stringUtil.language(119));return;
                }
                this.setState({ addShowPop: false });
                let ownerExpendInsertVo = {
                    time:arithUtil.timeToNumber(this.state.ownerExpendInsertItem.time,2),
                    clauseId:this.state.ownerExpendInsertItem.clauseId,
                    targetType:this.state.ownerExpendInsertItem.targetType,
                    targetId:this.state.ownerExpendInsertItem.targetId,
                    cargoId:this.state.ownerExpendInsertItem.cargoId,
                    accountId:this.state.ownerExpendInsertItem.accountId,
                    money:this.state.ownerExpendInsertItem.money,
                    companyId:this.state.ownerExpendInsertItem.companyId,
                    payState:this.state.ownerExpendInsertItem.payState,
                    describe:this.state.ownerExpendInsertItem.describe!=null?this.state.ownerExpendInsertItem.describe:'',
                };
                stringUtil.httpRequest(urlUtil.FINANCE_OWNER_EXPEND_URL,ownerExpendInsertVo,['json','post',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({ addShowPop: false, });}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(75)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                stringUtil.httpRequest(urlUtil.FINANCE_OWNER_EXPEND_URL+'/'+this.state.currOwnerExpendItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        <MaskModal title={stringUtil.language(706)+'（KZ.'+this.state.currOwnerExpendItem.ownerExpendNo+'）'} visible={this.state.infoShowPop} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
            content={<div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    {this.state.currOwnerExpendItem.describe}
                </div>}
            closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  textInput:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5),
      textAlign:'center',
      textAlignVertical:'center'
  },
  tableTitle:{
    borderStyle:'solid',
      borderColor:'#F0F0F0',
      borderWidth: 1,
      backgroundColor:'#F2F2F2',
      display:'flex',
      flexDirection:'row',
      alignItems:'center'
  },
  tableTd:{
      fontSize:15,
      textAlign:'center',
      textAlignVertical:'center',
  },
  time:{
    height:32,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'#A9A9A9',
    borderRadius:3,
    paddingLeft:3,
    paddingRight:3
},
  modalRow:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      marginTop:arithUtil.pxToDp(10),
  },
};