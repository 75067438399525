import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';

export default class TabHome extends Component{
  constructor(props){
    super(props);
    this.state={
      zhishangMessages:[
        {
          content:stringUtil.language(1186),
          time:'2021-12-15'
        },{
          content:stringUtil.language(1186),
          time:'2021-12-15'
        },{
          content:stringUtil.language(1186),
          time:'2021-12-15'
        },{
          content:stringUtil.language(1186),
          time:'2021-12-15'
        },
      ]
    };
  }
  componentDidMount(){
    stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        localStorage.setItem("shopConfigInfo",JSON.stringify(successResponse.data));//设置缓存
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%'}}>
        <div style={{width:'79.5%',height:'100%'}}>
          <div style={{height:'6%',display:'flex',alignItems:'center'}}><span style={{color:'#696969',fontSize:18}}>{stringUtil.language(654)}</span></div>
          <div style={{height:'60%',backgroundColor:'#FFF',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img style={{width:'95%',height:'95%'}} loading="lazy" src={require('../images/home_bg.png').default} title={stringUtil.language(84)}/>
          </div>
          <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
            <span style={{color:'#696969',fontSize:18}}>{stringUtil.language(655)}</span>
            <span style={{color:'#A9A9A9',fontSize:18}}>{stringUtil.language(1133)}&nbsp;&gt;&gt;&nbsp;&nbsp;</span>
          </div>
          <div style={{height:'27%',backgroundColor:'#FFF',paddingLeft:10,paddingRight:10}}>
              {this.state.zhishangMessages.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth: 1,borderBottomColor:'#DCDCDC'}} key={key}>
                  <span style={{color:'#808080'}}>{item.content}</span>
                  <span style={{color:'#A9A9A9'}}>{item.time}</span>
                </div>)
              })}
          </div>
        </div>
        <div style={{width:'20%',overflow:'auto',marginLeft:'0.5%',height:'100%',backgroundColor:'#FFF'}}><div style={{height:35,color:'#696969',fontSize:20,marginLeft:15,display:'flex',alignItems:'center'}}>{stringUtil.language(656)}</div>
            <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersListActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1202)}</span>
              </div>
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersWarehouseActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1203),navigatePage:'OrdersWarehouseActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1203)}</span>
              </div>
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersWarehousesActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(1217),navigatePage:'OrdersWarehousesActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1217)}</span>
              </div>
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DateSettleActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(480),navigatePage:'DateSettleActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(480)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DateCollectExpendActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(229),navigatePage:'DateCollectExpendActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(473)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'DebtCustomerActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(230),navigatePage:'DebtCustomerActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(230)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040301")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CollectPayAccountsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(284),navigatePage:'CollectPayAccountsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(284)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040401")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSettleSummaryActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(214),navigatePage:'OwnerSettleSummaryActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(657)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040501")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerAccountsDetailsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(197),navigatePage:'CustomerAccountsDetailsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(658)}</span>
              </div>:''}
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SupplierAccountsDetailsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(163),navigatePage:'SupplierAccountsDetailsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(342)}</span>
              </div>
              {otherUtil.authorityFindCode("040601")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CustomerSaleSettleActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(200),navigatePage:'CustomerSaleSettleActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(200)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040701")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(232),navigatePage:'SaleStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(659)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040801")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleProfitLossActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(233),navigatePage:'SaleProfitLossActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(598)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040801")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ShopProfitLossActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(234),navigatePage:'ShopProfitLossActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(622)}</span>
              </div>:''}
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleProfitActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(235),navigatePage:'SaleProfitActivity',paramsPage:'参数内容'}
                ]}});
              }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(596)}</span>
              </div>
              {otherUtil.authorityFindCode("040901")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleTimingActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(221),navigatePage:'SaleTimingActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(660)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041001")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'PurchaseStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(236),navigatePage:'PurchaseStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(581)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'WorkerSelfSortActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(343),navigatePage:'WorkerSelfSortActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(343)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BuyerSortActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(91),navigatePage:'BuyerSortActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(344)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSaleStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(113),navigatePage:'OwnerSaleStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(213)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041201")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OwnerSaleStatistics',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(114),navigatePage:'OwnerSaleStatistics',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(213)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041301")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleDateActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(237),navigatePage:'SaleDateActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(237)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("041401")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'BuyerStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(282),navigatePage:'BuyerStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(282)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("040101")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'SaleReportDailyActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(345),navigatePage:'SaleReportDailyActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(345)}</span>
              </div>:''}
              {otherUtil.authorityFindCode("0203")!=null?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessStatisticsActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(106),navigatePage:'ProcessStatisticsActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(238)}</span>
              </div>:''}
              <div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'InOutWarehouseActivity',mainStacks:[
                  {title:stringUtil.language(1128),navigatePage:''},
                  {title:stringUtil.language(150),navigatePage:'InOutWarehouseActivity',paramsPage:'参数内容'}
                ]}});
              }}>
                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#2882FF',marginLeft:12,marginRight:7}}>&#xe644;</i>
                <span style={{color:'#2882FF',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(239)}</span>
              </div>
            </div>
        </div>
      </div>)
  }
}

const styles = {};