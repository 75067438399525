import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(622)}
 export default class ShopProfitLossActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            timeTag:3,//0今stringUtil.language(1179)，1本stringUtil.language(1178)，2今年，3stringUtil.language(1067)段
            startTime:arithUtil.getCurrDateTime(1),
            endTime:arithUtil.getCurrDateTime(1),
        },
        shopProfitDO:{},
        titleAgentSales:[],
        titleSelfSupports:[],
        titleStirFries:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SHOP_PROFIT_URL,{
            mode:this.state.searchs.timeTag,
            startDate:arithUtil.formatToTime(this.state.searchs.startTime,'Y-M-D'),
            endDate:arithUtil.formatToTime(this.state.searchs.endTime,'Y-M-D')
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.shopProfitDO = successResponse.data;
                let titleAgentSales = {};
                if (this.state.shopProfitDO.agentSales) {
                    for(let i=0;i<this.state.shopProfitDO.agentSales.length;i++){
                        let row = this.state.shopProfitDO.agentSales[i];
                        for(let j=0;j<row.costs.length;j++){
                            if (!titleAgentSales.hasOwnProperty(row.costs[j].costClauseId)) titleAgentSales[row.costs[j].costClauseId] = row.costs[j].costClauseName;
                        }
                    }
                }
                this.state.titleAgentSales = [];
                for(var key in titleAgentSales){
                    this.state.titleAgentSales.push({
                        costClauseId:key,
                        costClauseName:titleAgentSales[key]
                    });
                }
                let titleSelfSupports = {};
                if (this.state.shopProfitDO.selfSupports) {
                    for(let i=0;i<this.state.shopProfitDO.selfSupports.length;i++){
                        let row = this.state.shopProfitDO.selfSupports[i];
                        for(let j=0;j<row.costs.length;j++){
                            if (!titleSelfSupports.hasOwnProperty(row.costs[j].costClauseId)) titleSelfSupports[row.costs[j].costClauseId] = row.costs[j].costClauseName;
                        }
                    }
                }
                this.state.titleSelfSupports = [];
                for(var key in titleSelfSupports){
                    this.state.titleSelfSupports.push({
                        costClauseId:key,
                        costClauseName:titleSelfSupports[key]
                    });
                }
                let titleStirFries = {};
                if (this.state.shopProfitDO.stirFries) {
                    for(let i=0;i<this.state.shopProfitDO.stirFries.length;i++){
                        let row = this.state.shopProfitDO.stirFries[i];
                        for(let j=0;j<row.costs.length;j++){
                            if (!titleStirFries.hasOwnProperty(row.costs[j].costClauseId)) titleStirFries[row.costs[j].costClauseId] = row.costs[j].costClauseName;
                        }
                    }
                }
                this.state.titleStirFries = [];
                for(var key in titleStirFries){
                    this.state.titleStirFries.push({
                        costClauseId:key,
                        costClauseName:titleStirFries[key]
                    });
                }
                this.setState({shopProfitDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:50}}>{stringUtil.language(888)}</span>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                            this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                            this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            let cols1=this.state.titleSelfSupports.length+3,cols2=this.state.titleAgentSales.length+5,cols3=this.state.titleStirFries.length+2,colspans=cols2;
                            if(cols1>colspans)colspans=cols1;if(cols3>colspans)colspans=cols3;
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="${colspans}"><b><font size="3">${localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}${stringUtil.language(622)}（${this.state.searchs.startTime} ${stringUtil.language(1174)} ${this.state.searchs.endTime}）</font></b></td></tr>`);
                            let selfSupportsTotals = 0;
                            if (this.state.shopProfitDO.selfSupports) {
                                for(let i=0;i<this.state.shopProfitDO.selfSupports.length;i++){
                                    let itema = this.state.shopProfitDO.selfSupports[i];
                                    selfSupportsTotals = selfSupportsTotals.add(itema.goodsMoney);
                                    for(let j=0;j<itema.costs.length;j++){
                                        selfSupportsTotals = selfSupportsTotals.add(itema.costs[j].money);
                                    }
                                }
                            }
                            resArr.push(`<tr><td></td></tr>
                            <tr><td align="left" style="color:#52A056" colspan="${colspans}"><b><font size="3">${stringUtil.language(623)}：${arithUtil.fenToYuan(selfSupportsTotals)}</font></b></td></tr>`);
                            resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1084)}</font></b></td>
                            <td align="left"><b><font size="3">${stringUtil.language(1068)}</font></b></td>`);
                            for(let key=0;key<this.state.titleSelfSupports.length;key++){
                                resArr.push(`<td align="left"><b><font size="3">${this.state.titleSelfSupports[key].costClauseName}</font></b></td>`);
                            }
                            resArr.push(`<td align="left"><b><font size="3">${stringUtil.language(945)}</font></b></td></tr>`);
                            for(let key=0;key<this.state.shopProfitDO.selfSupports.length;key++){let itema=this.state.shopProfitDO.selfSupports[key];
                                let rowMoneyTotal = itema.goodsMoney;
                                resArr.push(`<tr><td align="left"><font size="3">${itema.cargoNo}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(itema.goodsMoney)}</font></td>`);
                                for(let key=0;key<this.state.titleSelfSupports.length;key++){let itemb=this.state.titleSelfSupports[key];
                                    let moneyVal = 0;
                                    itema.costs.map((itemc,key)=>{
                                        if (itemc.costClauseId==itemb.costClauseId) {
                                            moneyVal = itemc.money;
                                            rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                        }
                                    });
                                    resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyVal)}</font></td>`);
                                }
                                resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(rowMoneyTotal)}</font></b></tr>`);
                            }
                            let agentSalesTotals = 0;
                            this.state.showDiff=false;
                            if (this.state.shopProfitDO.agentSales) {
                                for(let i=0;i<this.state.shopProfitDO.agentSales.length;i++){
                                    let itema = this.state.shopProfitDO.agentSales[i];
                                    if(!this.state.showDiff&&itema.diffGoodsMoney!=0){
                                        this.state.showDiff = true;
                                    }
                                    agentSalesTotals = (agentSalesTotals).add(itema.entrustMoney).add(itema.disburdenMoney);
                                    for(let j=0;j<itema.costs.length;j++){
                                        agentSalesTotals = (agentSalesTotals).add(itema.costs[j].money);
                                    }
                                }
                            }
                            resArr.push(`<tr><td></td></tr>
                            <tr><td align="left" style="color:#52A056" colspan="${colspans}"><b><font size="3">${stringUtil.language(624)}：${arithUtil.fenToYuan(agentSalesTotals)}</font></b></td></tr>`);
                            resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1049)}</font></b></td>
                            <td align="left"><b><font size="3">${stringUtil.language(1084)}</font></b></td>`);
                            for(let key=0;key<this.state.titleAgentSales.length;key++){
                                resArr.push(`<td align="left"><b><font size="3">${this.state.titleAgentSales[key].costClauseName}</font></b></td>`);
                            }
                            resArr.push(`<td align="left"><b><font size="3">`+JSON.parse(localStorage.getItem("currentStallItem")).disburdenText+`</font></b></td>
                            <td align="left"><b><font size="3">${stringUtil.language(941)}</font></b></td>`);
                            if(this.state.showDiff)resArr.push(`<td align="left"><b><font size="3">${stringUtil.language(538)}</font></b></td>`);
                            resArr.push(`<td align="left"><b><font size="3">${stringUtil.language(945)}</font></b></td></tr>`);
                            for(let key=0;key<this.state.shopProfitDO.agentSales.length;key++){let itema=this.state.shopProfitDO.agentSales[key];
                                let rowMoneyTotal = (itema.entrustMoney).add(itema.disburdenMoney);
                                if(this.state.showDiff)rowMoneyTotal = rowMoneyTotal.add(itema.diffGoodsMoney);
                                resArr.push(`<tr><td align="left"><font size="3">${itema.targetName}</font></td>
                                <td align="left"><font size="3">${stringUtil.language(1175)}${itema.cargoNo}${stringUtil.language(1176)}</font></td>`);
                                for(let key=0;key<this.state.titleAgentSales.length;key++){let itemb=this.state.titleAgentSales[key];
                                    let moneyVal = 0;
                                    itema.costs.map((itemc,key)=>{
                                        if (itemc.costClauseId==itemb.costClauseId) {
                                            moneyVal = itemc.money;
                                            rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                        }
                                    });
                                    resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyVal)}</font></td>`);
                                }
                                resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(itema.disburdenMoney)}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(itema.entrustMoney)}</font></td>`);
                                if(this.state.showDiff)resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(itema.diffGoodsMoney)}</font></td>`);
                                resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(rowMoneyTotal)}</font></td></tr>`);
                            }
                            let stirFriesTotals = 0;
                            if (this.state.shopProfitDO.stirFries) {
                                for(let i=0;i<this.state.shopProfitDO.stirFries.length;i++){
                                    let itema = this.state.shopProfitDO.stirFries[i];
                                    for(let j=0;j<itema.costs.length;j++){
                                        stirFriesTotals = stirFriesTotals.add(itema.costs[j].money);
                                    }
                                }
                            }
                            resArr.push(`<tr><td></td></tr>
                            <tr><td align="left" style="color:#52A056" colspan="${colspans}"><b><font size="3">${stringUtil.language(625)}：${arithUtil.fenToYuan(stirFriesTotals)}</font></b></td></tr>`);
                            resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1084)}</font></b></td>`);
                            for(let key=0;key<this.state.titleStirFries.length;key++){
                                resArr.push(`<td align="left"><b><font size="3">${this.state.titleStirFries[key].costClauseName}</font></b></td>`);
                            }
                            resArr.push(`<td align="left"><b><font size="3">${stringUtil.language(945)}</font></b></td></tr>`);
                            for(let key=0;key<this.state.shopProfitDO.stirFries.length;key++){let itema=this.state.shopProfitDO.stirFries[key];
                                let rowMoneyTotal = 0;
                                resArr.push(`<tr><td align="left"><font size="3">${itema.cargoNo}</font></td>`);
                                for(let key=0;key<this.state.titleStirFries.length;key++){let itemb=this.state.titleStirFries[key];
                                    let moneyVal = 0;
                                    itema.costs.map((itemc,key)=>{
                                        if (itemc.costClauseId==itemb.costClauseId) {
                                            moneyVal = itemc.money;
                                            rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                        }
                                    });
                                    resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(moneyVal)}</font></td>`);
                                }
                                resArr.push(`<td align="left"><font size="3">${arithUtil.fenToYuan(rowMoneyTotal)}</font></td></tr>`);
                            }
                            let salesTotals = 0,collectsTotals = 0;
                            if (this.state.shopProfitDO.sales) {
                                for(let i=0;i<this.state.shopProfitDO.sales.length;i++){
                                    salesTotals = salesTotals.add(this.state.shopProfitDO.sales[i].money);
                                }
                            }
                            if (this.state.shopProfitDO.collects) {
                                for(let i=0;i<this.state.shopProfitDO.collects.length;i++){
                                    collectsTotals = collectsTotals.add(this.state.shopProfitDO.collects[i].money);
                                }
                            }
                            resArr.push(`<tr><td></td></tr>
                            <tr><td align="left" style="color:#52A056" colspan="${colspans}"><b><font size="3">${stringUtil.language(626)}：${arithUtil.fenToYuan(salesTotals)}，${stringUtil.language(627)}：${arithUtil.fenToYuan(collectsTotals)}</font></b></td></tr>`);
                            for(let key=0,keyb=0;key<this.state.shopProfitDO.sales.length;key=key+4,keyb++){
                                let item0 = this.state.shopProfitDO.sales[key];
                                resArr.push(`<tr><td align="left"><font size="3">${item0.costClauseName+'：'+arithUtil.fenToYuan(item0.money)}</font></td>
                                <td><b><font size="3"></font></b></td>`);
                                if((key+1)<this.state.shopProfitDO.sales.length){
                                  let item1 = this.state.shopProfitDO.sales[key+1];
                                  resArr.push(`<td align="left"><font size="3">${item1.costClauseName+'：'+arithUtil.fenToYuan(item1.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                if((key+2)<this.state.shopProfitDO.sales.length){
                                  let item2 = this.state.shopProfitDO.sales[key+2];
                                  resArr.push(`<td align="left"><font size="3">${item2.costClauseName+'：'+arithUtil.fenToYuan(item2.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                resArr.push(`</tr>`);
                            }
                            for(let key=0,keyb=0;key<this.state.shopProfitDO.collects.length;key=key+4,keyb++){
                                let item0 = this.state.shopProfitDO.collects[key];
                                resArr.push(`<tr><td align="left"><font size="3">${item0.clauseName+'：'+arithUtil.fenToYuan(item0.money)}</font></td>
                                <td><b><font size="3"></font></b></td>`);
                                if((key+1)<this.state.shopProfitDO.collects.length){
                                  let item1 = this.state.shopProfitDO.collects[key+1];
                                  resArr.push(`<td align="left"><font size="3">${item1.clauseName+'：'+arithUtil.fenToYuan(item1.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                if((key+2)<this.state.shopProfitDO.collects.length){
                                  let item2 = this.state.shopProfitDO.collects[key+2];
                                  resArr.push(`<td align="left"><font size="3">${item2.clauseName+'：'+arithUtil.fenToYuan(item2.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                resArr.push(`</tr>`);
                            }
                            let investmentsTotals = 0;
                            if (this.state.shopProfitDO.investments) {
                                for(let i=0;i<this.state.shopProfitDO.investments.length;i++){
                                    investmentsTotals = (investmentsTotals).add(this.state.shopProfitDO.investments[i].money);
                                }
                            }
                            resArr.push(`<tr><td></td></tr>
                            <tr><td align="left" style="color:#52A056" colspan="${colspans}"><b><font size="3">${stringUtil.language(628)}：${arithUtil.fenToYuan(investmentsTotals)}</font></b></td></tr>`);
                            for(let key=0,keyb=0;key<this.state.shopProfitDO.investments.length;key=key+4,keyb++){
                                let item0 = this.state.shopProfitDO.investments[key];
                                let resStr='';
                                if(item0.cargoMode==1) resStr=resStr+stringUtil.language(426)+(item0.targetName?'_'+item0.targetName:'');
                                else resStr=resStr+item0.targetName;
                                resArr.push(`<tr><td align="left"><font size="3">${resStr+(item0.mode==0?stringUtil.language(1125):item0.mode==1?stringUtil.language(971):'')+':'+arithUtil.fenToYuan(item0.money)}</font></td>
                                <td><b><font size="3"></font></b></td>`);
                                if((key+1)<this.state.shopProfitDO.investments.length){
                                  let item1 = this.state.shopProfitDO.investments[key+1];
                                  let resStr='';
                                  if(item1.cargoMode==1) resStr=resStr+stringUtil.language(426)+(item1.targetName?'_'+item1.targetName:'');
                                  else resStr=resStr+item1.targetName;
                                  resArr.push(`<td align="left"><font size="3">${resStr+(item1.mode==0?stringUtil.language(1125):item1.mode==1?stringUtil.language(971):'')+':'+arithUtil.fenToYuan(item1.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                if((key+2)<this.state.shopProfitDO.investments.length){
                                  let item2 = this.state.shopProfitDO.investments[key+2];
                                  let resStr='';
                                  if(item2.cargoMode==1) resStr=resStr+stringUtil.language(426)+(item2.targetName?'_'+item2.targetName:'');
                                  else resStr=resStr+item2.targetName;
                                  resArr.push(`<td align="left"><font size="3">${resStr+(item2.mode==0?stringUtil.language(1125):item2.mode==1?stringUtil.language(971):'')+':'+arithUtil.fenToYuan(item2.money)}</font></td>
                                  <td><b><font size="3"></font></b></td>`);
                                }
                                resArr.push(`</tr>`);
                            }
                            resArr.push(`<tr style="height: 50px"><td align="right" colspan="${colspans}" style="color: #F00"><b><font size="6">${stringUtil.language(629)}：${arithUtil.fenToYuan(agentSalesTotals.add(selfSupportsTotals).add(stirFriesTotals).add(salesTotals).add(collectsTotals).sub(investmentsTotals))}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:'')+stringUtil.language(622)+'（'+this.state.searchs.startTime+' '+stringUtil.language(1174) +' '+this.state.searchs.endTime+'）');
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(622)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startTime+' '+stringUtil.language(1174)} +' '+this.state.searchs.endTime}</span>
                    </div>:''}
                    {(()=>{this.state.selfSupportsTotals = 0;
                        if (this.state.shopProfitDO.selfSupports) {
                            for(let i=0;i<this.state.shopProfitDO.selfSupports.length;i++){
                                let itema = this.state.shopProfitDO.selfSupports[i];
                                this.state.selfSupportsTotals = (this.state.selfSupportsTotals).add(itema.goodsMoney);
                                for(let j=0;j<itema.costs.length;j++){
                                    this.state.selfSupportsTotals = (this.state.selfSupportsTotals).add(itema.costs[j].money);
                                }
                            }
                        }
                        this.state.itemWidth = ((100).div(this.state.titleSelfSupports.length+3))+'%';
                    })()}
                    <span style={{height:'35px',lineHeight:'35px',color:'#52A056',fontSize:15,fontWeight:'bold',paddingLeft:10}}>{stringUtil.language(623)}：{arithUtil.fenToYuan(this.state.selfSupportsTotals)}</span>
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(1084)}</span>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(1068)}</span>
                        {this.state.titleSelfSupports.map((item,key)=>{
                            return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}} key={key}>{item.costClauseName}</span>)
                        })}
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(945)}</span>
                    </div>
                    {this.state.shopProfitDO.selfSupports?this.state.shopProfitDO.selfSupports.map((itema,key)=>{
                        let rowMoneyTotal = itema.goodsMoney;
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{itema.cargoNo}</span>
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(itema.goodsMoney)}</span>
                            {this.state.titleSelfSupports.map((itemb,key)=>{
                                let moneyVal = 0;
                                itema.costs.map((itemc,key)=>{
                                    if (itemc.costClauseId==itemb.costClauseId) {
                                        moneyVal = itemc.money;
                                        rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                    }
                                });
                                return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}} key={key}>{arithUtil.fenToYuan(moneyVal)}</span>);
                            })}
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(rowMoneyTotal)}</span>
                        </div>);
                    }):''}
                    {(()=>{this.state.agentSalesTotals = 0;
                        this.state.showDiff = false;
                        if (this.state.shopProfitDO.agentSales) {
                            for(let i=0;i<this.state.shopProfitDO.agentSales.length;i++){
                                let itema = this.state.shopProfitDO.agentSales[i];
                                if(!this.state.showDiff&&itema.diffGoodsMoney!=0){
                                    this.state.showDiff = true;
                                }
                                this.state.agentSalesTotals = (this.state.agentSalesTotals).add(itema.entrustMoney).add(itema.disburdenMoney);
                                this.state.agentSalesTotals = this.state.agentSalesTotals.add(itema.diffGoodsMoney);
                                for(let j=0;j<itema.costs.length;j++){
                                    this.state.agentSalesTotals = (this.state.agentSalesTotals).add(itema.costs[j].money);
                                }
                            }
                        }
                        this.state.itemWidth = ((100).div(this.state.titleAgentSales.length+(this.state.showDiff?6:5)))+'%';
                    })()}
                    <span style={{height:'35px',lineHeight:'35px',color:'#52A056',fontSize:15,fontWeight:'bold',paddingLeft:10,marginTop:20}}>{stringUtil.language(624)}：{arithUtil.fenToYuan(this.state.agentSalesTotals)}</span>
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(1084)}</span>
                        {this.state.titleAgentSales.map((item,key)=>{
                            return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}} key={key}>{item.costClauseName}</span>)
                        })}
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{JSON.parse(localStorage.getItem("currentStallItem")).disburdenText}</span>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(941)}</span>
                        {this.state.showDiff?<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(538)}</span>:""}
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(945)}</span>
                    </div>
                    {this.state.shopProfitDO.agentSales?this.state.shopProfitDO.agentSales.map((itema,key)=>{
                        let rowMoneyTotal = (itema.entrustMoney).add(itema.disburdenMoney);
                        if(this.state.showDiff)rowMoneyTotal = rowMoneyTotal.add(itema.diffGoodsMoney);
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:this.state.itemWidth,fontSize:10,fontWeight:'bold',textAlign:'center',textAlignVertical:'center'}}>{stringUtil.subStr(itema.targetName,)}</span>
                            <span style={{width:this.state.itemWidth,fontSize:10,fontWeight:'bold',textAlign:'center',textAlignVertical:'center'}}>{itema.cargoNo+stringUtil.language(1176)}</span>
                            {this.state.titleAgentSales.map((itemb,key)=>{
                                let moneyVal = 0;
                                itema.costs.map((itemc,key)=>{
                                    if (itemc.costClauseId==itemb.costClauseId) {
                                        moneyVal = itemc.money;
                                        rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                    }
                                });
                                return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center',textAlignVertical:'center'}} key={key}>{arithUtil.fenToYuan(moneyVal)}</span>)
                            })}
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center',textAlignVertical:'center'}}>{arithUtil.fenToYuan(itema.disburdenMoney)}</span>
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center',textAlignVertical:'center'}}>{arithUtil.fenToYuan(itema.entrustMoney)}</span>
                            {this.state.showDiff?<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center',textAlignVertical:'center'}}>{arithUtil.fenToYuan(itema.diffGoodsMoney)}</span>:""}
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center',textAlignVertical:'center'}}>{arithUtil.fenToYuan(rowMoneyTotal)}</span>
                        </div>);
                    }):''}
                    {(()=>{this.state.stirFriesTotals = 0;
                        if (this.state.shopProfitDO.stirFries) {
                            for(let i=0;i<this.state.shopProfitDO.stirFries.length;i++){
                                let itema = this.state.shopProfitDO.stirFries[i];
                                for(let j=0;j<itema.costs.length;j++){
                                    this.state.stirFriesTotals = (this.state.stirFriesTotals).add(itema.costs[j].money);
                                }
                            }
                        }
                        this.state.itemWidth = ((100).div(this.state.titleStirFries.length+2))+'%';
                    })()}
                    <span style={{height:'35px',lineHeight:'35px',color:'#52A056',fontSize:15,fontWeight:'bold',paddingLeft:10,marginTop:20}}>{stringUtil.language(625)}：{arithUtil.fenToYuan(this.state.stirFriesTotals)}</span>
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(1084)}</span>
                        {this.state.titleStirFries.map((item,key)=>{
                            return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}} key={key}>{item.costClauseName}</span>)
                        })}
                        <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{stringUtil.language(945)}</span>
                    </div>
                    {this.state.shopProfitDO.stirFries?this.state.shopProfitDO.stirFries.map((itema,key)=>{
                        let rowMoneyTotal = 0;
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{itema.cargoNo}</span>
                            {this.state.titleStirFries.map((itemb,key)=>{
                                let moneyVal = 0;
                                itema.costs.map((itemc,key)=>{
                                    if (itemc.costClauseId==itemb.costClauseId) {
                                        moneyVal = itemc.money;
                                        rowMoneyTotal = (rowMoneyTotal).add(itemc.money);
                                    }
                                });
                                return(<span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}} key={key}>{arithUtil.fenToYuan(moneyVal)}</span>)
                            })}
                            <span style={{width:this.state.itemWidth,fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(rowMoneyTotal)}</span>
                        </div>);
                    }):''}
                    {(()=>{this.state.salesTotals = 0;this.state.collectsTotals = 0;
                        if (this.state.shopProfitDO.sales) {
                            for(let i=0;i<this.state.shopProfitDO.sales.length;i++){
                                this.state.salesTotals = (this.state.salesTotals).add(this.state.shopProfitDO.sales[i].money);
                            }
                        }
                        if (this.state.shopProfitDO.collects) {
                            for(let i=0;i<this.state.shopProfitDO.collects.length;i++){
                                this.state.collectsTotals = (this.state.collectsTotals).add(this.state.shopProfitDO.collects[i].money);
                            }
                        }
                    })()}
                    <span style={{height:'35px',lineHeight:'35px',color:'#52A056',fontSize:15,fontWeight:'bold',paddingLeft:10,marginTop:20}}>{stringUtil.language(626)}：{arithUtil.fenToYuan(this.state.salesTotals)}，{stringUtil.language(627)}：{arithUtil.fenToYuan(this.state.collectsTotals)}</span>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',alignItems:'center',paddingLeft:10}}>
                        {this.state.shopProfitDO.sales?this.state.shopProfitDO.sales.map((item,key)=>{
                            return(<span style={{width:'33.3%',fontSize:12}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</span>);
                        }):''}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',alignItems:'center',paddingLeft:10}}>
                        {this.state.shopProfitDO.collects?this.state.shopProfitDO.collects.map((item,key)=>{
                            return(<span style={{width:'33.3%',fontSize:12}} key={key}>{item.clauseName+':'+arithUtil.fenToYuan(item.money)}</span>);
                        }):''}
                    </div>
                    {(()=>{this.state.investmentsTotals = 0;
                        if (this.state.shopProfitDO.investments) {
                            for(let i=0;i<this.state.shopProfitDO.investments.length;i++){
                                this.state.investmentsTotals = (this.state.investmentsTotals).add(this.state.shopProfitDO.investments[i].money);
                            }
                        }
                    })()}
                    <span style={{height:'35px',lineHeight:'35px',color:'#52A056',fontSize:15,fontWeight:'bold',paddingLeft:10,marginTop:20}}>{stringUtil.language(628)}：{arithUtil.fenToYuan(this.state.investmentsTotals)}</span>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',alignItems:'center',paddingLeft:10}}>
                        {this.state.shopProfitDO.investments?this.state.shopProfitDO.investments.map((item,key)=>{
                            let resStr='';
                            if(item.cargoMode==1) resStr=resStr+stringUtil.language(426)+(item.targetName?'_'+item.targetName:'');
                            else resStr=resStr+item.targetName;
                            // resStr=resStr+'{stringUtil.language(1175)}'+item.cargoNo+'{stringUtil.language(1176)}';
                            return(<span style={{width:'50%',fontSize:12}} key={key}>{resStr+(item.mode==0?stringUtil.language(1125):item.mode==1?stringUtil.language(971):'')+':'+arithUtil.fenToYuan(item.money)}</span>);
                        }):''}
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',borderTopColor:'#DCDCDC',borderTopWidth:1,paddingRight:10}}>
                        <span>{stringUtil.language(629)}：</span><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.agentSalesTotals).add(this.state.selfSupportsTotals).add(this.state.stirFriesTotals).add(this.state.salesTotals).add(this.state.collectsTotals).sub(this.state.investmentsTotals))}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};