const languages = {//中文
	"1": "“菜博士”是一个为蔬菜产供销流通全产业链提供经营管理服务的互联网APP平台。   “菜博士”可以帮助农产品批发市场档口商户应用Android设备进行管理，实现手机接货、销售开单、车次结算以及客户管理、收款、付款、转账等功能和档口经营财务自动核算，节约管理成本提升经营效率。 “菜博士”不是单纯的网上电商平台，也不是一个简单的记账管理软件，而是帮助农产品批发市场传统实体档口商户经营管理生意的软件工具。   “菜博士”能够加快农产品流通速度，提升农产品经营效率，节约农产品流通成本，推动农产品行业信息化建设应用。我们致力于打造农产品产供销全产业链的“互联网+农产品”大数据云平台。 卖菜帮——让生意更轻松！",
    "2": "上日结余 + 档口账户收款 - 档口账户付款 + 老板下放款 - 收银上交款 = 收银结余",
    "3": "档口账户收款  -  档口账户付款 + 老板下放款 - 收银上交款 =  收银结余",
    "4": "提交后无法修改，请检查用料数量和成本是否正确？",
    "5": "©2022 广东卖菜帮软件有限公司 版权所有",
    "6": "卖菜帮云服务平台——搜索档口，申请成为员工",
    "7": "先在此添加批次，再到采购中添加商品！",
    "8": "确定盘点信息与实际商品存储量一致？",
    "9": "检查，如有其他遗漏开支，请点击录入",
    "10": "加工品与完成品的用料成本不相等！",
    "11": "卖菜帮云服务平台——注册新档口",
    "12": "请输入备注信息..(非必填)",
    "13": "确定申请成为该档口店铺员工？",
    "14": "点击下列对应图标，下载使用：",
    "15": "是否同意该人员成为您的员工？",
    "16": "请输入档口名称或手机号..",
    "17": "选择资金账户，完成客户收款",
    "18": "暂无关联的销售单！",
    "19": "Y年M月D日 h时m分s秒",
    "20": "此销售单存在收款，不能删除",
    "21": "如需删除，请先删对应收款单",
    "22": "商品无可提交的有效数据！",
    "23": "输入的两次新密码不一致！",
    "24": "确定删除该供应商付款单？",
    "25": "不得大于剩余未编辑成本",
    "26": "正在执行，请稍后...",
    "27": "确定删除该货主付款单？",
    "28": "确定删除该自营批次线路",
    "29": "商品无可提交的有效数据",
    "30": "序号不得超过列表长度！",
    "31": "请输入用户联系电话！",
    "32": "请输入关键字搜索..",
    "33": "请选择自动带入费用项",
    "34": "确定删除该盘点记录？",
    "35": "请输入商品名搜索..",
    "36": "请再次输入新密码..",
    "37": "新密码不能小于6位！",
    "38": "新密码不能含有空格！",
    "39": "确定删除该加工记录？",
    "40": "确定删除该收发记录？",
    "41": "密码必须大于6个字！",
    "42": "两次输入密码不一致！",
    "43": "确定删除该开支记录？",
    "44": "确定删除该转账记录？",
    "45": "确定删除该加工计划？",
    "46": "确定撤销此批次的转接",
    "47": "自动带入费用批量编辑",
    "48": "请选择商品的标准名称",
    "49": "请输入自营线路备注名",
    "50": "高危客户，不能开单！",
    "51": "正在排序，搜索无效！",
    "52": "自定义添加其他费用！",
    "53": "请输入销售员搜索..",
    "54": "正在执行，请稍后..",
    "55": "请输入您的用户称呼",
    "56": "请输入联系电话..",
    "57": "请输入档口名称..",
    "58": "请输入详细地址..",
    "59": "手机号格式不正确！",
    "60": "添加商品（非必选）",
    "61": "请输入身份证号..",
    "62": "页面导航出现异常！",
    "63": "确定撤销档口申请？",
    "64": "确定撤销员工申请？",
    "65": "确定删除该采购单？",
    "66": "请输入标题内容..",
    "67": "请输入备注名称..",
    "68": "请再次输入密码..",
    "69": "密码不能含有空格！",
    "70": "请输入初始结余..",
    "71": "请输入初始欠款..",
    "72": "确定删除该供应商？",
    "73": "确定删除该收款单？",
    "74": "确定删除该付款单？",
    "75": "确定删除该开支单？",
    "76": "请输入货主搜索..",
    "77": "请输入批次搜索..",
    "78": "请输入商品备注名称",
    "79": "请输入分类名称..",
    "80": "开单自动带入费用项",
    "81": "确定删除该批售单？",
    "82": "确定删除该调拨记录",
    "83": "补货商品不可编辑！",
    "84": "卖菜帮云服务平台",
    "85": "请输入用户备注！",
    "86": "请输入档口名称！",
    "87": "请输入批次号..",
    "88": "请输入车牌号..",
    "89": "自动带入费用项：",
    "90": "查看该货主销售单",
    "91": "业务员销售额排名",
    "92": "原批次：自购炒货",
    "93": "请输入备注名..",
    "94": "收银员结账上交款",
    "95": "请选择资金账户！",
    "96": "请输入收款资金！",
    "97": "%s 个客户欠款，总计 %2$s",//改动
    "98": "卖菜帮打印客户端",
    "99": "卖菜帮云平台登录",
    "100": "请输入手机号..",
    "101": "请输入旧密码..",
    "102": "请输入新密码..",
    "103": "新密码不能为空！",
    "104": "确定删除该商品？",
    "105": "请选择加工班组！",
    "106": "加工存货收发统计",
    "107": "请输入备注名称！",
    "108": "采购结算对账查询",
    "109": "确定删除该客户？",
    "110": "确定删除该货主？",
    "111": "确定删除该单位？",
    "112": "确定解雇该员工？",
    "113": "每日销售(批次)",
    "114": "每日销售(货主)",
    "115": "请选择收款项目！",
    "116": "请选择开支项目！",
    "117": "请选择付款对象！",
    "118": "请输入开支金额！",
    "119": "请选择对应单位！",
    "120": "请选择转出账户！",
    "121": "请选择转入账户！",
    "122": "确定反售完该批次",
    "123": "页面导航出现异常",
    "124": "确定删除该收款项",
    "125": "确定删除该开支项",
    "126": "请输入客户端编号",
    "127": "本机已保存客户端",
    "128": "金额分列取货单价",
    "129": "金额合并取货单价",
    "130": "金额合并取总单价",
    "131": "请选择提交的单据",
    "132": "非必填备注名..",
    "133": "输入的位置有误！",
    "134": "打印指令发送成功",
    "135": "您的网速不给力哦",
    "136": "请输入批次号！",
    "137": "查看该货主批次",
    "138": "开具临时客户单",
    "139": "没有这么多页！",
    "140": "请选择批次..",
    "141": "无转接的商品！",
    "142": "其中：老板账户",
    "143": "老板公账下放款",
    "144": "今日销售开单：",
    "145": "请选择销售单！",
    "146": "打印下列销售单",
    "147": "请选择销售单！",
    "148": "选择货币类型：",
    "149": "请输入备注名！",
    "150": "商品收发库存表",
    "151": "移动客户端下载",
    "152": "请输入密码..",
    "153": "请输入手机号！",
    "154": "请输入昵称..",
    "155": "确定退出系统？",
    "156": "请选择加工品！",
    "157": "请选择完成品！",
    "158": "请选择其他款项",
    "159": "请选择供应商！",
    "160": "其他款项明细：",
    "161": "请输入验证码！",
    "162": "（经营日报表）",
    "163": "供应商对账明细",
    "164": "关键字搜索..",
    "165": "确定删除该商品",
    "166": "请输入发货数量",
    "167": "确定售完该批次",
    "168": "请选择开支项目",
    "169": "请选择付款对象",
    "170": "请选择资金账户",
    "171": "请输入开支金额",
    "172": "请选择对应单位",
    "173": "确定删除该批次",
    "174": "请输入名称..",
    "175": "请输入备注名称",
    "176": "请选择复制给谁",
    "177": "自动带入费用项",
    "178": "请选择所属货主",
    "179": "确定删除该分类",
    "180": "确定删除该仓库",
    "181": "不打印/不显示",
    "182": "请输入固定备注",
    "183": "请输入打印标题",
    "184": "请选择货主批次",
    "185": "请输入收款资金",
    "186": "请选择调入仓库",
    "187": "请选择市场！",
    "188": "请选择区域！",
    "189": "请选择货主！",
    "190": "请选择商品！",
    "191": "编辑商品——",
    "192": "档口收支结存",
    "193": "盘点记录详情",
    "194": "总计：交班数",
    "195": "，商品其他费",
    "196": "，单据其他费",
    "197": "客户对账明细",
    "198": "请输入备注名",
    "199": "实收+折让：",
    "200": "客户销售结算",
    "201": "今日总收款：",
    "202": "，其他收款：",
    "203": "今日总付款：",
    "204": "收银账户互转",
    "205": "老板公账互转",
    "206": "多单汇总打印",
    "207": "导出收支明细",
    "208": "卖菜帮云平台",
    "209": "卖菜帮客户端",
    "210": "打印驱动下载",
    "211": "请输入密码！",
    "212": "申请加入商户",
    "213": "每日销售统计",
    "214": "货主结算汇总",
    "215": "请选择批次！",
    "216": "请选择班组！",
    "217": "用料成本分摊",
    "218": "选择筛选条件",
    "219": "请输入标题！",
    "220": "密    码",
    "221": "销售序时列表",
    "222": "全部客户分类",
    "223": "已删除联系人",
    "224": "暂无此权限！",
    "225": "编辑初始欠款",
    "226": "处理员工申请",
    "227": "已同意申请！",
    "228": "已拒绝申请！",
    "229": "每日收支结账",
    "230": "客户欠款统计",
    "231": "客户销售结算",
    "232": "销售情况统计",
    "233": "销售盈亏明细",
    "234": "企业盈亏明细",
    "235": "盈亏统计报表",
    "236": "采购统计情况",
    "237": "货主销售日报",
    "238": "加工存货统计",
    "239": "商品收发库存",
    "240": "添加其他收款",
    "241": "请输入金额！",
    "242": "请选择员工！",
    "243": "添加货主开支",
    "244": "支付货主开支",
    "245": "添加档口开支",
    "246": "支付给供应商",
    "247": "批次商品编辑",
    "248": "货主开支添加",
    "249": "批次销售结算",
    "250": "请选择承当方",
    "251": "请输入客户端",
    "252": "未保存客户端",
    "253": "辅助单位含有",
    "254": "默认按重销售",
    "255": "默认按件销售",
    "256": "选择结算时点",
    "257": "需要绑定档口",
    "258": "不用绑定档口",
    "259": "添加自营线路",
    "260": "编辑自营线路",
    "261": "输入客户搜索",
    "262": "输入单号搜索",
    "263": "加载中...",
    "264": "批次开支项目",
    "265": "档口开支项目",
    "266": "其他收款项目",
    "267": "收款合计：",
    "268": "客户收款：",
    "269": "其他收款：",
    "270": "付款合计：",
    "271": "日常开支：",
    "272": "采购付款：",
    "273": "代销开支：",
    "274": "代销结付：",
    "275": "其他付款：",
    "276": "资金结余：",
    "277": "资金转账：",
    "278": "加工完成品",
    "279": "申请中..",
    "280": "其他费用项",
    "281": "全部业务员",
    "282": "业务员统计",
    "283": "合计金额：",
    "284": "收付款对账",
    "285": "上日结存：",
    "286": "今日结存：",
    "287": "盘点仓库：",
    "288": "之前累欠：",
    "289": "开单：数量",
    "290": "收款：实收",
    "291": "销售收款：",
    "292": "初始欠款：",
    "293": "销售单详情",
    "294": "截止时间：",
    "295": "上日余额：",
    "296": "今日付款：",
    "297": "今日收款：",
    "298": "档口收款：",
    "299": "老板收款：",
    "300": "档口付款：",
    "301": "老板付款：",
    "302": "账户名称：",
    "303": "初始结余：",
    "304": "付供应商款",
    "305": "注册新商户",
    "306": "发货数量：",
    "307": "发货重量：",
    "308": "标准重量：",
    "309": "减水重量：",
    "310": "不对应单位",
    "311": "开支时间：",
    "312": "开支批次：",
    "313": "开支项目：",
    "314": "付给单位：",
    "315": "应付金额：",
    "316": "支付状态：",
    "317": "资金账户：",
    "318": "备注信息：",
    "319": "选择加工品",
    "320": "选择完成品",
    "321": "无此权限！",
    "322": "仓库批次：",
    "323": "商品名称：",
    "324": "采购模式：",
    "325": "全部供应商",
    "326": "总计：收发",
    "327": "验证码..",
    "328": "获取验证码",
    "329": "后重新获取",
    "330": "销售日报表",
    "331": "全部采购单",
    "332": "采购：数量",
    "333": "付款：实付",
    "334": "商品其他费",
    "335": "单据其他费",
    "336": "客户欠档口",
    "337": "客户欠货主",
    "338": "档口欠货主",
    "339": "供应商添加",
    "340": "供应商编辑",
    "341": "删除供应商",
    "342": "供应商对账",
    "343": "销售额排名",
    "344": "业务员排名",
    "345": "经营日报表",
    "346": "全部销售员",
    "347": "无需收款！",
    "348": "销售单收款",
    "349": "全部收银员",
    "350": "支付给员工",
    "351": "支付给货主",
    "352": "添加加工单",
    "353": "批次反售完",
    "354": "档口费计算",
    "355": "金额百分比",
    "356": "添加收款项",
    "357": "编辑收款项",
    "358": "添加开支项",
    "359": "请输入名称",
    "360": "编辑开支项",
    "361": "云服务异常",
    "362": "保存客户端",
    "363": "添加业务员",
    "364": "编辑业务员",
    "365": "请输入编码",
    "366": "请输入内容",
    "367": "二维码显示",
    "368": "自定义净重",
    "369": "已默认取整",
    "370": "默认不取整",
    "371": "打印/显示",
    "372": "销售暂存单",
    "373": "销售正式单",
    "374": "全部录单员",
    "375": "批次号搜索",
    "376": "暂存单开单",
    "377": "无在售批次",
    "378": "列表为空！",
    "379": "供应商管理",
    "380": "未设置打印",
    "381": "用户称呼",
    "382": "联系电话",
    "383": "档口名称",
    "384": "所属市场",
    "385": "市场区域",
    "386": "行政区划",
    "387": "详细地址",
    "388": "提交成功",
    "389": "全部分类",
    "390": "应付其他",
    "391": "添加商品",
    "392": "选择商品",
    "393": "批售商品",
    "394": "采购商品",
    "395": "加工商品",
    "396": "按金额比",
    "397": "按批售卖",
    "398": "永久售卖",
    "399": "我的档口",
    "400": "档口员工",
    "401": "申请员工",
    "402": "清除选择",
    "403": "模块全选",
    "404": "取消全选",
    "405": "保存设置",
    "406": "功能权限",
    "407": "货主权限",
    "408": "线路权限",
    "409": "员工配置",
    "410": "账户权限",
    "411": "员工炒货",
    "412": "自营接货",
    "413": "查全部单",
    "414": "老板账户",
    "415": "档口账户",
    "416": "销售单据",
    "417": "收款单据",
    "418": "批次结算",
    "419": "批次详情",
    "420": "付款详情",
    "421": "采购详情",
    "422": "供应商：",
    "423": "费用科目",
    "424": "费用项：",
    "425": "全部所属",
    "426": "档口自营",
    "427": "业务员：",
    "428": "采购货品",
    "429": "接货备注",
    "430": "接货人：",
    "431": "档口老板",
    "432": "销售明细",
    "433": "新增批次",
    "434": "在售批次",
    "435": "原批次：",
    "436": "车牌号：",
    "437": "新批次：",
    "438": "自购炒货",
    "439": "本批数量",
    "440": "转接数量",
    "441": "确定转接",
    "442": "货主开支",
    "443": "档口开支",
    "444": "付供应商",
    "445": "付货主款",
    "446": "付员工款",
    "447": "上交转账",
    "448": "备注名称",
    "449": "档口商品",
    "450": "员工商品",
    "451": "种，结余",
    "452": "件，交班",
    "453": "全部仓库",
    "454": "添加盘点",
    "455": "盘点人：",
    "456": "产地批次",
    "457": "选择客户",
    "458": "高危客户",
    "459": "按天打单",
    "460": "对账打印",
    "461": "对账明细",
    "462": "单据其他",
    "463": "欠数累计",
    "464": "，代垫费",
    "465": "共计金额",
    "466": "上日欠款",
    "467": "截止总欠",
    "468": "客户类型",
    "469": "身份证号",
    "470": "城市区域",
    "471": "批售单号",
    "472": "全部员工",
    "473": "收支结账",
    "474": "本日销售",
    "475": "新增欠款",
    "476": "累计欠款",
    "477": "其他收款",
    "478": "转账交款",
    "479": "收支结存",
    "480": "每日结账",
    "481": "代垫费：",
    "482": "新增未收",
    "483": "今日结余",
    "484": "选择收款",
    "485": "开单人：",
    "486": "前欠金额",
    "487": "本次实收",
    "488": "尾数折让",
    "489": "余欠金额",
    "490": "最新欠款",
    "491": "金额排序",
    "492": "名称排序",
    "493": "款的客户",
    "494": "确定恢复",
    "495": "初始结余",
    "496": "上日总额",
    "497": "今日净流",
    "498": "今日余额",
    "499": "添加账户",
    "500": "账户添加",
    "501": "账户编辑",
    "502": "保存编辑",
    "503": "删除账户",
    "504": "无此权限",
    "505": "确定删除",
    "506": "收支明细",
    "507": "费用对象",
    "508": "结存余额",
    "509": "客户收款",
    "510": "车次开支",
    "511": "资金转出",
    "512": "资金转入",
    "513": "导出日期",
    "514": "单据类型",
    "515": "增加金额",
    "516": "减少金额",
    "517": "全部货主",
    "518": "品名规格",
    "519": "货主批次",
    "520": "上日余货",
    "521": "今日入库",
    "522": "产品窗口",
    "523": "退出系统",
    "524": "我的资料",
    "525": "密码修改",
    "526": "编辑昵称",
    "527": "编辑密码",
    "528": "确认密码",
    "529": "删除商品",
    "530": "删除成功",
    "531": "已实际付",
    "532": "还未支付",
    "533": "当日销售",
    "534": "销售单数",
    "535": "销售件数",
    "536": "销售重量",
    "537": "销售货款",
    "538": "软件收入",
    "539": "，其他费",
    "540": "结算汇总",
    "541": "反补货主",
    "542": "卸货费用",
    "543": "少收折让",
    "544": "货主扣减",
    "545": "结余金额",
    "546": "完工成品",
    "547": "单件重量",
    "548": "加工班组",
    "549": "原料名称",
    "550": "领用数量",
    "551": "损耗数量",
    "552": "清盘余货",
    "553": "实际耗用",
    "554": "进货成本",
    "555": "领用重量",
    "556": "损耗重量",
    "557": "余货数量",
    "558": "余货重量",
    "559": "单位成本",
    "560": "的数量！",
    "561": "分摊比例",
    "562": "暂存成功",
    "563": "挂单暂存",
    "564": "提交确认",
    "565": "暂存加工",
    "566": "加工详情",
    "567": "实耗数量",
    "568": "实耗重量",
    "569": "完成数量",
    "570": "完成重量",
    "571": "全部批次",
    "572": "添加采购",
    "573": "采购添加",
    "574": "添加款项",
    "575": "采购编辑",
    "576": "编辑商品",
    "577": "按重采购",
    "578": "按件采购",
    "579": "采购单价",
    "580": "货款金额",
    "581": "采购统计",
    "582": "录入收发",
    "583": "全部类型",
    "584": "收发详情",
    "585": "正常收货",
    "586": "正常发货",
    "587": "种，库存",
    "588": "此次收发",
    "589": "确定密码",
    "590": "销售汇总",
    "591": "销售总额",
    "592": "货主总额",
    "593": "档口总额",
    "594": "员工总额",
    "595": "销售报表",
    "596": "盈亏统计",
    "597": "档口收入",
    "598": "销售盈亏",
    "599": "其他收发",
    "600": "销售成本",
    "601": "档口应得",
    "602": "采购附加",
    "603": "批次费用",
    "604": "反补员工",
    "605": "销售收益",
    "606": "收入明细",
    "607": "支出明细",
    "608": "商品名称",
    "609": "货主应得",
    "610": "采购成本",
    "611": "员工应得",
    "612": "档口收益",
    "613": "累计前欠",
    "614": "开单合计",
    "615": "实收货款",
    "616": "日常开支",
    "617": "代垫三轮",
    "618": "全部客户",
    "619": "全部商品",
    "620": "所属货主",
    "621": "交易往来",
    "622": "企业盈亏",
    "623": "销售收入",
    "624": "代销收入",
    "625": "炒货收入",
    "626": "开单收入",
    "627": "其他收入",
    "628": "成本费用",
    "629": "盈亏利润",
    "630": "之前累欠",
    "631": "普通客户",
    "632": "预警客户",
    "633": "优质客户",
    "634": "重要客户",
    "635": "客户添加",
    "636": "货主添加",
    "637": "单位添加",
    "638": "客户编辑",
    "639": "初始欠款",
    "640": "编辑欠款",
    "641": "应收金额",
    "642": "往来明细",
    "643": "删除客户",
    "644": "货主编辑",
    "645": "应付金额",
    "646": "未收欠款",
    "647": "删除货主",
    "648": "员工编辑",
    "649": "业务仓库",
    "650": "权限设置",
    "651": "解雇员工",
    "652": "单位编辑",
    "653": "删除单位",
    "654": "市场分析",
    "655": "智商资讯",
    "656": "我的报表",
    "657": "货主结算",
    "658": "客户对账",
    "659": "销售统计",
    "660": "销售时序",
    "661": "其他收发",
    "662": "其他开支",
    "663": "资金转账",
    "664": "全部日志",
    "665": "销售日志",
    "666": "财务日志",
    "667": "之前金额",
    "668": "之后金额",
    "669": "收款时间",
    "670": "收款人员",
    "671": "收款单号",
    "672": "缴款客户",
    "673": "收款账户",
    "674": "收款金额",
    "675": "批次被删",
    "676": "仓库批次",
    "677": "调出仓库",
    "678": "调入仓库",
    "679": "操作人员",
    "680": "注册一个",
    "681": "新的档口",
    "682": "申请成为",
    "683": "收款合计",
    "684": "付款合计",
    "685": "采购付款",
    "686": "代销开支",
    "687": "代销结付",
    "688": "其他付款",
    "689": "资金结余",
    "690": "资金转款",
    "691": "按单收款",
    "692": "总欠收款",
    "693": "收款查询",
    "694": "单，未收",
    "695": "全部账户",
    "696": "单，收款",
    "697": "收款详情",
    "698": "收款打印",
    "699": "按单打印",
    "700": "汇总打印",
    "701": "销售详情",
    "702": "全部项目",
    "703": "收款项目",
    "704": "选择时间",
    "705": "资金账户",
    "706": "备注信息",
    "707": "账出账户",
    "708": "员工名称",
    "709": "支付金额",
    "710": "全部单位",
    "711": "付款对象",
    "712": "开支时间",
    "713": "开支批次",
    "714": "开支项目",
    "715": "付给单位",
    "716": "支付状态",
    "717": "货主品牌",
    "718": "开支金额",
    "719": "供应商户",
    "720": "转出账户",
    "721": "转入账户",
    "722": "添加转账",
    "723": "转账金额",
    "724": "全部班组",
    "725": "加工单号",
    "726": "加工日期",
    "727": "自营批号",
    "728": "继续加工",
    "729": "加工暂存",
    "730": "已接在售",
    "731": "售完批次",
    "732": "即时库存",
    "733": "长久批次",
    "734": "代销接货",
    "735": "采购管理",
    "736": "仓库调拨",
    "737": "仓库盘点",
    "738": "商品定价",
    "739": "商品添加",
    "740": "撤销转接",
    "741": "批次售完",
    "742": "已售数量",
    "743": "已售重量",
    "744": "剩余成本",
    "745": "打印信息",
    "746": "开单总额",
    "747": "上批转入",
    "748": "转到下批",
    "749": "批次结余",
    "750": "售完备注",
    "751": "批次转接",
    "752": "退补货主",
    "753": "档口收费",
    "754": "货主结余",
    "755": "货主合计",
    "756": "销售利润",
    "757": "数量详情",
    "758": "重量详情",
    "759": "档口信息",
    "760": "打印设备",
    "761": "档口仓库",
    "762": "使用设置",
    "763": "组合商品",
    "764": "商品复制",
    "765": "商品分类",
    "766": "批量编辑",
    "767": "品名称谓",
    "768": "编码货主",
    "769": "基本单位",
    "770": "辅助单位",
    "771": "商品详情",
    "772": "商品编码",
    "773": "原料含量",
    "774": "商品编辑",
    "775": "标准名称",
    "776": "标准重量",
    "777": "减水重量",
    "778": "编辑成功",
    "779": "押筐添加",
    "780": "编辑分类",
    "781": "开户时间",
    "782": "续费管理",
    "783": "档口编辑",
    "784": "地址信息",
    "785": "档口地址",
    "786": "其他信息",
    "787": "结算时点",
    "788": "固定收取",
    "789": "收费比例",
    "790": "固定收费",
    "791": "添加仓库",
    "792": "编辑仓库",
    "793": "欠数显示",
    "794": "自营线路",
    "795": "开单设置",
    "796": "开单方式",
    "797": "库存开单",
    "798": "批次开单",
    "799": "重量默认",
    "800": "默认标重",
    "801": "开单取整",
    "802": "多收货款",
    "803": "结算货主",
    "804": "结算档口",
    "805": "开单打印",
    "806": "打印页数",
    "807": "打印标题",
    "808": "固定备注",
    "809": "附加费用",
    "810": "货款其他",
    "811": "欠款客户",
    "812": "其他设置",
    "813": "重量模式",
    "814": "设置成功",
    "815": "分享查看",
    "816": "批售开单",
    "817": "金额搜索",
    "818": "无需收款",
    "819": "查单详情",
    "820": "开单编辑",
    "821": "一键提交",
    "822": "点选开单",
    "823": "键盘开单",
    "824": "一键补录",
    "825": "退货开单",
    "826": "添加调拨",
    "827": "调拨详情",
    "828": "商品排序",
    "829": "我的主页",
    "830": "通讯管理",
    "831": "车次存货",
    "832": "配货加工",
    "833": "销售开单",
    "834": "资金收银",
    "835": "基础设置",
    "836": "日志管理",
    "837": "操作成功",
    "838": "员工管理",
    "839": "商品管理",
    "840": "打印配置",
    "841": "客户管理",
    "842": "货主管理",
    "843": "仓库管理",
    "844": "自营路线",
    "845": "进销操作",
    "846": "出纳收支",
    "847": "报表查询",
    "848": "批次管理",
    "849": "加工管理",
    "850": "开单管理",
    "851": "调拨管理",
    "852": "盘点记录",
    "853": "销售排名",
    "854": "未分类",
    "855": "货主：",
    "856": "批次：",
    "857": "车牌：",
    "858": "备注：",
    "859": "确 定",
    "860": "取 消",
    "861": "数量：",
    "862": "重量：",
    "863": "标重：",
    "864": "减水：",
    "865": "批售单",
    "866": "采购单",
    "867": "加工单",
    "868": "按数量",
    "869": "按重量",
    "870": "无线路",
    "871": "线路：",
    "872": "售卖：",
    "873": "有权限",
    "874": "无权限",
    "875": "类型：",
    "876": "前欠：",
    "877": "开单：",
    "878": "收款：",
    "879": "折让：",
    "880": "应收：",
    "881": "付款：",
    "882": "单号：",
    "883": "金额：",
    "884": "账户：",
    "885": "采购：",
    "886": "费用项",
    "887": "员工：",
    "888": "日期：",
    "889": "销售额",
    "890": "合计：",
    "891": "业务员",
    "892": "仓库：",
    "893": "经手员",
    "894": "总收款",
    "895": "总付款",
    "896": "交班数",
    "897": "盘点人",
    "898": "确认人",
    "899": "交班量",
    "900": "未确认",
    "901": "已确认",
    "902": "盘点量",
    "903": "客户：",
    "904": "时间：",
    "905": "实收：",
    "906": "，折让",
    "907": "，货款",
    "908": "，代垫",
    "909": "，小计",
    "910": "单其他",
    "911": "地址：",
    "912": "限额：",
    "913": "收初欠",
    "914": "单位：",
    "915": "收现款",
    "916": "收欠款",
    "917": "销售单",
    "918": "所属：",
    "919": "截止：",
    "920": "恢 复",
    "921": "供应商",
    "922": "名称：",
    "923": "归属：",
    "924": "支付宝",
    "925": "银行卡",
    "926": "初始：",
    "927": "收入：",
    "928": "支出：",
    "929": "经办人",
    "930": "付货主",
    "931": "付员工",
    "932": "辅单位",
    "933": "主单位",
    "934": "手机号",
    "935": "登 录",
    "936": "菜博士",
    "937": "申请中",
    "938": "注册中",
    "939": "旧密码",
    "940": "新密码",
    "941": "档口费",
    "942": "代垫费",
    "943": "收款额",
    "944": "批次号",
    "945": "总金额",
    "946": "代卖费",
    "947": "总成本",
    "948": "请编辑",
    "949": "加工人",
    "950": "加工：",
    "951": "加工品",
    "952": "损耗率",
    "953": "完成品",
    "954": "原料名",
    "955": "采购员",
    "956": "操作员",
    "957": "收/发",
    "958": "库存量",
    "959": "备注名",
    "960": "验证码",
    "961": "重均价",
    "962": "件均重",
    "963": "卸货费",
    "964": "上日余",
    "965": "本日余",
    "966": "件均价",
    "967": "共销售",
    "968": "平均价",
    "969": "商品名",
    "970": "销售员",
    "971": "采购费",
    "972": "已解雇",
    "973": "未付款",
    "974": "未进货",
    "975": "未设置",
    "976": "付款人",
    "977": "收款人",
    "978": "操作前",
    "979": "操作后",
    "980": "收 款",
    "981": "已收款",
    "982": "已实付",
    "983": "未付清",
    "984": "全部单",
    "985": "暂存单",
    "986": "已提交",
    "987": "录单员",
    "988": "个货主",
    "989": "种商品",
    "990": "主数量",
    "991": "辅数量",
    "992": "接货人",
    "993": "加工费",
    "994": "已支付",
    "995": "未支付",
    "996": "收款项",
    "997": "承当方",
    "998": "开支项",
    "999": "请设置",
    "1000": "的含量",
    "1001": "续 费",
    "1002": "第二天",
    "1003": "不打单",
    "1004": "已结清",
    "1005": "原开单",
    "1006": "新开单",
    "1007": "折让费",
    "1008": "三轮费",
    "1009": "查收款",
    "1010": "请输入",
    "1011": "的单价",
    "1012": "的重量",
    "1013": "的数量",
    "1014": "选客户",
    "1015": "货单价",
    "1016": "总单价",
    "1017": "新位置",
    "1018": "商品框",
    "1019": "注册",
    "1020": "品名",
    "1021": "数量",
    "1022": "重量",
    "1023": "公斤",
    "1024": "标重",
    "1025": "减水",
    "1026": "操作",
    "1027": "确定",
    "1028": "取消",
    "1029": "固定",
    "1030": "档口",
    "1031": "员工",
    "1032": "收入",
    "1033": "备注",
    "1034": "搜索",
    "1035": "申请",
    "1036": "全选",
    "1037": "开单",
    "1038": "收款",
    "1039": "时间",
    "1040": "单号",
    "1041": "金额",
    "1042": "折让",
    "1043": "付款",
    "1044": "采购",
    "1045": "打印",
    "1046": "单位",
    "1047": "所属",
    "1048": "客户",
    "1049": "货主",
    "1050": "单价",
    "1051": "自营",
    "1052": "接货",
    "1053": "售完",
    "1054": "新到",
    "1055": "转接",
    "1056": "加工",
    "1057": "收发",
    "1058": "剩余",
    "1059": "已售",
    "1060": "成本",
    "1061": "小计",
    "1062": "结余",
    "1063": "商品",
    "1064": "仓库",
    "1065": "状态",
    "1066": "限额",
    "1067": "日期",
    "1068": "货款",
    "1069": "其他",
    "1070": "代垫",
    "1071": "导出",
    "1072": "合计",
    "1073": "电话",
    "1074": "普通",
    "1075": "预警",
    "1076": "高危",
    "1077": "优质",
    "1078": "重要",
    "1079": "折扣",
    "1080": "实收",
    "1081": "前欠",
    "1082": "余欠",
    "1083": "其中",
    "1084": "批次",
    "1085": "应收",
    "1086": "笔，",
    "1087": "未收",
    "1088": "手机",
    "1089": "名称",
    "1090": "欠款",
    "1091": "初欠",
    "1092": "编辑",
    "1093": "现金",
    "1094": "微信",
    "1095": "支出",
    "1096": "余额",
    "1097": "全部",
    "1098": "头像",
    "1099": "昵称",
    "1100": "正常",
    "1101": "进入",
    "1102": "撤销",
    "1103": "含重",
    "1104": "序号",
    "1105": "规格",
    "1106": "用料",
    "1107": "人工",
    "1108": "辅料",
    "1109": "录单",
    "1110": "班组",
    "1111": "费用",
    "1112": "应付",
    "1113": "删除",
    "1114": "按重",
    "1115": "按件",
    "1116": "均价",
    "1117": "标题",
    "1118": "人员",
    "1119": "业务",
    "1120": "盈亏",
    "1121": "进货",
    "1122": "销售",
    "1123": "车货",
    "1124": "您与",
    "1125": "回扣",
    "1126": "实付",
    "1127": "总欠",
    "1128": "主页",
    "1129": "添加",
    "1130": "已删",
    "1131": "同意",
    "1132": "拒绝",
    "1133": "更多",
    "1134": "调拨",
    "1135": "类型",
    "1136": "账出",
    "1137": "转出",
    "1138": "转入",
    "1139": "项目",
    "1140": "账户",
    "1141": "已收",
    "1142": "单数",
    "1143": "未付",
    "1144": "提交",
    "1145": "暂存",
    "1146": "详情",
    "1147": "车牌",
    "1148": "毛利",
    "1149": "余货",
    "1150": "返补",
    "1151": "计算",
    "1152": "模式",
    "1153": "收费",
    "1154": "结存",
    "1155": "编号",
    "1156": "编码",
    "1157": "押筐",
    "1158": "已选",
    "1159": "打单",
    "1160": "修改",
    "1161": "三轮",
    "1162": "失败",
    "1163": "退货",
    "1164": "已欠",
    "1165": "件数",
    "1166": "总重",
    "1167": "皮重",
    "1168": "净重",
    "1169": "补货",
    "1170": "查看",
    "1171": "查询",
    "1172": "斤",
    "1173": "件",
    "1174": "至",
    "1175": "第",
    "1176": "批",
    "1177": "种",
    "1178": "月",
    "1179": "日",
    "1180": "印",
    "1181": "转",
    "1182": "共",
    "1183": "密",
    "1184": "码",
    "1185": "收",
    "1186": "发",
    "1187": "注",
    "1188": "册",
    "1189": "单",
    "1190": "从",
    "1191": "天",
    "1192": "笔",
    "1193": "付",
    "1194": "出",
    "1195": "入",
    "1196": "重",
    "1197": "吨",
    "1198": "点",
    "1199": "页",
    "1200": "铢",
    "1201": "元",
    "1202": "客户订货接单表",
    "1203": "分业务存货表",
    "1204": "上余库存",
    "1205": "新增入库",
    "1206": "销售出库",
    "1207": "其他出库",
    "1208": "到货差异",
    "1209": "盘盈盘亏",
    "1210": "库存结余",
    "1211": "报废情况",
    "1212": "好",
    "1213": "次",
    "1214": "产地品牌",
    "1215": "显示",
    "1216": "不显示",
    "1217": "进销结存汇总表",
    "1218": "不可切换",
    "1219": "可切换",
    "1220": "切换仓库",
    "1221": "结余次品编辑",
    "1222": "订货接单",
    "1223": "请选择货主",
    "1224": "此批次无",
    "1225": "报废处理",
    "1226": "正常出入",
    "1227": "语言模式",
    "1228": "中文", 
    "1229": "泰文",
    "1230": "我的收银",
    "1231": "资金收支报表",
    "1232": "收银员对账",
    "1233": "账户资金",
    "1234": "实际清点",
    "1235": "排序",
    "1236": "图片显示",
    "1237": "第%s批",
    "1238": "不选业务员",
};

export default Object.assign({},languages);