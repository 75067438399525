import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import TabTopActivity from '../components/TabTopActivity';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

  export default class CargoFinishInfoView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        nWmode:0,//0stringUtil.language(1021)，1stringUtil.language(1022)，2stringUtil.language(432)
        currCargoItem:{},//cargoMode，-1库存 0代销 1{stringUtil.language(1051)} 2炒货
        inputTextTagVal:'',
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_URL+'/'+this.props.cargoItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({currCargoItem:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:10,paddingRight:10}}>
              {this.state.currCargoItem.cargoMode==0||this.state.currCargoItem.cargoMode==2?<span>{stringUtil.language(856)}{this.state.currCargoItem.targetName} / {this.state.currCargoItem.cargoNo}</span>:
              <span>{stringUtil.language(856)}{stringUtil.language(1051)}_{this.state.currCargoItem.targetName} / {this.state.currCargoItem.cargoNo}</span>}
              <div style={{height:25,display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
                <span style={{color:'#FF8C00',fontSize:10}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.receiveTime),'Y-M-D h:m:s')}{stringUtil.language(1052)}</span>
                <span style={{color:'#DAA520',fontSize:10}}>{arithUtil.formatToTimeStr(new Date(this.state.currCargoItem.settleTime),'Y-M-D h:m:s')}{stringUtil.language(1053)}</span>
              </div>
            </div>
            {this.state.currCargoItem.receiveDescribe?<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
              <span style={{fontSize:12}}>{stringUtil.language(429)}:{this.state.currCargoItem.receiveDescribe}</span>
            </div>:''}
            <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(430)}{this.state.currCargoItem.workerId==0?stringUtil.language(431):this.state.currCargoItem.workerName}</span>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:30,cursor:'pointer'}} onClick={()=>{
                    this.setState({nWmode:0});
                }}>{this.state.nWmode==0?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1021)}</i>:
                <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1021)}</i>}</div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                    this.setState({nWmode:1});
                }}>{this.state.nWmode==1?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(1022)}</i>:
                <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(1022)}</i>}</div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                    this.setState({nWmode:2});
                }}>{this.state.nWmode==2?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(432)}</i>:
                <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(432)}</i>}</div>
              </div>
              <span style={{color:'#C0C0C0',fontSize:10}}>{this.state.currCargoItem.warehouseName}</span>
            </div>
            {this.state.nWmode!=2?<div style={{display:'flex',flexDirection:'column'}}>
              <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1054)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1056)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1044)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1058)}</span>
                <span style={{width:'11.111%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1041)}</span>
              </div>
              <div>
                {this.state.currCargoItem.commodities!=null?this.state.currCargoItem.commodities.map((item,key)=>{
                  return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'11.111%',color:'#000',fontSize:11}}>{stringUtil.subStr(item.commodityName,6)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{this.state.nWmode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight, 1),this.state.weightMode)}</span>
                    <span style={{width:'11.111%',fontSize:11,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                  </div>)
                }):''}
              </div>
            </div>:<div style={{display:'flex',flexDirection:'column'}}>
              <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'20%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'80%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'25%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'15%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'17%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'25%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'18%',color:'#FFA500',fontSize:11,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
              </div>
              <div>
                {this.state.currCargoItem.detailed?this.state.currCargoItem.detailed.map((item,key)=>{
                  let totalItemInfo={number:0,weight:0,money:0};
                  if(item.items==null) item.items=[];
                  for(let i=0;i<item.items.length;i++){
                    totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                    totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                    item.items[i].money = item.items[i].goodsMoney;
                    for(let j=0;j<item.items[i].costs.length;j++){
                      item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    }
                    totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                  }
                  return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                    <span style={{width:'20%',color:'#000',fontSize:12,paddingTop:7}}>{stringUtil.subStr(item.commodityName,9)}</span>
                    <div style={{width:'80%',display:'flex',flexDirection:'column'}}>
                      <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{color:'#52A056',fontSize:14,fontWeight:'bold'}}>{stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money)}{stringUtil.currency(1)}</span></div>
                      {item.items.map((itemb,keyb)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                          <span style={{width:'25%',color:'#808080',fontSize:12}}>{itemb.customerName}</span>
                          <span style={{width:'15%',color:'#808080',fontSize:13,textAlign:'center'}}>{itemb.number}</span>
                          <span style={{width:'17%',color:'#808080',fontSize:13,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                          <span style={{width:'25%',color:'#808080',fontSize:12,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                          <span style={{width:'18%',color:'#808080',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                        </div>);
                      })}
                    </div>
                  </div>)
                }):''}
              </div>
            </div>}
        </div>)
    }
}

const styles = {}