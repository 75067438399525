import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import * as printUtil from '../utils/printUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import TabTopActivity from '../components/TabTopActivity';
import WholesaleInfoView from './WholesaleInfoView.js';

export default class DebtCollectActivity extends Component{
    constructor(props) {
        super(props);
        let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
        this.state={
            weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
            currCustomerArrears:paramsPage.currCustomerArrears?JSON.parse(JSON.stringify(paramsPage.currCustomerArrears)):{},
            accountsAll:[],
            workersAll:[],
            searchs:{
                warehouseId:localStorage.getItem("currentWarehouseItem")!=null?(JSON.parse(localStorage.getItem("currentWarehouseItem"))).id:-1,
                customerId:paramsPage.currCustomerArrears?paramsPage.currCustomerArrears.customerId:-1,//-1stringUtil.language(1097)
                targetType:paramsPage.targetItem?paramsPage.targetItem.targetType:-1,//-1stringUtil.language(1097),
                targetId:paramsPage.targetItem?paramsPage.targetItem.targetId:-1,//-1stringUtil.language(1097)
                workerId:-1
            },
            wholesaleItemList:[],wholesaleItemListTotalInfo:{number:0,unCollect:0},
            wholesaleInfoShowPop:false,
            isShowItemInfoKey:-1,
            totalCollectInfo:{time:arithUtil.getCurrDateTime(),totalCollectMoney:0,totalDiscountMoney:0},
        };
        if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({accountsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workersAll = successResponse.data;
                this.state.workersAll.unshift({id:0,noteName:stringUtil.language(431)});
                this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(472)});
                this.initData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        this.state.wholesaleItemListTotalInfo={number:0,unCollect:0};
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_ARREARS_URL,{
            warehouseId:this.state.searchs.warehouseId,
            customerId:this.state.searchs.customerId,
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            workerId:this.state.searchs.workerId
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let wholesaleItemList = successResponse.data.wholesales;
                for(let i=0;i<wholesaleItemList.length;i++){
                    let wholesaleItemUnCollect = 0;
                    for(let j=0;j<wholesaleItemList[i].wholesaleMoneys.length;j++){
                        let unCollect = (wholesaleItemList[i].wholesaleMoneys[j].arrears).sub(wholesaleItemList[i].wholesaleMoneys[j].collectMoney).sub(wholesaleItemList[i].wholesaleMoneys[j].discountMoney);
                        wholesaleItemList[i].wholesaleMoneys[j].unCollect = unCollect;
                        wholesaleItemUnCollect = wholesaleItemUnCollect.add(unCollect);
                    }
                    wholesaleItemList[i].wholesaleUnCollect = wholesaleItemUnCollect;
                    this.state.wholesaleItemListTotalInfo.unCollect = (this.state.wholesaleItemListTotalInfo.unCollect).add(wholesaleItemList[i].wholesaleUnCollect);
                }
                let initArrearsList = successResponse.data.initArrears;
                if(initArrearsList.length>0){
                    let initArrears={
                        isInitArrearsMode:1,//0或null:stringUtil.language(917)，1:stringUtil.language(1091)
                        wholesaleUnCollect:0,
                        wholesaleMoneys:[]
                    }
                    let initTotal = {collect:0,discount:0};
                    for(let i=0;i<initArrearsList.length;i++){
                        if(initArrearsList[i].initArrearsUnCollect!=0){
                            initTotal.collect = (initTotal.collect).add(initArrearsList[i].initArrearsUnCollect);
                            initArrears.wholesaleMoneys.push({
                                targetType:initArrearsList[i].targetType,
                                targetId:initArrearsList[i].targetId,
                                targetName:initArrearsList[i].targetName,
                                unCollect:initArrearsList[i].initArrearsUnCollect
                            });
                        }
                    }
                    if(initTotal.collect!=0){
                        initArrears.wholesaleUnCollect = initTotal.collect;
                        this.state.wholesaleItemListTotalInfo.unCollect = (this.state.wholesaleItemListTotalInfo.unCollect).add(initArrears.wholesaleUnCollect);
                        wholesaleItemList.unshift(initArrears);
                    }
                }
                this.state.wholesaleItemListTotalInfo.number = wholesaleItemList.length;
                this.setState({wholesaleItemList:wholesaleItemList});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    updateWholesaleCollect(){
        for(let i=0;i<this.state.wholesaleItemList.length;i++){
            this.state.wholesaleItemList[i].isSelected = false;
            this.state.wholesaleItemList[i].collectChenpinglong = null;
            this.state.wholesaleItemList[i].discountChenpinglong = 0;
            for(let j=0;j<this.state.wholesaleItemList[i].wholesaleMoneys.length;j++){
                this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = null;
                this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = null;
            }
        }
        if(this.state.wholesaleItemList.length>0){
            let currTotalCollectMoney = 0;
            for(let i=0;i<this.state.wholesaleItemList.length;i++){
                let wholesaleItem = this.state.wholesaleItemList[i];
                if(wholesaleItem.discountChenpinglong!=null){
                    currTotalCollectMoney = currTotalCollectMoney.add((wholesaleItem.wholesaleUnCollect).sub(wholesaleItem.discountChenpinglong));
                }else{
                    currTotalCollectMoney = currTotalCollectMoney.add(wholesaleItem.wholesaleUnCollect);
                }
                if(currTotalCollectMoney<=this.state.totalCollectInfo.totalCollectMoney){
                    if(wholesaleItem.discountChenpinglong!=null){
                        this.state.wholesaleItemList[i].collectChenpinglong = (wholesaleItem.wholesaleUnCollect).sub(wholesaleItem.discountChenpinglong);
                    }else{
                        this.state.wholesaleItemList[i].collectChenpinglong = wholesaleItem.wholesaleUnCollect;
                        this.state.wholesaleItemList[i].discountChenpinglong = 0;
                    }
                    this.state.wholesaleItemList[i].isSelected = true;
                    let beforesOwnerC=0,beforesOwnerD=0;
                    for(let j=0;j<wholesaleItem.wholesaleMoneys.length;j++){
                        this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (wholesaleItem.collectChenpinglong).mul(wholesaleItem.wholesaleMoneys[j].unCollect).div(wholesaleItem.wholesaleUnCollect).round();
                        this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (wholesaleItem.discountChenpinglong).mul(wholesaleItem.wholesaleMoneys[j].unCollect).div(wholesaleItem.wholesaleUnCollect).round();
                        if(j!=wholesaleItem.wholesaleMoneys.length-1){
                            let cdR = (this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong).add(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                            if(cdR>this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect){
                                this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect).sub(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                            }
                            // if(cdR<=this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect){
                            //     this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong).round();
                            //     this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong).round();
                            // }
                        }else{
                            this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].collectChenpinglong).sub(beforesOwnerC);
                            this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[i].discountChenpinglong).sub(beforesOwnerD);
                        }
                        beforesOwnerC=beforesOwnerC.add(this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong);
                        beforesOwnerD=beforesOwnerD.add(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                    }
                }else{
                    let subMoney = (wholesaleItem.wholesaleUnCollect).sub(currTotalCollectMoney.sub(this.state.totalCollectInfo.totalCollectMoney));
                    if(subMoney>0){
                        this.state.wholesaleItemList[i].collectChenpinglong = subMoney;
                        this.state.wholesaleItemList[i].discountChenpinglong = 0;
                        this.state.wholesaleItemList[i].isSelected = true;
                        let beforesOwnerC=0,beforesOwnerD=0;
                        for(let j=0;j<wholesaleItem.wholesaleMoneys.length;j++){
                            this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (wholesaleItem.collectChenpinglong).mul(wholesaleItem.wholesaleMoneys[j].unCollect).div(wholesaleItem.wholesaleUnCollect).round();
                            this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (wholesaleItem.discountChenpinglong).mul(wholesaleItem.wholesaleMoneys[j].unCollect).div(wholesaleItem.wholesaleUnCollect).round();
                            if(j!=wholesaleItem.wholesaleMoneys.length-1){
                                let cdR = (this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong).add(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                                if(cdR>this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect){
                                    this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect).sub(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                                }
                                // if(cdR<=this.state.wholesaleItemList[i].wholesaleMoneys[j].unCollect){
                                //     this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong).round();
                                //     this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong).round();
                                // }
                            }else{
                                this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[i].collectChenpinglong).sub(beforesOwnerC);
                                this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[i].discountChenpinglong).sub(beforesOwnerD);
                            }
                            beforesOwnerC=beforesOwnerC.add(this.state.wholesaleItemList[i].wholesaleMoneys[j].collectChenpinglong);
                            beforesOwnerD=beforesOwnerD.add(this.state.wholesaleItemList[i].wholesaleMoneys[j].discountChenpinglong);
                        }
                    }else{this.state.wholesaleItemList[i].isSelected = false;}
                    break;
                }
            }
            if(currTotalCollectMoney<this.state.totalCollectInfo.totalCollectMoney)this.state.totalCollectInfo.totalCollectMoney=currTotalCollectMoney;
            this.setState({wholesaleItemList:this.state.wholesaleItemList});
        }
    }
    updateTotalCollectMoney(){
        this.state.totalCollectInfo.totalCollectMoney = 0;
        this.state.totalCollectInfo.totalDiscountMoney = 0;
        for(let i=0;i<this.state.wholesaleItemList.length;i++){
            if(this.state.wholesaleItemList[i].collectChenpinglong!=null) this.state.totalCollectInfo.totalCollectMoney = (this.state.totalCollectInfo.totalCollectMoney).add(this.state.wholesaleItemList[i].collectChenpinglong);
            if(this.state.wholesaleItemList[i].discountChenpinglong!=null) this.state.totalCollectInfo.totalDiscountMoney = (this.state.totalCollectInfo.totalDiscountMoney).add(this.state.wholesaleItemList[i].discountChenpinglong);
        }
        this.setState({totalCollectInfo:this.state.totalCollectInfo});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{width:'100%',height:'6%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <TabTopActivity viewHeight='100%' viewWidth='40%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{//20220601吕总强烈要求在这加，不在stringUtil.language(917)列表加
                    let wholesaleIds=[];
                    for(let i=0;i<this.state.wholesaleItemList.length;i++){
                        if(this.state.wholesaleItemList[i].isChoiceChenpinglong)wholesaleIds.push(this.state.wholesaleItemList[i].id);
                    }
                    if(wholesaleIds.length==0){ToastBox.error(stringUtil.language(145));return;}
                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,wholesaleIds,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let wholesales=successResponse.data;
                            for(let i=0;i<wholesales.length;i++){
                                if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                            }
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}><span style={{color:'#F00',fontSize:16}}>{stringUtil.language(146)}</span></div>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{//20220601吕总强烈要求在这加，不在stringUtil.language(917)列表加
                    let wholesaleIds=[];
                    for(let i=0;i<this.state.wholesaleItemList.length;i++){
                        if(this.state.wholesaleItemList[i].isChoiceChenpinglong)wholesaleIds.push(this.state.wholesaleItemList[i].id);
                    }
                    if(wholesaleIds.length==0){ToastBox.error(stringUtil.language(145));return;}
                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_SUMMARY_URL,wholesaleIds,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.setState({wholesalePCMDO:successResponse.data,printWholesaleShow:true},()=>{
                                const printArea = document.getElementById('printWholesale');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({printWholesaleShow:false});
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}><span style={{color:'#F00',fontSize:16}}>{stringUtil.language(206)}</span></div>
            </div>
            <div style={{height:'93%',display:'flex',flexDirection:'row',paddingLeft:5,paddingRight:5}}>
                <div style={{width:'64%',height:'100%',backgroundColor:'#FFF',marginRight:'0.5%'}}>
                    <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {(()=>{this.state.isAllSelect=1;
                            for(let key=0;key<this.state.wholesaleItemList.length;key++){
                                if(!this.state.wholesaleItemList[key].isChoiceChenpinglong)this.state.isAllSelect=0;
                            }
                        })()}
                        {this.state.isAllSelect?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//stringUtil.language(1036)==>不stringUtil.language(1036)
                            for(let key=0;key<this.state.wholesaleItemList.length;key++){
                                this.state.wholesaleItemList[key].isChoiceChenpinglong=0;
                            }
                            this.setState({wholesaleItemList:this.state.wholesaleItemList});
                        }}>&#xe698;</i>:<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不stringUtil.language(1036)==>stringUtil.language(1036)
                            for(let key=0;key<this.state.wholesaleItemList.length;key++){
                                this.state.wholesaleItemList[key].isChoiceChenpinglong=1;
                            }
                            this.setState({wholesaleItemList:this.state.wholesaleItemList});
                        }}>&#xe6a7;</i>}
                        <div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'40%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(917)}</span>
                            <span style={{width:'60%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1039)}</span>
                        </div>
                        <div style={{width:'65%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{width:'45%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'40%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1085)}</span>
                                <span style={{width:'60%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1033)}</span>
                            </div>
                            <div style={{width:'55%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'55%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1080)}</span>
                                <span style={{width:'30%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1042)}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{height:'95%',overflow:'auto'}}>
                        {this.state.wholesaleItemList.map((item,key)=>{
                            return(<div style={{width:'100%'}} key={key}>
                                <div style={{width:'100%',height:45,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    {item.isInitArrearsMode!=1&&item.isChoiceChenpinglong?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#52A056',textAlign:'center',cursor:'pointer'}} onClick={()=>{//选==>不选
                                        this.state.wholesaleItemList[key].isChoiceChenpinglong=0;
                                        this.setState({wholesaleItemList:this.state.wholesaleItemList});
                                    }}>&#xe698;</i>:item.isInitArrearsMode!=1?<i className="iconfontIndexCss" style={{width:'5%',fontSize:18,color:'#A9A9A9',textAlign:'center',cursor:'pointer'}} onClick={()=>{//不选==>选
                                        this.state.wholesaleItemList[key].isChoiceChenpinglong=1;
                                        this.setState({wholesaleItemList:this.state.wholesaleItemList});
                                    }}>&#xe6a7;</i>:''}
                                    <div style={{width:'30%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                        if(item.isInitArrearsMode!=1){
                                            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+item.id,null,['key','get',1],(successResponse)=>{
                                                if (successResponse.status==200) {
                                                    this.state.currWholesaleInfoItem=successResponse.data;
                                                    this.setState({wholesaleInfoShowPop:!this.state.wholesaleInfoShowPop});
                                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                        }
                                    }}>{item.isInitArrearsMode==1?<span style={{width:'100%',color:'#52A056',fontSize:16,fontWeight:'bold',paddingLeft:10}}>{stringUtil.language(292)}</span>:''}
                                        {item.isInitArrearsMode!=1?<span style={{width:'40%',fontSize:11,paddingLeft:10}}>{item.wholesaleNo}</span>:''}
                                        {item.isInitArrearsMode!=1?<span style={{width:'60%',color:'#808080',fontSize:11,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.finishTime),'Y-M-D h:m:s')}</span>:''}
                                    </div>
                                    <div style={{width:'65%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <div style={{width:'45%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{//AAAA：与BBBB相同
                                            if(item.collectChenpinglong==null){
                                                this.state.wholesaleItemList[key].collectChenpinglong = this.state.wholesaleItemList[key].wholesaleUnCollect;
                                                this.state.wholesaleItemList[key].discountChenpinglong = 0;
                                                for(let i=0;i<item.wholesaleMoneys.length;i++){
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].collectChenpinglong = this.state.wholesaleItemList[key].wholesaleMoneys[i].unCollect;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].discountChenpinglong = 0;
                                                }
                                            }
                                            if(this.state.wholesaleItemList[key].isSelected){
                                                this.state.wholesaleItemList[key].isSelected = false;
                                                this.state.wholesaleItemList[key].collectChenpinglong = null;
                                                this.state.wholesaleItemList[key].discountChenpinglong = null;
                                                for(let i=0;i<item.wholesaleMoneys.length;i++){
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].collectChenpinglong = null;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].discountChenpinglong = null;
                                                }
                                            }else{this.state.wholesaleItemList[key].isSelected = true;}
                                            this.state.isShowItemInfoKey=-1;
                                            this.updateTotalCollectMoney();
                                        }}><span style={{width:'40%',color:'#F00',fontSize:16,fontWeight:'bold',textAlign:'center'}}>{arithUtil.fenToYuan(item.wholesaleUnCollect)}</span>
                                        <span style={{width:'60%',fontSize:16}}>{item.describe?item.describe:''}</span></div>
                                        {item.collectChenpinglong!=null?<div style={{width:'55%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:'55%',height:35,color:'#2E8B57',fontSize:16,fontWeight:'bold'})} type='number' value={!isNaN(item.collectChenpinglongIskone)||(item.collectChenpinglong!=null&&item.collectChenpinglong!=0)?arithUtil.fenToYuan(item.collectChenpinglong):''} placeholder='0' onChange={(event)=>{
                                                this.state.wholesaleItemList[key].collectChenpinglongIskone=arithUtil.yuanToFen(event.target.value);
                                                event.target.value = event.target.value!=''?event.target.value:0;
                                                this.state.wholesaleItemList[key].collectChenpinglong = arithUtil.yuanToFen(event.target.value);
                                                let beforesOwnerC=0;
                                                for(let j=0;j<this.state.wholesaleItemList[key].wholesaleMoneys.length;j++){
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (arithUtil.yuanToFen(event.target.value)).mul(this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect).div(this.state.wholesaleItemList[key].wholesaleUnCollect).round();
                                                    if(j!=this.state.wholesaleItemList[key].wholesaleMoneys.length-1){
                                                        let cdR = (this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong).add(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                        if(cdR>this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect){
                                                            this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect).sub(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                        }
                                                        // if(cdR<=this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect){
                                                        //     this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong).round();
                                                        // }
                                                    }else{
                                                        this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].collectChenpinglong).sub(beforesOwnerC);
                                                    }
                                                    beforesOwnerC=beforesOwnerC.add(this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong);
                                                }
                                                this.updateTotalCollectMoney();
                                            }}/>
                                            <input style={arithUtil.stylesAdd(styles.textInput,{width:'30%',height:35,color:'#2E8B57',fontSize:16,fontWeight:'bold'})} type='number' value={!isNaN(item.discountChenpinglongIskong)||(item.discountChenpinglong!=null&&item.discountChenpinglong!=0)?arithUtil.fenToYuan(item.discountChenpinglong):''} placeholder='0' onChange={(event)=>{
                                                this.state.wholesaleItemList[key].discountChenpinglongIskong = arithUtil.yuanToFen(event.target.value);
                                                event.target.value = event.target.value!=''?event.target.value:0;
                                                this.state.wholesaleItemList[key].discountChenpinglong = arithUtil.yuanToFen(event.target.value);
                                                let collectAddDiscount = (this.state.wholesaleItemList[key].collectChenpinglong).add(arithUtil.yuanToFen(event.target.value));
                                                let beforesOwnerC=0,beforesOwnerD=0;
                                                for(let j=0;j<this.state.wholesaleItemList[key].wholesaleMoneys.length;j++){
                                                    if(collectAddDiscount>this.state.wholesaleItemList[key].wholesaleUnCollect){
                                                        this.state.wholesaleItemList[key].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleUnCollect).sub(arithUtil.yuanToFen(event.target.value));
                                                        this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].collectChenpinglong).mul(this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect).div(this.state.wholesaleItemList[key].wholesaleUnCollect).round();
                                                    }
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong = (arithUtil.yuanToFen(event.target.value)).mul(this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect).div(this.state.wholesaleItemList[key].wholesaleUnCollect).round();
                                                    if(j!=this.state.wholesaleItemList[key].wholesaleMoneys.length-1){
                                                        let cdR = (this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong).add(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                        if(cdR>this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect){
                                                            this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect).sub(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                        }
                                                        // if(cdR<=this.state.wholesaleItemList[key].wholesaleMoneys[j].unCollect){
                                                        //     this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong).round();
                                                        //     this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong).round();
                                                        // }
                                                    }else{
                                                        this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong = (this.state.wholesaleItemList[key].collectChenpinglong).sub(beforesOwnerC);
                                                        this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong = (this.state.wholesaleItemList[key].discountChenpinglong).sub(beforesOwnerD);
                                                    }
                                                    beforesOwnerC=beforesOwnerC.add(this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong);
                                                    beforesOwnerD=beforesOwnerD.add(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                }
                                                this.updateTotalCollectMoney();
                                            }}/>
                                            <div style={{width:'13%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                                this.setState({isShowItemInfoKey:this.state.isShowItemInfoKey==key?-1:key});
                                            }}>{this.state.isShowItemInfoKey!=null&&this.state.isShowItemInfoKey==key&&item.collectChenpinglong!=null&&item.wholesaleMoneys!=null?<i className="iconfontIndexCss" style={{fontSize:20,color:'#d2855d'}}>&#xe606;</i>
                                                :<i className="iconfontIndexCss" style={{fontSize:20,color:'#d2855d'}}>&#xe601;</i>}
                                            </div>
                                        </div>:<div style={{width:'55%',height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{//BBBB：与AAAA相同
                                            if(item.collectChenpinglong==null){
                                                this.state.wholesaleItemList[key].collectChenpinglong = this.state.wholesaleItemList[key].wholesaleUnCollect;
                                                this.state.wholesaleItemList[key].discountChenpinglong = 0;
                                                for(let i=0;i<item.wholesaleMoneys.length;i++){
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].collectChenpinglong = this.state.wholesaleItemList[key].wholesaleMoneys[i].unCollect;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].discountChenpinglong = 0;
                                                }
                                            }
                                            if(this.state.wholesaleItemList[key].isSelected){
                                                this.state.wholesaleItemList[key].isSelected = false;
                                                this.state.wholesaleItemList[key].collectChenpinglong = null;
                                                this.state.wholesaleItemList[key].discountChenpinglong = null;
                                                for(let i=0;i<item.wholesaleMoneys.length;i++){
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].collectChenpinglong = null;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[i].discountChenpinglong = null;
                                                }
                                            }else{this.state.wholesaleItemList[key].isSelected = true;}
                                            this.state.isShowItemInfoKey=-1;
                                            this.updateTotalCollectMoney();
                                        }}><span style={{width:'97.5%',height:'35px',lineHeight:'35px',color:'#DCDCDC',borderStyle:'dotted',borderColor:'#E8E8E8',borderWidth:1,borderRadius:8,textAlign:'center'}}>{stringUtil.language(484)}</span></div>}
                                    </div>
                                </div>
                                {this.state.isShowItemInfoKey!=null&&this.state.isShowItemInfoKey==key&&item.collectChenpinglong!=null&&item.wholesaleMoneys!=null?item.wholesaleMoneys.map((items,keys)=>{
                                    return(<div style={{height:32,display:'flex',flexDirection:'row',alignItems:'center'}} key={keys}>
                                        <span style={{width:'30%',color:'#DAA520',fontSize:11,paddingLeft:10}}>{items.targetType==1?stringUtil.language(426):items.targetName}</span>
                                        <div style={{width:'65%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                            <div style={{width:'45%',display:'flex',flexDirection:'row'}}>
                                                <span style={{width:'40%',color:'#F00',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(items.unCollect)}</span>
                                                <span style={{width:'60%',color:'transparent',fontSize:13,textAlign:'center'}}>-</span>
                                            </div>
                                            <div style={{width:'55%',display:'flex',flexDirection:'row',justifyContent:'space-around'}}>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'55%',height:30,fontSize:11})} type='number' value={!isNaN(items.collectChenpinglongIskong)||(items.collectChenpinglong!=null&&items.collectChenpinglong!=0)?arithUtil.fenToYuan(items.collectChenpinglong):''} placeholder='0' onChange={(event)=>{
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[keys].collectChenpinglongIskong = arithUtil.yuanToFen(event.target.value);
                                                    event.target.value = event.target.value!=''?event.target.value:0;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[keys].collectChenpinglong = arithUtil.yuanToFen(event.target.value);
                                                    this.state.wholesaleItemList[key].collectChenpinglong = 0;
                                                    for(let j=0;j<this.state.wholesaleItemList[key].wholesaleMoneys.length;j++){
                                                        this.state.wholesaleItemList[key].collectChenpinglong = (this.state.wholesaleItemList[key].collectChenpinglong).add(this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong);
                                                    }
                                                    this.updateTotalCollectMoney();
                                                }}/>
                                                <input style={arithUtil.stylesAdd(styles.textInput,{width:'30%',height:30,fontSize:11})} type='number' value={!isNaN(items.discountChenpinglongIskong)||(items.discountChenpinglong!=null&&items.discountChenpinglong!=0)?arithUtil.fenToYuan(items.discountChenpinglong):''} placeholder='0' onChange={(event)=>{
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[keys].discountChenpinglongIskong = arithUtil.yuanToFen(event.target.value);
                                                    event.target.value = event.target.value!=''?event.target.value:0;
                                                    this.state.wholesaleItemList[key].wholesaleMoneys[keys].discountChenpinglong = arithUtil.yuanToFen(event.target.value);
                                                    this.state.wholesaleItemList[key].discountChenpinglong = 0;
                                                    for(let j=0;j<this.state.wholesaleItemList[key].wholesaleMoneys.length;j++){
                                                        this.state.wholesaleItemList[key].discountChenpinglong = (this.state.wholesaleItemList[key].discountChenpinglong).add(this.state.wholesaleItemList[key].wholesaleMoneys[j].discountChenpinglong);
                                                    }
                                                    let collectAddDiscount = (this.state.wholesaleItemList[key].wholesaleMoneys[keys].collectChenpinglong).add(arithUtil.yuanToFen(event.target.value));
                                                    if(collectAddDiscount>this.state.wholesaleItemList[key].wholesaleMoneys[keys].unCollect){
                                                        this.state.wholesaleItemList[key].wholesaleMoneys[keys].collectChenpinglong = (this.state.wholesaleItemList[key].wholesaleMoneys[keys].unCollect).sub(arithUtil.yuanToFen(event.target.value));
                                                        this.state.wholesaleItemList[key].collectChenpinglong = 0;
                                                        for(let j=0;j<this.state.wholesaleItemList[key].wholesaleMoneys.length;j++){
                                                            this.state.wholesaleItemList[key].collectChenpinglong = (this.state.wholesaleItemList[key].collectChenpinglong).add(this.state.wholesaleItemList[key].wholesaleMoneys[j].collectChenpinglong);
                                                        }
                                                    }
                                                    this.updateTotalCollectMoney();
                                                }}/>
                                                <div style={{width:'13%'}}></div>
                                            </div>
                                        </div>
                                    </div>)
                                }):''}
                            </div>)
                        })}
                    </div>
                </div>
                <div style={{width:'35%',height:'100%',backgroundColor:'#FFF',marginLeft:'0.5%',paddingLeft:arithUtil.pxToDp(10),paddingTop:arithUtil.pxToDp(15)}}>
                    <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:18}}>{stringUtil.language(485)}</span>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:150})} onChange={(event)=>{
                            this.state.searchs.workerId = JSON.parse(event.target.value).id;
                            this.initData();
                        }}>{this.state.workersAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                        })}
                        </select>
                    </div>
                    <span style={{height:arithUtil.pxToDp(40),fontSize:18,fontWeight:'bold',textAlignVertical:'center'}}>{stringUtil.language(903)}{this.state.currCustomerArrears.customerName}</span><br/>
                    <span style={{color:'#FF8C00'}}>{stringUtil.language(1182)} {this.state.wholesaleItemListTotalInfo.number} {stringUtil.language(1086)}{arithUtil.fenToYuan(this.state.wholesaleItemListTotalInfo.unCollect)} {stringUtil.currency(1)}</span>
                    <div style={{height:arithUtil.pxToDp(70),display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:20}}>{stringUtil.language(905)}</span>
                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(150),height:arithUtil.pxToDp(35)})} type='number' value={!isNaN(this.state.totalCollectInfo.totalCollectMoney)?arithUtil.fenToYuan(this.state.totalCollectInfo.totalCollectMoney):''} placeholder='0' onChange={(event)=>{
                            this.state.totalCollectInfo.totalCollectMoney = arithUtil.yuanToFen(event.target.value);
                            this.updateWholesaleCollect();
                        }}/><span> {stringUtil.currency(1)}</span>
                    </div>
                    <div style={{height:'50%',overflow:'auto'}}>
                        <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        {this.state.accountsAll.map((item,key)=>{
                            let accountIndexKey = this.state.totalCollectInfo.accountIndex;
                            return(<div style={{width:'31%',height:arithUtil.pxToDp(60),borderStyle:'solid',borderWidth:1,borderColor:'#DCDCDC',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                this.state.totalCollectInfo.accountId = item.id;
                                this.state.totalCollectInfo.accountIndex = key;
                                this.setState({totalCollectInfo:this.state.totalCollectInfo});
                            }}>
                                {item.type==0?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#F4B015':'#A9A9A9'}}>&#xe632;</i>:
                                item.type==1?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#3BCA72':'#A9A9A9'}}>&#xe605;</i>:
                                item.type==2?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#5A9EF7':'#A9A9A9'}}>&#xe65f;</i>:
                                <i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#CC4B46':'#A9A9A9'}}>&#xe607;</i>}
                                <span style={{color:(accountIndexKey!=null&&accountIndexKey==key)?'#696969':'#A9A9A9',fontSize:10}}>{item.name}</span>
                            </div>)
                        })}
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(858)}</span>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:'75%'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                            this.state.totalCollectInfo.describe = event.target.value;
                        }}/>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:arithUtil.pxToDp(20)}}>
                        <span style={{width:'40%',height:'35px',color:'#FFF',lineHeight:'35px',borderRadius:3,backgroundColor:'#2B8D53',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if (this.state.totalCollectInfo.accountId==null) {
                                    ToastBox.error(stringUtil.language(95));return;
                                }
                                this.setState({submitShowPop:!this.state.submitShowPop});
                            }}>{stringUtil.language(859)}</span>
                        <span style={{width:'40%',height:'35px',color:'#808080',lineHeight:'35px',borderRadius:3,backgroundColor:'#DCDCDC',marginLeft:arithUtil.pxToDp(10),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }}>{stringUtil.language(860)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',position:'absolute',bottom:0,right:0}}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.totalCollectInfo.time)} onChange={(date) => {
                            this.state.totalCollectInfo.time = arithUtil.formatToTimeStr(date,'Y-M-D')+arithUtil.formatToTimeStr(new Date((new Date()).valueOf()),' h:m:s');
                            this.setState({totalCollectInfo:this.state.totalCollectInfo});
                        }} />
                    </div>
                </div>
            </div>
            <MaskModal visible={this.state.submitShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:16}}>{stringUtil.language(1048)}: {this.state.currCustomerArrears.customerName}</span></div>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:16}}>{stringUtil.language(486)}: {arithUtil.fenToYuan(this.state.wholesaleItemListTotalInfo.unCollect)}</span></div>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:16}}>{stringUtil.language(487)}: {arithUtil.fenToYuan(this.state.totalCollectInfo.totalCollectMoney)}</span></div>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:16}}>{stringUtil.language(488)}: {arithUtil.fenToYuan(this.state.totalCollectInfo.totalDiscountMoney)}</span></div>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:16}}>{stringUtil.language(489)}: {arithUtil.fenToYuan((this.state.wholesaleItemListTotalInfo.unCollect).sub(this.state.totalCollectInfo.totalCollectMoney).sub(this.state.totalCollectInfo.totalDiscountMoney))}</span></div>
                    </div>}
                okBnEvents={(event) => {
                    this.setState({submitShowPop:false},()=>{
                        let customerCollectInsertVo = {collects:[]};
                        let totalDiscountMoney = 0;
                        for(let i=0;i<this.state.wholesaleItemList.length;i++){
                            let wholesaleTemp = this.state.wholesaleItemList[i];
                            if(wholesaleTemp.discountChenpinglong!=null&&wholesaleTemp.discountChenpinglong!=0) totalDiscountMoney = totalDiscountMoney.add(wholesaleTemp.discountChenpinglong);
                            for(let j=0;j<wholesaleTemp.wholesaleMoneys.length;j++){
                                let moneyTemp = wholesaleTemp.wholesaleMoneys[j];
                                if ((moneyTemp.collectChenpinglong!=null&&moneyTemp.collectChenpinglong!=0) || (moneyTemp.discountChenpinglong!=null&&moneyTemp.discountChenpinglong!=0)) {
                                    let collectDetailedTemp = {
                                        collectMode:wholesaleTemp.isInitArrearsMode==1?0:1,//0.stringUtil.language(913) 1stringUtil.language(1185)stringUtil.language(865)
                                        wholesaleId:wholesaleTemp.isInitArrearsMode==1?null:wholesaleTemp.id,
                                        targetType:moneyTemp.targetType,
                                        targetId:moneyTemp.targetId,
                                        discountMoney:moneyTemp.discountChenpinglong,
                                        collectMoney:moneyTemp.collectChenpinglong
                                    };
                                    customerCollectInsertVo.collects.push(collectDetailedTemp);
                                }
                            }
                        }
                        if (this.state.totalCollectInfo.totalCollectMoney==0 && totalDiscountMoney==0) {
                            ToastBox.error(stringUtil.language(96));return;
                        }
                        customerCollectInsertVo.time = arithUtil.timeToNumber(this.state.totalCollectInfo.time);
                        customerCollectInsertVo.customerId = this.state.currCustomerArrears.customerId;
                        customerCollectInsertVo.accountId = this.state.totalCollectInfo.accountId;
                        customerCollectInsertVo.discountMoney = totalDiscountMoney;
                        customerCollectInsertVo.collectMoney = this.state.totalCollectInfo.totalCollectMoney;
                        customerCollectInsertVo.describe = this.state.totalCollectInfo.describe!=null?this.state.totalCollectInfo.describe:'';
                        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,customerCollectInsertVo,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(388));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    });
                }} closeBnEvents={() => {this.setState({submitShowPop: false})}}/>
            <MaskModal title={stringUtil.language(293)} visible={this.state.wholesaleInfoShowPop} width={arithUtil.pxToDp(600)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.currWholesaleInfoItem!=null?this.state.currWholesaleInfoItem.id:0}>
                  {this.state.currWholesaleInfoItem!=null?<WholesaleInfoView wholesaleItem={this.state.currWholesaleInfoItem} context={this}/>:''}
                </div>} closeBnTopEvents={() => {this.setState({wholesaleInfoShowPop:false})}}/>
            {this.state.printWholesaleShow?<div style={{width:'100%',overflowY:'auto'}} id='printWholesale'>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:25,fontWeight:'bold'}}>{this.state.wholesalePCMDO.printTitle}</span></div>
                <div style={{height:25,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:15}}>{this.state.wholesalePCMDO.marketName}</span>
                </div>
                <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:13}}>{stringUtil.language(903)}</span><span style={{fontSize:20,fontWeight:'bold'}}>{this.state.wholesalePCMDO.customerName}</span>
                    </div>
                    <span style={{fontSize:13}}>{stringUtil.language(911)}{this.state.wholesalePCMDO.customerAdname?this.state.wholesalePCMDO.customerAdname:''}{this.state.wholesalePCMDO.customerAddress?this.state.wholesalePCMDO.customerAddress:''}</span>
                </div>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',borderTopStyle:'solid',borderTopColor:'#000',borderTopWidth:'1px',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                    <span style={{width:'13%'}}>{stringUtil.language(1067)}</span>
                    <span style={{width:'23%'}}>{stringUtil.language(1020)}</span>
                    <span style={{width:'9%',textAlign:'right'}}>{stringUtil.language(1021)}</span>
                    <span style={{width:'12%',textAlign:'right'}}>{stringUtil.language(1022)}</span>
                    <span style={{width:'16%',textAlign:'right'}}>{stringUtil.language(1050)}</span>
                    <span style={{width:'13%',textAlign:'right'}}>{stringUtil.language(1041)}</span>
                    <span style={{width:'14%',textAlign:'right'}}>{stringUtil.language(891)}</span>
                </div>
                {(()=>{this.state.wholesaleTotalInfo={number:0,weight:0,money:0}})()}
                {this.state.wholesalePCMDO.commodities?this.state.wholesalePCMDO.commodities.map((item,key)=>{
                    this.state.wholesaleTotalInfo.number=this.state.wholesaleTotalInfo.number+item.number;
                    this.state.wholesaleTotalInfo.weight=(this.state.wholesaleTotalInfo.weight).add(item.weight);
                    let money=item.goodsMoney;
                    if(item.costs==null)item.costs=[];
                    for(let i=0;i<item.costs.length;i++){
                        money=money.add(item.costs[i].money);
                    }
                    this.state.wholesaleTotalInfo.money=(this.state.wholesaleTotalInfo.money).add(money);
                    return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'13%'}}>{arithUtil.formatToTimeStrs(new Date(item.time),'M'+stringUtil.language(1178)+'D'+stringUtil.language(1179))}</span>
                        <span style={{width:'23%'}}>{(key+1)+'.'+stringUtil.subStr(item.commodityName,8)}</span>
                        <span style={{width:'9%',textAlign:'right'}}>{item.number}</span>
                        <span style={{width:'12%',textAlign:'right'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                        <span style={{width:'16%',textAlign:'right'}}>{item.mode==1?(arithUtil.fenToYuan(item.unitPrice,2)+(item.unitMaster?'/'+item.unitMaster:'/'+stringUtil.language(1173))):(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)))}</span>
                        <span style={{width:'13%',textAlign:'right'}}>{arithUtil.fenToYuan(money)}</span>
                        <span style={{width:'14%',textAlign:'right'}}>{item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5)}</span>
                    </div>);
                }):''}
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',marginTop:45}}>{this.state.wholesalePCMDO.printWholesaleText}</div>
                <div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1072)}:{this.state.wholesaleTotalInfo.number}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.wholesaleTotalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}{stringUtil.language(464)}{arithUtil.fenToYuan(this.state.wholesalePCMDO.advanceMoney)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        {this.state.wholesalePCMDO.costs?<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                            {this.state.wholesalePCMDO.costs.map((item,key)=>{
                                return(<span style={{marginRight:10}} key={key}>{item.costClauseName}:{arithUtil.fenToYuan(item.money)}</span>);
                            })}
                        </div>:''}{/*20220711吕stringUtil.language(1027)这样显示*/}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(465)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney))}</span>
                    </div>
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1038)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.collectMoney)}</span>
                        {this.state.wholesalePCMDO.discountMoney?<span>,{stringUtil.language(1042)}:</span>:''}{this.state.wholesalePCMDO.discountMoney?<span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.discountMoney)}</span>:''}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1087)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney)).sub((this.state.wholesalePCMDO.collectMoney).add(this.state.wholesalePCMDO.discountMoney)))}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:35}}>{stringUtil.language(490)}:</span><span style={{fontSize:35,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.newestArrears)}</span>
                    </div>
                </div>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{fontSize:13}}>{stringUtil.language(1030)}:{this.state.wholesalePCMDO.shopName}</span>
                        <span style={{fontSize:13}}>{stringUtil.language(1073)}:{this.state.wholesalePCMDO.shopMobile}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{fontSize:13}}>地址:{this.state.wholesalePCMDO.shopAdname?this.state.wholesalePCMDO.shopAdname:''}{this.state.wholesalePCMDO.shopAddress?this.state.wholesalePCMDO.shopAddress:''}</span>
                        <span style={{fontSize:13}}>{stringUtil.language(1033)}:{(localStorage.getItem("currentUserItem")?JSON.parse(localStorage.getItem("currentUserItem")).noteName:'')+arithUtil.formatToTimeStr(new Date(),'M.D h:m')}{stringUtil.language(1180)}</span>
                    </div>
                </div>
            </div>:''}
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTd:{
        width:'12%',
        fontSize:12,
        textAlign:'center',
        textAlignVertical:'center',
    },
};