import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import * as printUtil from '../utils/printUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput.js';
import WholesaleInfoView from './WholesaleInfoView.js';

export default class TabMoneyCustomerCollectFind extends Component{
  constructor(props){
    super(props);
    this.state={
        searchs:{
            customerId:-1,
            customerName:'',
            accountId:-1,
            targetId:-1,
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        weightMode:0,//0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
        collectSearchDtoObj:{},
        accountsAll:[],
        personsAll:[],
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        customerCollectItemList:[],
        currCustomerCollectItem:{collectMoney:0,discountMoney:0},
        infoShowPop:false,
        delShowPop:false,
        wholesaleInfoShowPop:false,
        keyTagVal:-1,
     };
     if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
  }
    componentDidMount(){
        this.getDataList();
        stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.accountsAll = successResponse.data;
                this.state.accountsAll.unshift({id:-1,name:stringUtil.language(695)});
                this.setState({accountsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let workersAll = successResponse.data;
                for(let i=0;i<workersAll.length;i++){
                    if (workersAll[i].state==0) {
                        this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                    }
                }
                this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(349)});
                this.setState({personsAll:this.state.personsAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,{
            customerId:this.state.searchs.customerId,
            customerName:this.state.searchs.customerName,
            accountId:this.state.searchs.accountId,
            workerId:this.state.searchs.targetId,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.collectSearchDtoObj = successResponse.data;
                this.state.totalNum = this.state.collectSearchDtoObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({customerCollectItemList:this.state.collectSearchDtoObj.items});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
      var _this= this;
      _this.setState({goValue : e.target.value})
      var value = e.target.value;
      if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
      else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
      else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }
    
    render(){ 
        return(<div style={{width:'100%',height:'100%'}}>
            <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:7,paddingRight:7}}>
                <div style={{width:180,height:25,backgroundColor:'#FFF'}}>
                    <SearchInput onChangeEvents={(value)=>{
                        this.state.searchs.customerName = value;
                        this.getDataList();
                    }} placeholder={stringUtil.language(1010)+stringUtil.language(1048)+'名'+stringUtil.language(1034)+'..'}/>
                </div>
                <select style={{width:120,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                    this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                    this.getDataList();
                }}>
                    {this.state.personsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                    })}
                </select>
                <select style={{width:140,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                    this.state.searchs.accountId = JSON.parse(event.target.value).id;
                    this.getDataList();
                }}>
                    {this.state.accountsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
                    <div style={styles.time}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                            this.getDataList();
                        }} />
                        <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                    </div> {stringUtil.language(1174)} <div style={styles.time}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                            this.getDataList();
                        }} />
                        <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                    </div>
                </div>
            </div>
            <div style={{height:'92%',paddingLeft:arithUtil.pxToDp(7),paddingRight:arithUtil.pxToDp(7)}}>
                <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1040)}</span>
                    <span style={{width:'21%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1039)}</span>
                    <span style={{width:'16%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                    <span style={{width:'15%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1140)}</span>
                    <span style={{width:'11%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1080)}</span>
                    <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1042)}</span>
                    <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{height:'85%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.customerCollectItemList.map((item,key)=>{
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'17%',fontSize:16,textAlign:'center'}}>SK{item.customerCollectNo}</span>
                            <span style={{width:'21%',fontSize:10,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                            <span style={{width:'16%',fontSize:16}}>{item.customerName}</span>
                            <span style={{width:'15%',fontSize:16}}>{item.accountName}</span>
                            <span style={{width:'11%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                            <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                            <div style={{width:'10%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <i className="iconfontIndexCss" style={{fontSize:25,color:'#A9A9A9',cursor:'pointer'}} onClick={()=>{
                                    this.state.currCustomerCollectItem = JSON.parse(JSON.stringify(item));
                                    this.setState({infoShowPop: !this.state.infoShowPop});
                                }}>&#xe64c;</i>
                            </div>
                        </div>);
                    })}
                </div>
                <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                        current={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        goValue={this.state.goValue}
                        pageClick={this.pageClick.bind(this)}
                        goPrev={this.goPrevClick.bind(this)}
                        goNext={this.goNext.bind(this)}
                        switchChange={this.goSwitchChange.bind(this)}/>:''}
                    <span style={{color:'#F00'}}>{stringUtil.language(1182)}{this.state.totalNum}{stringUtil.language(696)}{arithUtil.fenToYuan(this.state.collectSearchDtoObj.collectMoney)}{stringUtil.currency(1)}{stringUtil.language(906)}{arithUtil.fenToYuan(this.state.collectSearchDtoObj.discountMoney)}{stringUtil.currency(1)}</span>
                </div>
            </div>
            <MaskModal title={stringUtil.language(697)} visible={this.state.infoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
                <div style={{paddingLeft:10,paddingRight:10}}>
                    <div style={{width:'100%',height:195,position:'absolute',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><i className="iconfontIndexCss" style={{fontSize:150,color:'rgba(169,169,169,0.3)'}}>&#xe609;</i></div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(669)}：</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currCustomerCollectItem.time),'Y-M-D h:m:s')}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(670)}：</span><span>{this.state.currCustomerCollectItem.workerId==0?stringUtil.language(431):this.state.currCustomerCollectItem.workerName}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(671)}：</span><span style={{color:'#F00'}}>SK.{this.state.currCustomerCollectItem.customerCollectNo}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(672)}：</span><span>{this.state.currCustomerCollectItem.customerName}</span>
                    </div>
                    <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(673)}：</span><span>{this.state.currCustomerCollectItem.accountName}</span>
                    </div>
                    <div style={{width:'100%',height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <span>{stringUtil.language(674)}：</span><div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.currCustomerCollectItem.collectMoney).add(this.state.currCustomerCollectItem.discountMoney))}</span><span>{stringUtil.currency(1)}</span>
                            </div>
                            <span style={{color:'#A9A9A9',fontSize:10}}>{stringUtil.language(1080)}{arithUtil.fenToYuan(this.state.currCustomerCollectItem.collectMoney)}{stringUtil.currency(1)}{stringUtil.language(906)}{arithUtil.fenToYuan(this.state.currCustomerCollectItem.discountMoney)}{stringUtil.currency(1)}</span>
                        </div>
                    </div>
                </div>
                <div style={{height:30,backgroundColor:'#7CF5B9',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'25%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(471)}</span>
                    <span style={{width:'37%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                    <span style={{width:'21%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1080)}</span>
                    <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1079)}</span>
                </div>
                <div style={{width:'100%',display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
                    {this.state.currCustomerCollectItem.collects!=null?this.state.currCustomerCollectItem.collects.map((item,key)=>{
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                            if(item.collectMode==1){
                                this.state.wholesaleInfoItem={id:item.wholesaleId};
                                this.setState({wholesaleInfoShowPop:!this.state.wholesaleInfoShowPop});
                            }
                        }}>
                            <span style={{width:'25%',color:item.collectMode==0?'#007ACC':'#696969',fontSize:12,textAlign:'center'}}>{item.collectMode==0?stringUtil.language(913):'No.'+item.wholesaleNo}</span>
                            <span style={{width:'37%',fontSize:12}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                            <span style={{width:'21%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                            <span style={{width:'17%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                        </div>)
                    }):<div/>}
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{fontSize:12,paddingLeft:5}}>{stringUtil.language(858)}{this.state.currCustomerCollectItem.describe}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("030104")!=null?<span style={{width:95,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({delShowPop:!this.state.delShowPop});
                        }}><i className="iconfontIndexCss" style={{fontSize:15,color:'#FFF'}}>&#xe612;</i> {stringUtil.language(1113)}</span>:''}
                        <span style={{width:95,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_PRINT_URL+'/'+this.state.currCustomerCollectItem.id,null,['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    printUtil.toComputerPrint(otherUtil.cusCollectInfoNull(successResponse.data),1);
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><i className="iconfontIndexCss" style={{fontSize:13,color:'#FFF'}}>&#xe867;</i> {stringUtil.language(698)}</span>
                        <span style={{width:95,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#3CB371',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            if(this.state.currCustomerCollectItem.collects){
                                let wholesaleIds=[];
                                for(let i=0;i<this.state.currCustomerCollectItem.collects.length;i++){
                                    let item = this.state.currCustomerCollectItem.collects[i];
                                    if(item.collectMode==1){
                                        let isHave=0;
                                        for(let j=0;j<wholesaleIds.length;j++){
                                            if(wholesaleIds[j]==item.wholesaleId){isHave=1;break;}
                                        }
                                        if(!isHave)wholesaleIds.push(item.wholesaleId);
                                    }
                                }
                                if(wholesaleIds.length>0){
                                    stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,wholesaleIds,['json','post',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            let wholesales=successResponse.data;
                                            for(let i=0;i<wholesales.length;i++){
                                                if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                                printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                                            }
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{ToastBox.error(stringUtil.language(18));}
                            }else{ToastBox.error(stringUtil.language(18));}
                        }}><i className="iconfontIndexCss" style={{fontSize:13,color:'#FFF'}}>&#xe867;</i> {stringUtil.language(699)}</span>
                        <span style={{width:95,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#3CB371',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_CUSTOMER_COLLECT_URL+"/"+this.state.currCustomerCollectItem.id,{},['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.setState({wholesalePCMDO:successResponse.data,printWholesaleShow:true},()=>{
                                        const printArea = document.getElementById('printWholesale');
                                        const iframe = document.createElement('IFRAME');
                                        let doc = null;
                                        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                        document.body.appendChild(iframe);
                                        doc = iframe.contentWindow.document;
                                        doc.write(printArea.innerHTML);
                                        doc.close();
                                        iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                        iframe.contentWindow.print();
                                        if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                        this.setState({printWholesaleShow:false});
                                    });
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><i className="iconfontIndexCss" style={{fontSize:13,color:'#FFF'}}>&#xe867;</i> {stringUtil.language(700)}</span>
                    </div>
                </div>
            </div>} closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
            <MaskModal title={stringUtil.language(701)} visible={this.state.wholesaleInfoShowPop} width={arithUtil.pxToDp(600)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.wholesaleInfoItem?this.state.wholesaleInfoItem.id:0}>
                    {this.state.wholesaleInfoItem?<WholesaleInfoView wholesaleItem={this.state.wholesaleInfoItem} context={this}/>:''}
                </div>} closeBnTopEvents={() => {this.setState({wholesaleInfoShowPop:false})}}/>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(73)}
                </div>}
            okBnEvents={(event) => {
                this.setState({infoShowPop: false,delShowPop: false});
                stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL+'/'+this.state.currCustomerCollectItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
            {this.state.printWholesaleShow?<div style={{width:'100%',overflowY:'auto'}} id='printWholesale'>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:25,fontWeight:'bold'}}>{this.state.wholesalePCMDO.printTitle}</span></div>
                <div style={{height:40,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:15}}>{this.state.wholesalePCMDO.marketName}</span>
                    <span style={{fontSize:15}}>{arithUtil.formatToTimeStr(new Date(this.state.currCustomerCollectItem.time),'Y-M-D h:m:s')}</span>
                </div>
                <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:13}}>{stringUtil.language(903)}</span><span style={{fontSize:20,fontWeight:'bold'}}>{this.state.wholesalePCMDO.customerName}</span>
                    </div>
                    <span style={{fontSize:13}}>{stringUtil.language(911)}{this.state.wholesalePCMDO.customerAdname?this.state.wholesalePCMDO.customerAdname:''}{this.state.wholesalePCMDO.customerAddress?this.state.wholesalePCMDO.customerAddress:''}</span>
                </div>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',borderTopStyle:'solid',borderTopColor:'#000',borderTopWidth:'1px',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                    <span style={{width:'13%'}}>{stringUtil.language(1067)}</span>
                    <span style={{width:'23%'}}>{stringUtil.language(1020)}</span>
                    <span style={{width:'9%',textAlign:'right'}}>{stringUtil.language(1021)}</span>
                    <span style={{width:'12%',textAlign:'right'}}>{stringUtil.language(1022)}</span>
                    <span style={{width:'16%',textAlign:'right'}}>{stringUtil.language(1050)}</span>
                    <span style={{width:'13%',textAlign:'right'}}>{stringUtil.language(1041)}</span>
                    <span style={{width:'14%',textAlign:'right'}}>{stringUtil.language(891)}</span>
                </div>
                {(()=>{this.state.wholesaleTotalInfo={number:0,weight:0,money:0}})()}
                {this.state.wholesalePCMDO.commodities?this.state.wholesalePCMDO.commodities.map((item,key)=>{
                    this.state.wholesaleTotalInfo.number=this.state.wholesaleTotalInfo.number+item.number;
                    this.state.wholesaleTotalInfo.weight=(this.state.wholesaleTotalInfo.weight).add(item.weight);
                    let money=item.goodsMoney;
                    if(item.costs==null)item.costs=[];
                    for(let i=0;i<item.costs.length;i++){
                        money=money.add(item.costs[i].money);
                    }
                    this.state.wholesaleTotalInfo.money=(this.state.wholesaleTotalInfo.money).add(money);
                    return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'13%'}}>{arithUtil.formatToTimeStrs(new Date(item.time),'M'+stringUtil.language(1178)+'D'+stringUtil.language(1179))}</span>
                        <span style={{width:'23%'}}>{(key+1)+'.'+stringUtil.subStr(item.commodityName,8)}</span>
                        <span style={{width:'9%',textAlign:'right'}}>{item.number}</span>
                        <span style={{width:'12%',textAlign:'right'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                        <span style={{width:'16%',textAlign:'right'}}>{item.mode==1?(arithUtil.fenToYuan(item.unitPrice,2)+(item.unitMaster?'/'+item.unitMaster:'/'+stringUtil.language(1173))):(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)))}</span>
                        <span style={{width:'13%',textAlign:'right'}}>{arithUtil.fenToYuan(money)}</span>
                        <span style={{width:'14%',textAlign:'right'}}>{item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5)}</span>
                    </div>);
                }):''}
                <div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:45,borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1072)}:{this.state.wholesaleTotalInfo.number}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.wholesaleTotalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{stringUtil.language(942)}:{arithUtil.fenToYuan(this.state.wholesalePCMDO.advanceMoney)}&nbsp;&nbsp;</span>
                        {this.state.wholesalePCMDO.costs?<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                            {this.state.wholesalePCMDO.costs.map((item,key)=>{
                                return(<span style={{marginRight:10}} key={key}>{item.costClauseName}:{arithUtil.fenToYuan(item.money)}</span>);
                            })}
                        </div>:''}{/*20220711吕stringUtil.language(1027)这样显示*/}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(465)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney))}</span>
                    </div>
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(466)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesalePCMDO.customerArrears).add((this.state.wholesalePCMDO.collectMoney).add(this.state.wholesalePCMDO.discountMoney)).sub((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney)))}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1038)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.collectMoney)}</span>
                        {this.state.wholesalePCMDO.discountMoney?<span>,{stringUtil.language(1042)}:</span>:''}{this.state.wholesalePCMDO.discountMoney?<span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.discountMoney)}</span>:''}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:35}}>{stringUtil.language(467)}:</span><span style={{fontSize:35,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.customerArrears)}</span>{/*this.state.wholesalePCMDO.newestArrears*/}
                    </div>
                </div>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{fontSize:13}}>{stringUtil.language(1030)}:{this.state.wholesalePCMDO.shopName}</span>
                        <span style={{fontSize:13}}>{stringUtil.language(1073)}:{this.state.wholesalePCMDO.shopMobile}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <span style={{fontSize:13}}>地址:{this.state.wholesalePCMDO.shopAdname?this.state.wholesalePCMDO.shopAdname:''}{this.state.wholesalePCMDO.shopAddress?this.state.wholesalePCMDO.shopAddress:''}</span>
                        <span style={{fontSize:13}}>{stringUtil.language(1033)}:{(localStorage.getItem("currentUserItem")?JSON.parse(localStorage.getItem("currentUserItem")).noteName:'')+arithUtil.formatToTimeStr(new Date(),'M.D h:m')}{stringUtil.language(1180)}</span>
                    </div>
                </div>
            </div>:''}
        </div>)
    }
}

const styles = {
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
        paddingLeft:3,
        paddingRight:3
    },
};