import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingSalesman extends Component{
  constructor(props){
    super(props);
    this.state={
      buyersAll:[],
      buyerItem:{},
      addShowPop:false,
      editShowPop:false,
      delShowPop:false,
      customerAll:[],customerAllDatas:[],
    };
  }
  componentDidMount(){
    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
        if(successResponse.status==200){
            this.state.customerAll=successResponse.data;
            this.setState({customerAllDatas:JSON.parse(JSON.stringify(this.state.customerAll))});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      this.initDatas();
  }
  initDatas(){
      stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({buyersAll:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
        <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <span style={{width:'14%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1156)}</span>
            <span style={{width:'50%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1089)}</span>
            <span style={{width:'36%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:'87%',borderColor:'#F0F0F0',borderStyle:'solid',borderWidth: 1,overflow:'auto'}}>
            {this.state.buyersAll.map((item,key)=>{
                return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'14%',textAlign:'center'}}>{item.code}</span>
                    <span style={{width:'50%'}}>{item.name}</span>
                    <div style={{width:'36%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.buyerItem = JSON.parse(JSON.stringify(item));
                            this.state.customerShowPop=true;
                            this.state.customerAllDatas=JSON.parse(JSON.stringify(this.state.customerAll));
                            stringUtil.httpRequest(urlUtil.BASIC_BUYER_CUSTOMER_URL+'/'+this.state.buyerItem.id,null,['key','get',1],(successResponse)=>{
                                if(successResponse.status==200){
                                    this.state.buyerCustomers=successResponse.data;
                                    for(let i=0;i<this.state.customerAllDatas.length;i++){let isHava=0;
                                        for(let j=0;j<this.state.buyerCustomers.length;j++){
                                            if(this.state.customerAllDatas[i].id==this.state.buyerCustomers[j].customerId){isHava=1;break;}
                                        }
                                        if(isHava)this.state.customerAllDatas[i].isChoiceChenpinglong=1;
                                    }
                                    this.setState({buyerCustomers:this.state.buyerCustomers});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>&#xe62f;</i>
                        <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.buyerItem = {id:item.id,name:item.name,code:item.code};
                            this.setState({editShowPop:!this.state.editShowPop});
                        }}>&#xe63a;</i>
                        <i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.buyerItem = {id:item.id,name:item.name,code:item.code};
                            this.setState({delShowPop:!this.state.delShowPop});
                        }}>&#xe612;</i>
                    </div>
                </div>)
            })}
        </div>
        <div style={{height:'8%',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <span style={{width:150,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.state.buyerItem = {};
                  this.setState({addShowPop:!this.state.addShowPop});
                }}><i className="iconfontIndexCss" style={{fontSize:25,color:'#FFF'}}>&#xe601;</i>&nbsp;&nbsp;{stringUtil.language(891)}</span>
        </div>
        {this.state.customerShowPop&&this.state.buyerItem?<div style={{width:700,height:500,position:'absolute',top:0,right:0,backgroundColor:'#FFF',borderStyle:'solid',borderColor:'#F00',borderWidth:1,display:'flex',flexDirection:'column'}}>
            <div style={{width:'100%',height:35,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'rgba(0,0,0,0.1)'}}>
                <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginLeft:10}}>业务员: {this.state.buyerItem.name}{this.state.buyerItem.code?'('+this.state.buyerItem.code+')':''}</span>
                <div style={{width:50,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.setState({customerShowPop:false});
                }}><i className="iconfontIndexCss" style={{fontSize:25,color:'#696969'}}>&#xe631;</i></div>
            </div>
            <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}><div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {(this.state.buyerCustomers?this.state.buyerCustomers:[]).map((item,key)=>{
                    return(<div style={{width:'25%',height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{marginLeft:10}}>{item.customerName}</span>
                    </div>)
                })}
            </div></div>
            <div style={{width:'100%',height:50,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#319B3F',borderWidth:1,borderRadius:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginRight:10,cursor:'pointer'}} onClick={()=>{
                    this.state.customersItemDatas=this.state.customerAllDatas;
                    this.setState({choiceCusShowPop:true});
                }}><span style={{color:'#319B3F'}}>编辑客户</span></div>
            </div>
        </div>:''}
        <MaskModal visible={this.state.choiceCusShowPop?true:false} width={400} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
            content={<div style={{width:'100%',height:'100%',paddingLeft:10,paddingRight:10,display:'flex',flexDirection:'column'}} key={this.state.addShowPopIndex}>
                <div style={{width:'100%',height:35,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <input style={{width:230,height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                        this.state.keySearch = event.target.value;
                        if(this.state.keySearch=='')this.setState({customersItemDatas:this.state.customerAllDatas});
                    }} placeholder={stringUtil.language(32)}/>
                    <span style={{width:40,height:'25px',color:'#FFF',lineHeight:'25px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:5,cursor:'pointer'}} onClick={()=>{
                        this.state.customersItemDatas=[];
                        for(let i=0;i<this.state.customerAllDatas.length;i++){
                            if((this.state.customerAllDatas[i].noteName).indexOf(this.state.keySearch)==-1)continue;
                            this.state.customersItemDatas.push(this.state.customerAllDatas[i]);
                        }
                        this.setState({customersItemDatas:this.state.customersItemDatas});
                    }}>{stringUtil.language(1034)}</span>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if(this.state.isAllChoice){this.state.isAllChoice=0;
                            for(let i=0;i<this.state.customerAllDatas.length;i++){
                                this.state.customerAllDatas[i].isChoiceChenpinglong=0;
                            }
                        }else{this.state.isAllChoice=1;
                            for(let i=0;i<this.state.customerAllDatas.length;i++){
                                this.state.customerAllDatas[i].isChoiceChenpinglong=1;
                            }
                        }
                        this.state.customersItemDatas=this.state.customerAllDatas;
                        this.setState({choiceOwners:this.state.choiceOwners});
                    }}>{this.state.isAllChoice?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                        <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                        <span style={{marginRight:7}}>{stringUtil.language(1036)}</span>
                    </div>
                </div>
                <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}>
                    {(this.state.customersItemDatas?this.state.customersItemDatas:[]).map((item,key)=>{
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth:1,cursor:'pointer'}} onClick={()=>{
                            if(item.isChoiceChenpinglong){this.state.customersItemDatas[key].isChoiceChenpinglong=0;
                            }else{this.state.customersItemDatas[key].isChoiceChenpinglong=1;}
                            this.setState({customersItemDatas:this.state.customersItemDatas});
                        }} key={key}>{item.isChoiceChenpinglong?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                            <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                            <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.noteName}</span>
                        </div>)
                    })}
                </div>
            </div>}
            okBnEvents={(event) => {
                this.state.customerShowPop=false;
                this.state.choiceCusShowPop=false;
                let idsResult=[];
                for(let i=0;i<this.state.customerAllDatas.length;i++){
                    if(this.state.customerAllDatas[i].isChoiceChenpinglong){
                        idsResult.push(this.state.customerAllDatas[i].id);
                    }
                }
                if(!idsResult.length){ToastBox.error(stringUtil.language(457));return;}
                stringUtil.httpRequest(urlUtil.BASIC_BUYER_CUSTOMER_URL+'/'+this.state.buyerItem.id,idsResult,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initDatas();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({choiceCusShowPop:false})}}/>
        <MaskModal visible={this.state.addShowPop} title={stringUtil.language(363)} width={320} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
          content={<div style={{height:'100%',paddingRight:10}}>
                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                    <span style={{width:60,textAlign:'center'}}>{stringUtil.language(1089)}</span>
                    <input style={{height:30,display:'flex',flexGrow:1,borderColor:'#d0d0d0',borderStyle:'solid',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.buyerItem.name = event.target.value;
                    }}/>
                </div>
                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                    <span style={{width:60,textAlign:'center'}}>{stringUtil.language(1156)}</span>
                    <input style={{height:30,display:'flex',flexGrow:1,borderColor:'#d0d0d0',borderStyle:'solid',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} placeholder={stringUtil.language(365)+'..'} onChange={(event)=>{
                      this.state.buyerItem.code = event.target.value;
                    }}/>
                </div>
            </div>}
          okBnEvents={(event) => {
            if (typeof(this.state.buyerItem.name)=='undefined' || this.state.buyerItem.name==null || this.state.buyerItem.name=='') {
                ToastBox.error(stringUtil.language(107));return;
            }
            this.setState({addShowPop: false});
            stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,{name:this.state.buyerItem.name,code:this.state.buyerItem.code},['key','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.initDatas();
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({addShowPop: false})}}/>
        <MaskModal visible={this.state.editShowPop} title={stringUtil.language(364)} width={320} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
          content={<div style={{height:'100%',paddingRight:10}} key={this.state.buyerItem.id}>
                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                    <span style={{width:60,textAlign:'center'}}>{stringUtil.language(1089)}</span>
                    <input style={{height:30,display:'flex',flexGrow:1,borderColor:'#d0d0d0',borderStyle:'solid',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} defaultValue={this.state.buyerItem.name} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.buyerItem.name = event.target.value;
                    }}/>
                </div>
                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                    <span style={{width:60,textAlign:'center'}}>{stringUtil.language(1156)}</span>
                    <input style={{height:30,display:'flex',flexGrow:1,borderColor:'#d0d0d0',borderStyle:'solid',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} defaultValue={this.state.buyerItem.code} placeholder={stringUtil.language(365)+'..'} onChange={(event)=>{
                      this.state.buyerItem.code = event.target.value;
                    }}/>
                </div>
            </div>}
          okBnEvents={(event) => {
            if (typeof(this.state.buyerItem.name)=='undefined' || this.state.buyerItem.name==null || this.state.buyerItem.name=='') {
                ToastBox.error(stringUtil.language(107));return;
            }
            this.setState({editShowPop: false});
            stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL+'/'+this.state.buyerItem.id,{name:this.state.buyerItem.name,code:this.state.buyerItem.code},['key','put',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.initDatas();
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({editShowPop: false})}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(124)}？
                    </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL+'/'+this.state.buyerItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initDatas();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {};