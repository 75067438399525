import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class CargoAddCommodityActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        currBatchItem:paramsPage.currCargoItem?paramsPage.currCargoItem:{},
        keySearch:'',
        currCommodityTypeItem:{},
        costsAll:[],
        commoditysAll:[],
        commoditysData:[],
        currCommodities:[],
        addVegetableShowPop:false,
        editVegetableShowPop:false,
        selectDiglogTagEntCost:false,
        currBatchCommodityItem:{},
        currBatchCommodityIndex:-1,
        commodityTypesAll:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.state.currBatchItem.commodities = [];
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let commoditysAllTemp = successResponse.data;
                this.state.commoditysAll = [];
                for(let i=0;i<commoditysAllTemp.length;i++){
                    if (this.state.currBatchItem.cargoMode==0) {//对应某stringUtil.language(1049)targetId
                        this.state.searchNeeds = (commoditysAllTemp[i].targetId==this.state.currBatchItem.targetId)?{
                            is:true,targetType:0,targetId:this.state.currBatchItem.targetId
                        }:{is:false,targetType:0,targetId:this.state.currBatchItem.targetId};
                    }else if (this.state.currBatchItem.cargoMode==1) {//stringUtil.language(426)
                        this.state.searchNeeds = (commoditysAllTemp[i].targetType==1)?{// && commoditysAllTemp[i].systemCommodityId!=0
                            is:true,targetType:1,targetId:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0
                        }:{is:false,targetType:1,targetId:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0};
                    }else if (this.state.currBatchItem.cargoMode==2) {//stringUtil.language(411)
                        this.state.searchNeeds = (commoditysAllTemp[i].targetType==2 && (localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).workerId:0)==commoditysAllTemp[i].targetId)?{
                            is:true,targetType:2,targetId:commoditysAllTemp[i].targetId
                        }:{is:false,targetType:2,targetId:commoditysAllTemp[i].targetId};
                    }
                    if(this.state.searchNeeds!=null&&this.state.searchNeeds.is) this.state.commoditysAll.push(commoditysAllTemp[i]);
                }
                this.setState({commoditysData: this.state.commoditysAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                this.state.commodityTypesAll.unshift({id:0,name:stringUtil.language(854)});
                this.state.commodityTypesAll.unshift({id:-1,name:stringUtil.language(389)});
                this.setState({commodityTypesAll: this.state.commodityTypesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCommoditys(){
        this.state.commoditysData = [];
        for(let i=0;i<this.state.commoditysAll.length;i++){
            let commodityItem = this.state.commoditysAll[i];
            if (commodityItem.name.indexOf(this.state.keySearch)!=-1||(commodityItem.code?commodityItem.code:'').indexOf(this.state.keySearch)!=-1) {
                if (this.state.currCommodityTypeItem.id==null || this.state.currCommodityTypeItem.id==0 || commodityItem.shopCommodityTypeId==this.state.currCommodityTypeItem.id) {
                    this.state.commoditysData.push(commodityItem);
                }
            }
        }
        this.setState({commoditysData:this.state.commoditysData});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(856)}{this.state.currBatchItem.cargoMode==1?stringUtil.language(1051)+(this.state.currBatchItem.targetName?'_'+this.state.currBatchItem.targetName:''):this.state.currBatchItem.targetName} {stringUtil.language(1175)}{this.state.currBatchItem.cargoNo}{stringUtil.language(1176)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(892)}{this.state.currBatchItem.warehouseName}</span>
                    </div>
                </div>
                <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                    {this.state.currBatchItem.cargoMode==0?<span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>:''}
                    {this.state.currBatchItem.cargoMode==0?<span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}({this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)})</span>:''}
                    <span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1024)}</span>
                    <span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1025)}</span>
                    <span style={{width:'25%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{height:'79%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.currBatchItem.commodities!=null?this.state.currBatchItem.commodities.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'20%'}}>{item.commodityName}</span>
                            {this.state.currBatchItem.cargoMode==0?<span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',textAlign:'center'}}>{item.sendNumber}</span>:''}
                            {this.state.currBatchItem.cargoMode==0?<span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',textAlign:'center'}}>{arithUtil.keToWeight(item.sendWeight,this.state.weightMode)}</span>:''}
                            <span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>
                            <span style={{display:'flex',flexGrow:1,flexDirection:'row',justifyContent:'center',textAlign:'center'}}>{arithUtil.keToWeight(item.reduce,this.state.weightMode)}</span>
                            <div style={{width:'25%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            ·   <i className="iconfontIndexCss" style={{fontSize:'27px',color:'#696969',cursor:'pointer'}} onClick={()=>{
                                    this.state.currBatchCommodityItem = JSON.parse(JSON.stringify(item));
                                    this.state.currBatchCommodityIndex = key;
                                    this.setState({editVegetableShowPop:!this.state.editVegetableShowPop});
                                }}>&#xe63a;</i>
                                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#F00',marginLeft:arithUtil.pxToDp(35),cursor:'pointer'}} onClick={()=>{
                                    this.state.currBatchItem.commodities.splice(key,1);
                                    this.setState({currBatchItem:this.state.currBatchItem});
                                }}>&#xe612;</i>
                            </div>
                        </div>)
                    }):<div/>}
                </div>
                <div style={{height:'8%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#696969',lineHeight:'30px',borderRadius:3,borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',backgroundColor:'#EDEDED',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.currBatchItem.cargoMode==0&&!this.state.currBatchItem.targetId) {
                            ToastBox.error(stringUtil.language(189));return;
                        }
                        this.searchCommoditys();
                        this.state.currCommodities = [];
                        this.setState({addVegetableShowPop: !this.state.addVegetableShowPop});
                    }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#C0C0C0'}}>&#xe601; </i>{stringUtil.language(391)}</span>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={{width:arithUtil.pxToDp(120),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if (this.state.currBatchItem.commodities==null || this.state.currBatchItem.commodities.length==0) {
                                ToastBox.error(stringUtil.language(190));return;
                            }
                            let cargoInsertVo = {commodities:[]};
                            for(let i=0;i<this.state.currBatchItem.commodities.length;i++){
                                let commodityTemp = {costs:[]};
                                commodityTemp.commodityId = this.state.currBatchItem.commodities[i].commodityId!=null?this.state.currBatchItem.commodities[i].commodityId:0;
                                commodityTemp.standard = this.state.currBatchItem.commodities[i].standard!=null?this.state.currBatchItem.commodities[i].standard:0;
                                commodityTemp.reduce = this.state.currBatchItem.commodities[i].reduce!=null?this.state.currBatchItem.commodities[i].reduce:0;
                                commodityTemp.sendNumber = this.state.currBatchItem.commodities[i].sendNumber!=null?this.state.currBatchItem.commodities[i].sendNumber:0;
                                commodityTemp.sendWeight = this.state.currBatchItem.commodities[i].sendWeight!=null?this.state.currBatchItem.commodities[i].sendWeight:0;
                                commodityTemp.transferNumber = this.state.currBatchItem.commodities[i].transferNumber!=null?this.state.currBatchItem.commodities[i].transferNumber:0;
                                commodityTemp.transferWeight = this.state.currBatchItem.commodities[i].transferWeight!=null?this.state.currBatchItem.commodities[i].transferWeight:0;
                                commodityTemp.transferMoney = this.state.currBatchItem.commodities[i].transferMoney!=null?this.state.currBatchItem.commodities[i].transferMoney:0;
                                if (this.state.currBatchItem.commodities[i].costs==null)this.state.currBatchItem.commodities[i].costs=[];
                                for(let j=0;j<this.state.currBatchItem.commodities[i].costs.length;j++){
                                    let costsSource = JSON.parse(JSON.stringify(this.state.currBatchItem.commodities[i].costs[j]));
                                    commodityTemp.costs.push({
                                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                        money:costsSource.money!=null?costsSource.money:0,
                                    });
                                }
                                cargoInsertVo.commodities.push(commodityTemp);
                            }
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_COMMODITY_URL+'/'+this.state.currBatchItem.id,{items:cargoInsertVo.commodities},['json','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>{stringUtil.language(859)}</span>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(392)} visible={this.state.addVegetableShowPop} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                        <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} id='keyId' onChange={(event)=>{
                                    this.state.keySearch = event.target.value;
                                    if(this.state.keySearch=='')this.searchCommoditys();
                                    // this.searchCommoditys();
                                }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                                <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.searchCommoditys();
                                }}>{stringUtil.language(1034)}</span>
                            </div>
                            <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(100)})} onChange={(event)=>{
                                    this.state.currCommodityTypeItem = JSON.parse(event.target.value);
                                    this.searchCommoditys();
                                }}>
                                {this.state.commodityTypesAll.map((item,key)=>{
                                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                                })}
                            </select>
                        </div>
                        <div style={{height:'87%',overflow:'auto'}}>
                            {this.state.commoditysData.map((item,key)=>{
                                return(<div key={key} style={{height:55,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',paddingLeft:10,paddingRight:10,cursor:'pointer'}}>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} onClick={()=>{
                                        let isHave = false;
                                        for(let i=0;i<this.state.currCommodities.length;i++){
                                            let comItem = this.state.currCommodities[i];
                                            if (comItem.commodityId==item.id) {
                                                isHave = true;
                                                this.state.currCommodities.splice(i,1);
                                                break;
                                            }
                                        }
                                        if (!isHave) {
                                            this.state.currCommodities.push({
                                                commodityId:item.id,
                                                commodityName:item.name,
                                                standard:item.standard,
                                                reduce:item.reduce,
                                                sendNumber:0,
                                                sendWeight:0,
                                                transferNumber:0,
                                                transferWeight:0,
                                                transferMoney:0,
                                                costs:item.costs,//stringUtil.language(1063)对应stringUtil.language(1037)stringUtil.language(886)目
                                            });
                                        }
                                        this.setState({currCommodities:this.state.currCommodities});
                                    }}>
                                        <img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                                        <span style={{fontSize:17,marginLeft:8}}>{item.name}</span>
                                    </div>
                                    {this.state.currCommodities.map((itemCurr,key)=>{
                                        if (itemCurr.commodityId == item.id) {
                                            return(<div style={{display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                                {this.state.currBatchItem.cargoMode==0?<input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(80),color:this.state.currCommodities[key].sendNumber!=0?'#696969':'#A9A9A9'})} type='number' placeholder={stringUtil.language(1021)} onChange={(event)=>{
                                                    this.state.currCommodities[key].sendNumber = parseInt(event.target.value);
                                                }}/>:''}
                                                {this.state.currBatchItem.cargoMode==0?<input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(80),color:this.state.currCommodities[key].sendWeight!=0?'#696969':'#A9A9A9',marginLeft:arithUtil.pxToDp(15)})} type='number' placeholder={stringUtil.language(1022)} onChange={(event)=>{
                                                    this.state.currCommodities[key].sendWeight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                                }}/>:''}
                                                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056',marginLeft:5}}>&#xe614;</i>
                                            </div>);
                                        }
                                    })}
                                </div>)
                            })}
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if(this.state.currBatchItem.commodities==null) this.state.currBatchItem.commodities = [];
                    let currSelectCommodities = JSON.parse(JSON.stringify(this.state.currCommodities));
                    for(let i=0;i<currSelectCommodities.length;i++){
                        this.state.currBatchItem.commodities.push(currSelectCommodities[i]);
                    }
                    this.setState({addVegetableShowPop: false});
                }} closeBnEvents={() => {this.setState({ addVegetableShowPop: false, })}}/>
            <MaskModal title={stringUtil.language(191)+this.state.currBatchCommodityItem.commodityName} visible={this.state.editVegetableShowPop} width={arithUtil.pxToDp(500)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currBatchCommodityIndex}>
                        {this.state.currBatchItem.cargoMode==0?<div style={{height:'18%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(861)}</span>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='0' defaultValue={this.state.currBatchCommodityItem.sendNumber} onChange={(event)=>{
                                    this.state.currBatchCommodityItem.sendNumber = parseInt(event.target.value);
                                }}/>
                                <span> {stringUtil.language(1173)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(862)}</span>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='0' defaultValue={arithUtil.keToWeight(this.state.currBatchCommodityItem.sendWeight,this.state.weightMode)} onChange={(event)=>{
                                    this.state.currBatchCommodityItem.sendWeight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                }}/>
                                <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                            </div>
                        </div>:''}
                        <div style={{height:'18%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(863)}</span>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='0' defaultValue={arithUtil.keToWeight(this.state.currBatchCommodityItem.standard,this.state.weightMode)} onChange={(event)=>{
                                    this.state.currBatchCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                }}/>
                                <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span>{stringUtil.language(864)}</span>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='0' defaultValue={arithUtil.keToWeight(this.state.currBatchCommodityItem.reduce,this.state.weightMode)} onChange={(event)=>{
                                    this.state.currBatchCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                }}/>
                                <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                            </div>
                        </div>
                        <div style={{height:'14%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <span>{stringUtil.language(89)}</span>
                            <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                if (this.state.costsAll.length==0) {
                                    stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:1},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.costsAll = successResponse.data;
                                            this.setState({selectDiglogTagEntCost:!this.state.selectDiglogTagEntCost});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{this.setState({selectDiglogTagEntCost:!this.state.selectDiglogTagEntCost});}
                            }}>&#xe601;</i>
                            <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCost} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                                    {this.state.costsAll.map((item,key)=>{
                                        if (this.state.currShopCommodityItem.targetType==0 || (this.state.currShopCommodityItem.targetType!=0&&item.payee!=0)) {
                                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} onClick={()=>{
                                            this.state.currBatchCommodityItem.costs.push({
                                                costClauseId:item.id,
                                                costClauseName:item.name,
                                                costClausePayee:item.payee,
                                                costClauseCalMode:item.calMode,
                                                money:0
                                            });
                                            this.setState({selectDiglogTagEntCost:false});
                                            }}>
                                                <span>{item.name}</span>
                                                <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                            </div>);
                                        }
                                    })}
                                </div>}
                                closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCost:false});}}/>
                        </div>
                        <div style={{height:this.state.currBatchItem.cargoMode==0?'50%':'68%',overflow:'auto'}}>
                            {this.state.currBatchCommodityItem.costs!=null?this.state.currBatchCommodityItem.costs.map((item,key)=>{
                                return(<div key={key} style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                                    <span>{key+1+'、'+item.costClauseName}</span>
                                    <span>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                    <span>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                                    {item.costClauseCalMode==0?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='' defaultValue={arithUtil.fenToYuan(item.money,2)} onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                    item.costClauseCalMode==1?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='' defaultValue={arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode)} onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                        }}/>
                                        <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                                    item.costClauseCalMode==2?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='' defaultValue={item.money} onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = parseFloat(event.target.value);
                                        }}/>
                                        <span style={{color:'#D2855D'}}> %</span></div>:
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='' defaultValue={arithUtil.fenToYuan(item.money)} onChange={(event)=>{
                                            this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                        }}/></div>}
                                    <i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                        this.state.currBatchCommodityItem.costs.splice(key,1);
                                        this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                                    }}>&#xe612;</i>
                                </div>)
                            }):<div/>}
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    this.state.currBatchItem.commodities[this.state.currBatchCommodityIndex] = JSON.parse(JSON.stringify(this.state.currBatchCommodityItem));
                    this.setState({editVegetableShowPop: false});
                }} closeBnEvents={() => {this.setState({ editVegetableShowPop: false, })}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:33,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5
    },
    textInput:{
        height:33,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5,
        textAlign:'center',
        textAlignVertical:'center'
    },
};