import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import PurchaseOrderInfoView from './PurchaseOrderInfoView.js';

 export default class BusinessDetailsActivitySupplier extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchSuppliers:{
          startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
          endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
          type:-1//-1stringUtil.language(1097) 0 stringUtil.language(1044)，1 stringUtil.language(1043)
        },
        currSupplierItem:paramsPage.currContactItem?JSON.parse(JSON.stringify(paramsPage.currContactItem)):{},
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        supplierStreamItemAll:[],
        infoShowPop:false,
        itemTemp:{type:0},
        totalInfo:null,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
      this.getDataList();
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SUMM_SUPPLIER_STREAM_URL+'/'+this.state.currSupplierItem.id,{
            startTime:arithUtil.settleSub(this.state.searchSuppliers.startTime),
            endTime:arithUtil.settleSub(this.state.searchSuppliers.endTime),
            type:this.state.searchSuppliers.type,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let supplierStreamDtoObj= successResponse.data;
                this.state.totalNum = supplierStreamDtoObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({supplierStreamItemAll:JSON.parse(JSON.stringify(supplierStreamDtoObj.items))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SUMM_SUPPLIER_STREAM_AGGREGATE_URL+'/'+this.state.currSupplierItem.id,{
            startTime:arithUtil.settleSub(this.state.searchSuppliers.startTime),
            endTime:arithUtil.settleSub(this.state.searchSuppliers.endTime),
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({totalInfo:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
        if(this.state.totalInfo==null)return(<div/>);
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',display:'flex',flexDirection:'row',paddingLeft:'1%',paddingRight:'1%'}}>
                <div style={{width:'75%',marginRight:arithUtil.pxToDp(4),borderRadius:5}}>
                  <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',backgroundColor:'#FFF',paddingLeft:5,paddingRight:5}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <span style={{width:arithUtil.pxToDp(45)}}>{stringUtil.language(875)}</span>
                      <select style={styles.selectStyle} onChange={(event)=>{
                          this.state.searchSuppliers.type = JSON.parse(event.target.value).type;
                          this.getDataList();
                      }}>
                        {[{type:-1,name:stringUtil.language(389)},{type:0,name:stringUtil.language(1044)},{type:1,name:stringUtil.language(1043)}].map((item,key)=>{
                          return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                      </select>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <div style={styles.time}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchSuppliers.startTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchSuppliers.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                            this.getDataList();
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;
                      <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchSuppliers.endTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchSuppliers.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                            this.getDataList();
                        }} /></div>
                    </div>
                  </div>
                  <div style={{height:'92%',backgroundColor:'#FFF'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'27%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',color:'#A9A9A9'})}>{stringUtil.language(1135)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#A9A9A9'})}>{stringUtil.language(1041)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:this.state.totalPage>1?'85%':'95%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.supplierStreamItemAll.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:arithUtil.pxToDp(7)}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'27%',textAlign:'left'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',textAlign:'left'})}>CK.{item.docmentNo}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',textAlign:'left'})}>{item.type==0?stringUtil.language(1044):stringUtil.language(1043)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',})}>{arithUtil.fenToYuan(item.money)}</span>
                                <i className="iconfontIndexCss" style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',fontSize:25,color:'#808080',cursor:'pointer'})} onClick={()=>{
                                  this.state.itemTemp={listId:item.id,id:item.docmentId,type:item.type,title:item.type==0?stringUtil.language(421):stringUtil.language(420)};
                                  if(item.type==0){
                                      this.setState({infoShowPop:!this.state.infoShowPop});
                                  }else{
                                      stringUtil.httpRequest(urlUtil.FINANCE_SUPPLIER_PAY_URL+'/'+item.docmentId,null,['key','get',1],(successResponse)=>{
                                          if (successResponse.status==200) {
                                              this.state.itemTemp.data = successResponse.data;
                                              this.setState({infoShowPop:!this.state.infoShowPop});
                                          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                  }
                                }}>&#xe64c;</i>
                            </div>);
                        })}
                    </div>
                    {this.state.totalPage>1?<div style={{height:'10%'}}>
                        <Pagination total={this.state.totalNum}
                            current={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            goValue={this.state.goValue}
                            pageClick={this.pageClick.bind(this)}
                            goPrev={this.goPrevClick.bind(this)}
                            goNext={this.goNext.bind(this)}
                            switchChange={this.goSwitchChange.bind(this)}/>
                    </div>:''}
                  </div>
                </div>
                <div style={{width:'24%',marginLeft:arithUtil.pxToDp(4),backgroundColor:'#FFF',borderRadius:5}}>
                    <span style={{height:arithUtil.pxToDp(50),fontSize:20,display:'flex',justifyContent:'center',alignItems:'center'}}>{this.state.currSupplierItem.noteName}</span><br/>
                    <span style={{height:arithUtil.pxToDp(35),fontSize:17,paddingLeft:10}}>{stringUtil.language(876)}{arithUtil.fenToYuan(this.state.totalInfo.beforeMoney)}{stringUtil.currency(1)}</span><br/>
                    <span style={{height:arithUtil.pxToDp(35),fontSize:17,paddingLeft:10}}>{stringUtil.language(885)}{arithUtil.fenToYuan(this.state.totalInfo.purchase)}{stringUtil.currency(1)}</span><br/>
                    <span style={{height:arithUtil.pxToDp(35),fontSize:17,paddingLeft:10}}>{stringUtil.language(881)}{arithUtil.fenToYuan(this.state.totalInfo.pay)}{stringUtil.currency(1)}</span>
                    <div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}}>
                      <span style={{fontSize:17}}>{stringUtil.language(880)}</span>
                      <span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.totalInfo.beforeMoney).add(this.state.totalInfo.purchase).sub(this.state.totalInfo.pay))}{stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
            <MaskModal title={this.state.itemTemp.title} visible={this.state.infoShowPop} width={arithUtil.pxToDp(600)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{width:'100%',height:'100%',overflow:'auto'}} key={this.state.itemTemp.listId}>
                  {this.state.itemTemp.type==0?<PurchaseOrderInfoView purchaseOrderItem={this.state.itemTemp} context={this}/>:<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
                      <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:5}}>
                          <span>{stringUtil.language(882)}{this.state.itemTemp.data.supplierPayNo}</span>
                          <span style={{color:'#FFA500'}}>{arithUtil.formatToTimeStr(new Date(this.state.itemTemp.data.time),'Y-M-D h:m:s')}</span>
                      </div>
                      <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}}><span>{stringUtil.language(422)}{this.state.itemTemp.data.supplierName}</span></div>
                      <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}}>
                          <span>{stringUtil.language(883)}</span><span style={{color:'#52A056',fontSize:23,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.itemTemp.data.money)}</span><span> {stringUtil.currency(1)}</span>
                      </div>
                      <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}}><span>{stringUtil.language(884)}{this.state.itemTemp.data.accountName}</span></div>
                      <span style={{marginTop:20,paddingLeft:10}}>{stringUtil.language(858)}{this.state.itemTemp.data.describe}</span>
                  </div>}
                </div>} closeBnTopEvents={() => {this.setState({infoShowPop:false})}}/>
        </div>)
    }
}

const styles = {
    tabItem:{
        width:'24%',
        height:'100%',
        backgroundColor:'#FFF',
        borderRadius:5,
        flexDirection:'row',
        display:'flex',
        alignItems:'center',
        paddingLeft:arithUtil.pxToDp(5)
    },
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'20%',
        fontSize:13,
        textAlign:'center',
        textAlignVertical:'center',
    },
    selectStyle:{
        width: '180px',
        height: '25px',
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth:1,
        borderRadius: '2px',
        paddingLeft: '5px',
        fontSize: '12px'
    },
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};