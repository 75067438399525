import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';

//import * as otherUtil from '../utils/otherUtil.js';
export function getAllAuthoritys(){
  let authorityList = [
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0101","businessName":stringUtil.language(759),"authorityCode":"010101","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0102","businessName":stringUtil.language(838),"authorityCode":"010201","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0102","businessName":stringUtil.language(838),"authorityCode":"010202","authorityName":"权限配置","ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0102","businessName":stringUtil.language(838),"authorityCode":"010203","authorityName":"流水"+stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010301","authorityName":stringUtil.language(739),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010302","authorityName":stringUtil.language(1063)+stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010303","authorityName":stringUtil.language(1063)+stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010304","authorityName":"类目"+stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010305","authorityName":"类目"+stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0103","businessName":stringUtil.language(839),"authorityCode":"010306","authorityName":"类目"+stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0104","businessName":stringUtil.language(840),"authorityCode":"010401","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0105","businessName":stringUtil.language(841),"authorityCode":"010501","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0105","businessName":stringUtil.language(841),"authorityCode":"010502","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0105","businessName":stringUtil.language(841),"authorityCode":"010503","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0105","businessName":stringUtil.language(841),"authorityCode":"010504","authorityName":"流水"+stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0106","businessName":stringUtil.language(379),"authorityCode":"010601","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0106","businessName":stringUtil.language(379),"authorityCode":"010602","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0106","businessName":stringUtil.language(379),"authorityCode":"010603","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0106","businessName":stringUtil.language(379),"authorityCode":"010604","authorityName":"流水"+stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0107","businessName":stringUtil.language(842),"authorityCode":"010701","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0107","businessName":stringUtil.language(842),"authorityCode":"010702","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0107","businessName":stringUtil.language(842),"authorityCode":"010703","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0107","businessName":stringUtil.language(842),"authorityCode":"010704","authorityName":"流水"+stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0108","businessName":stringUtil.language(264),"authorityCode":"010801","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0108","businessName":stringUtil.language(264),"authorityCode":"010802","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0108","businessName":stringUtil.language(264),"authorityCode":"010803","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0109","businessName":stringUtil.language(265),"authorityCode":"010901","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0109","businessName":stringUtil.language(265),"authorityCode":"010902","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0109","businessName":stringUtil.language(265),"authorityCode":"010903","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0110","businessName":stringUtil.language(266),"authorityCode":"011001","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0110","businessName":stringUtil.language(266),"authorityCode":"011002","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0110","businessName":stringUtil.language(266),"authorityCode":"011003","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0111","businessName":stringUtil.language(1018),"authorityCode":"011101","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0111","businessName":stringUtil.language(1018),"authorityCode":"011102","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0111","businessName":stringUtil.language(1018),"authorityCode":"011103","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0112","businessName":stringUtil.language(705),"authorityCode":"011201","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0112","businessName":stringUtil.language(705),"authorityCode":"011202","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0112","businessName":stringUtil.language(705),"authorityCode":"011203","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0113","businessName":stringUtil.language(843),"authorityCode":"011301","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0113","businessName":stringUtil.language(843),"authorityCode":"011302","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0113","businessName":stringUtil.language(843),"authorityCode":"011303","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0114","businessName":stringUtil.language(844),"authorityCode":"011401","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0114","businessName":stringUtil.language(844),"authorityCode":"011402","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"01","moduleName":stringUtil.language(835),"businessCode":"0114","businessName":stringUtil.language(844),"authorityCode":"011403","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0201","businessName":stringUtil.language(848),"authorityCode":"020101","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0201","businessName":stringUtil.language(848),"authorityCode":"020102","authorityName":stringUtil.language(1129),"ancestors":[]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0201","businessName":stringUtil.language(848),"authorityCode":"020103","authorityName":stringUtil.language(1160),"ancestors":[]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0201","businessName":stringUtil.language(848),"authorityCode":"020104","authorityName":stringUtil.language(1113),"ancestors":[]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0202","businessName":stringUtil.language(735),"authorityCode":"020201","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0202","businessName":stringUtil.language(735),"authorityCode":"020202","authorityName":stringUtil.language(1129),"ancestors":["020101","020201"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0202","businessName":stringUtil.language(735),"authorityCode":"020203","authorityName":stringUtil.language(1160),"ancestors":["020101","020201"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0202","businessName":stringUtil.language(735),"authorityCode":"020204","authorityName":stringUtil.language(1113),"ancestors":["020101","020201"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0203","businessName":stringUtil.language(849),"authorityCode":"020301","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0203","businessName":stringUtil.language(849),"authorityCode":"020302","authorityName":stringUtil.language(1129),"ancestors":["020101","020301"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0203","businessName":stringUtil.language(849),"authorityCode":"020303","authorityName":stringUtil.language(1160),"ancestors":["020101","020301"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0203","businessName":stringUtil.language(849),"authorityCode":"020304","authorityName":stringUtil.language(1113),"ancestors":["020101","020301"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0204","businessName":stringUtil.language(850),"authorityCode":"020401","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0204","businessName":stringUtil.language(850),"authorityCode":"020402","authorityName":stringUtil.language(1144),"ancestors":["020101","020401"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0204","businessName":stringUtil.language(850),"authorityCode":"020403","authorityName":stringUtil.language(1160),"ancestors":["020101","020401"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0204","businessName":stringUtil.language(850),"authorityCode":"020404","authorityName":stringUtil.language(1113),"ancestors":["020101","020401"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0205","businessName":stringUtil.language(851),"authorityCode":"020501","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0205","businessName":stringUtil.language(851),"authorityCode":"020502","authorityName":stringUtil.language(1144),"ancestors":["020101","020501"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0205","businessName":stringUtil.language(851),"authorityCode":"020503","authorityName":stringUtil.language(1160),"ancestors":["020101","020501"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0205","businessName":stringUtil.language(851),"authorityCode":"020504","authorityName":stringUtil.language(1113),"ancestors":["020101","020501"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0206","businessName":stringUtil.language(599),"authorityCode":"020601","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0206","businessName":stringUtil.language(599),"authorityCode":"020602","authorityName":stringUtil.language(1144),"ancestors":["020101","020601"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0206","businessName":stringUtil.language(599),"authorityCode":"020603","authorityName":stringUtil.language(1160),"ancestors":["020101","020601"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0206","businessName":stringUtil.language(599),"authorityCode":"020604","authorityName":stringUtil.language(1113),"ancestors":["020101","020601"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0207","businessName":stringUtil.language(852),"authorityCode":"020701","authorityName":stringUtil.language(1171),"ancestors":["020101"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0207","businessName":stringUtil.language(852),"authorityCode":"020702","authorityName":stringUtil.language(1129),"ancestors":["020101","020701"]},
    {"moduleCode":"02","moduleName":stringUtil.language(845),"businessCode":"0207","businessName":stringUtil.language(852),"authorityCode":"020703","authorityName":stringUtil.language(1113),"ancestors":["020101","020701"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0301","businessName":stringUtil.language(509),"authorityCode":"030101","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0301","businessName":stringUtil.language(509),"authorityCode":"030102","authorityName":stringUtil.language(1129),"ancestors":["030101","020401"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0301","businessName":stringUtil.language(509),"authorityCode":"030103","authorityName":stringUtil.language(1160),"ancestors":["030101","020401"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0301","businessName":stringUtil.language(509),"authorityCode":"030104","authorityName":stringUtil.language(1113),"ancestors":["030101"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0302","businessName":stringUtil.language(477),"authorityCode":"030201","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0302","businessName":stringUtil.language(477),"authorityCode":"030202","authorityName":stringUtil.language(1129),"ancestors":["030201"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0302","businessName":stringUtil.language(477),"authorityCode":"030203","authorityName":stringUtil.language(1160),"ancestors":["030201"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0302","businessName":stringUtil.language(477),"authorityCode":"030204","authorityName":stringUtil.language(1113),"ancestors":["030201"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0303","businessName":stringUtil.language(510),"authorityCode":"030301","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0303","businessName":stringUtil.language(510),"authorityCode":"030302","authorityName":stringUtil.language(1129),"ancestors":["030301","020101"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0303","businessName":stringUtil.language(510),"authorityCode":"030303","authorityName":stringUtil.language(1160),"ancestors":["030301","020101"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0303","businessName":stringUtil.language(510),"authorityCode":"030304","authorityName":stringUtil.language(1113),"ancestors":["030301"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0304","businessName":stringUtil.language(443),"authorityCode":"030401","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0304","businessName":stringUtil.language(443),"authorityCode":"030402","authorityName":stringUtil.language(1129),"ancestors":["030401"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0304","businessName":stringUtil.language(443),"authorityCode":"030403","authorityName":stringUtil.language(1160),"ancestors":["030401"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0304","businessName":stringUtil.language(443),"authorityCode":"030404","authorityName":stringUtil.language(1113),"ancestors":["030401"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0305","businessName":stringUtil.language(304),"authorityCode":"030501","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0305","businessName":stringUtil.language(304),"authorityCode":"030502","authorityName":stringUtil.language(1129),"ancestors":["030501"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0305","businessName":stringUtil.language(304),"authorityCode":"030503","authorityName":stringUtil.language(1160),"ancestors":["030501"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0305","businessName":stringUtil.language(304),"authorityCode":"030504","authorityName":stringUtil.language(1113),"ancestors":["030501"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0306","businessName":stringUtil.language(445),"authorityCode":"030601","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0306","businessName":stringUtil.language(445),"authorityCode":"030602","authorityName":stringUtil.language(1129),"ancestors":["030601"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0306","businessName":stringUtil.language(445),"authorityCode":"030603","authorityName":stringUtil.language(1160),"ancestors":["030601"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0306","businessName":stringUtil.language(445),"authorityCode":"030604","authorityName":stringUtil.language(1113),"ancestors":["030601"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0307","businessName":stringUtil.language(446),"authorityCode":"030701","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0307","businessName":stringUtil.language(446),"authorityCode":"030702","authorityName":stringUtil.language(1129),"ancestors":["030701"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0307","businessName":stringUtil.language(446),"authorityCode":"030703","authorityName":stringUtil.language(1160),"ancestors":["030701"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0307","businessName":stringUtil.language(446),"authorityCode":"030704","authorityName":stringUtil.language(1113),"ancestors":["030701"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0308","businessName":stringUtil.language(663),"authorityCode":"030801","authorityName":stringUtil.language(1171),"ancestors":[]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0308","businessName":stringUtil.language(663),"authorityCode":"030802","authorityName":stringUtil.language(1129),"ancestors":["030801"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0308","businessName":stringUtil.language(663),"authorityCode":"030803","authorityName":stringUtil.language(1160),"ancestors":["030801"]},
    {"moduleCode":"03","moduleName":stringUtil.language(846),"businessCode":"0308","businessName":stringUtil.language(663),"authorityCode":"030804","authorityName":stringUtil.language(1113),"ancestors":["030801"]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0401","businessName":stringUtil.language(480),"authorityCode":"040101","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0402","businessName":stringUtil.language(230),"authorityCode":"040201","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0403","businessName":stringUtil.language(284),"authorityCode":"040301","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0404","businessName":stringUtil.language(657),"authorityCode":"040401","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0405","businessName":stringUtil.language(658),"authorityCode":"040501","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0406","businessName":stringUtil.language(200),"authorityCode":"040601","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0407","businessName":stringUtil.language(659),"authorityCode":"040701","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0408","businessName":stringUtil.language(598),"authorityCode":"040801","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0409","businessName":stringUtil.language(221),"authorityCode":"040901","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0410","businessName":stringUtil.language(735),"authorityCode":"041001","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0411","businessName":stringUtil.language(853),"authorityCode":"041101","authorityName":stringUtil.language(1170),"ancestors":[]},
    {"moduleCode":"04","moduleName":stringUtil.language(847),"businessCode":"0412","businessName":stringUtil.language(213),"authorityCode":"041201","authorityName":stringUtil.language(1170),"ancestors":[]}
  ];
  return authorityList;
}

/*权限列表分类整理
返回的Json：
[
    {
        moduleCode:"01",
        moduleName:"信息模块",
        childrens:[
            {
                businessCode:"0101",
                businessName:stringUtil.language(759)",
                childrens:[
                    {"moduleCode":"01","moduleName":"信息模块","businessCode":"0101","businessName":stringUtil.language(759)","authorityCode":"010101","authorityName":stringUtil.language(1092)","ancestors":[]},
                ],
                isOpen:false,
            },
        ],
        isOpen:false,//是否打开/选中
    },
]*/
export function authorityListFormat(authorityList){
    const moduleLayer =dataJsonFormat(authorityList, function (item) { return [item.moduleCode]; });
    let resultData = [];
    for(let i=0;i<moduleLayer.length;i++){
      let moduleArray = moduleLayer[i];
      const businessLayer =dataJsonFormat(moduleArray, function (item) { return [item.businessCode]; });
      let moduleArrayTemp = [];
      for(let j=0;j<businessLayer.length;j++){
        let authorityArray = businessLayer[j];
        let businessItem = {
          businessCode: authorityArray[0].businessCode,
          businessName: authorityArray[0].businessName,
          childrens:authorityArray,
          isOpen:false
        }
        moduleArrayTemp.push(businessItem);
      }
      let moduleItem = {
        moduleCode: moduleArrayTemp[0].childrens[0].moduleCode,
        moduleName: moduleArrayTemp[0].childrens[0].moduleName,
        childrens:moduleArrayTemp,
        isOpen:false
      }
      resultData.push(moduleItem);
    }
    return resultData;
  }

  /*arrayData：分组的数据源
  func：function(item){return [item.key];}按照item.key进行分组*/
  export function dataJsonFormat(arrayData,func){
    const groups = {};        
    arrayData.forEach(function (obj) { //stringUtil.language(1187)意这里必须是forEach 大写
        const group = JSON.stringify(func(obj));
        groups[group] = groups[group] || [];
        groups[group].push(obj);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    }); 
  }

  /*权限查找。authorityCodeParam：要查找的权限{stringUtil.language(1156)}
  {otherUtil.authorityFindCode("010101")!=null?要显示的内容:<View style={{position:'absolute'}}/>}*/
  export function authorityFindCode(authorityCodeParam){
    if (localStorage.getItem("currentStallItem")==null || JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0) {//登录角色为老板，不限制权限
      return {};
    }
    let authorityArray = JSON.parse(localStorage.getItem("authorityDatas"));
    if (authorityArray!=null && authorityArray.length>0) {
      for(let i=0;i<authorityArray.length;i++){
        if(authorityCodeParam.length==2){//模块
          if(authorityCodeParam==authorityArray[i].substring(0,2))return {};
        }else if(authorityCodeParam.length==4){//功能
          if(authorityCodeParam==authorityArray[i].substring(0,4))return {};
        }else{//某个具体的权限{stringUtil.language(1156)}
          if(authorityCodeParam == authorityArray[i]){
            // for(let j=0;j<getAllAuthoritys().length;j++){
            //   if (authorityCodeParam==getAllAuthoritys()[j].authorityCode) {
            //     return getAllAuthoritys()[j];//返回完整的权限信息
            //   }
            // }
            return {};
          }
        }
      }
      return null;
    }else{
      return null;
    }
  }

  //加解{stringUtil.language(1183)}。tag:0加{stringUtil.language(1183)},1解{stringUtil.language(1183)}  str:encodeURIComponent('加{stringUtil.language(1183)}的数据'))
  export function encDec(str,tag=0){str = str+'';
    let output = '',chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    if(tag==0){
      for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || (map = '=', i % 1); output += map.charAt(63 & block >> 8 - i % 1 * 8)) {
        charCode = str.charCodeAt(i += 3/4);
        if (charCode > 0xFF) throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
        block = block << 8 | charCode;
      }
    }else if(tag==1){
      str = str.replace(/=+$/, '');
      if (str.length % 4 == 1) throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
      for (let bc = 0, bs = 0, buffer, i = 0; buffer = str.charAt(i++); ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer, bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0 ) {
        buffer = chars.indexOf(buffer);
      }
    }
    return output;
  }

  //stringUtil.language(509)确认{stringUtil.language(1189)}null处理
  export function cusCollectInfoNull(customerCollectPSDO){
    let collectInfoRes = {
      printTitle:customerCollectPSDO.printTitle?customerCollectPSDO.printTitle:'',//stringUtil.language(807)
      time:arithUtil.formatToTimeStr(new Date(customerCollectPSDO.time),'Y.M.D h:m:s'),
      customerName:customerCollectPSDO.customerName?customerCollectPSDO.customerName:'',//stringUtil.language(1048)stringUtil.language(1089)
      customerArrears:customerCollectPSDO.customerArrears,//stringUtil.language(1048)stringUtil.language(1127)。stringUtil.language(1081)=stringUtil.language(1127)+stringUtil.language(1185)+折
      collectMoney:customerCollectPSDO.collectMoney,//stringUtil.language(674)
      discountMoney:customerCollectPSDO.discountMoney,//stringUtil.language(1042)stringUtil.language(1041)
      shopAdname:customerCollectPSDO.shopAdname?customerCollectPSDO.shopAdname:'',//stringUtil.language(1030)stringUtil.language(386)
      shopAddress:customerCollectPSDO.shopAddress?customerCollectPSDO.shopAddress:'',//stringUtil.language(1030)stringUtil.language(387)
      workerId:customerCollectPSDO.workerId,//0stringUtil.language(431)
      workerName:customerCollectPSDO.workerName?customerCollectPSDO.workerName:'',//stringUtil.language(708)
      workerMobile:customerCollectPSDO.workerMobile?customerCollectPSDO.workerMobile:'',//stringUtil.language(1031)stringUtil.language(1073)
    };
    return collectInfoRes;
  }
  
  //{stringUtil.language(865)}{stringUtil.language(1045)}null处理
  export function wholesaleNull(wholesaleInfo){
    let wholesaleInfoRes = {
      weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
      wholesaleId:encDec(wholesaleInfo.id),
      state:wholesaleInfo.state,//0 stringUtil.language(1145) 1 stringUtil.language(1144)
      printTitle:wholesaleInfo.printTitle?wholesaleInfo.printTitle:'',//stringUtil.language(807)
      marketName:wholesaleInfo.marketName?wholesaleInfo.marketName:'',//市场stringUtil.language(1089)
      customerId:encDec(wholesaleInfo.customerId),
      customerName:wholesaleInfo.customerName?wholesaleInfo.customerName:'',//stringUtil.language(1048)stringUtil.language(1089)
      customerAlias:wholesaleInfo.customerAlias?wholesaleInfo.customerAlias:'',//临时stringUtil.language(1048)别名
      customerMobile:wholesaleInfo.customerMobile?wholesaleInfo.customerMobile:'',//stringUtil.language(1048)stringUtil.language(1073)
      customerType:wholesaleInfo.customerType,//0stringUtil.language(1100) 1临时
      createTime:arithUtil.formatToTimeStr(new Date(wholesaleInfo.createTime),'Y.M.D h:m'),//创建stringUtil.language(1039)
      createTimeTh:arithUtil.formatToTimeStr(new Date(wholesaleInfo.createTime),'D-M-Y'),//stringUtil.language(856)
      finishTime:arithUtil.formatToTimeStr(new Date(wholesaleInfo.finishTime),'Y.M.D h:m'),//完成stringUtil.language(1039)
      customerAdname:wholesaleInfo.customerAdname?wholesaleInfo.customerAdname:'',//stringUtil.language(386)stringUtil.language(1089)
      customerAddress:wholesaleInfo.customerAddress?wholesaleInfo.customerAddress:'',//stringUtil.language(1048)地址
      wholesaleNo:wholesaleInfo.wholesaleNo?wholesaleInfo.wholesaleNo:'',//stringUtil.language(1040)
      commodities:[],//stringUtil.language(1063)列表
      isShowOwnerCargo:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).ownerCargo?1:0,
      isShowOther:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther?JSON.parse(localStorage.getItem("currentStallItem")).isShowOther:0,//0合并
      isShowOwnerCargoOther:localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).ownerCargoOther?1:0,
      newestArrears:wholesaleInfo.newestArrears,//stringUtil.language(1030)新欠
      customerArrears:wholesaleInfo.customerArrears,//stringUtil.language(1030)stringUtil.language(1127)，stringUtil.language(1081)=customerArrears-本{stringUtil.language(1189)}stringUtil.language(1041)
      warehouseName:wholesaleInfo.warehouseName?wholesaleInfo.warehouseName:'',//stringUtil.language(1064)
      shopId:encDec(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0),
      shopName:wholesaleInfo.shopName?wholesaleInfo.shopName:'',//stringUtil.language(1030)名
      shopMobile:wholesaleInfo.shopMobile?wholesaleInfo.shopMobile:'',//stringUtil.language(1030)stringUtil.language(1073)
      shopAdname:wholesaleInfo.shopAdname?wholesaleInfo.shopAdname:'',//stringUtil.language(1030)stringUtil.language(386)
      shopAddress:wholesaleInfo.shopAddress?wholesaleInfo.shopAddress:'',//stringUtil.language(1030)stringUtil.language(387)
      workerId:encDec(wholesaleInfo.workerId),
      workerName:wholesaleInfo.workerId==0?stringUtil.language(431):wholesaleInfo.workerName?wholesaleInfo.workerName:'',//stringUtil.language(970)
      operaterName:wholesaleInfo.operaterId==0?stringUtil.language(431):wholesaleInfo.operaterName?wholesaleInfo.operaterName:'',//stringUtil.language(987)
      workerMobile:wholesaleInfo.workerMobile?wholesaleInfo.workerMobile:'',//stringUtil.language(970)stringUtil.language(1073)
      billMoney:wholesaleInfo.billMoney,//取整的差值
      costs:[],//stringUtil.language(335)列表
      wholesaleMoneys:[],//stringUtil.language(674)明细
      advanceMoney:wholesaleInfo.advanceMoney,//stringUtil.language(942)
      money:wholesaleInfo.money,//本{stringUtil.language(1189)}总额
      collectionFinish:wholesaleInfo.collectionFinish,//0否,1是
      describe:wholesaleInfo.describe?wholesaleInfo.describe:'',
      printWholesaleText:wholesaleInfo.printWholesaleText?wholesaleInfo.printWholesaleText:'',
      workerPrint:localStorage.getItem("currentUserItem")?JSON.parse(localStorage.getItem("currentUserItem")).noteName:'',//{stringUtil.language(1045)}人
      currTime:arithUtil.formatToTimeStr(new Date(),'Y.M.D h:m'),//{stringUtil.language(1045)}的stringUtil.language(1039)
      payQrcode:wholesaleInfo.payQrcode?wholesaleInfo.payQrcode:'',
      buyerNames:wholesaleInfo.buyerNames?wholesaleInfo.buyerNames:[]
    };
    for(let i=0;i<wholesaleInfo.commodities.length;i++){//stringUtil.language(1063)列表
      let commoditiesTemp={
        commodityName:wholesaleInfo.commodities[i].commodityName?wholesaleInfo.commodities[i].commodityName:'',//stringUtil.language(969)
        commodityLevelId:wholesaleInfo.commodities[i].commodityLevelId,
        commodityLevelName:wholesaleInfo.commodities[i].commodityLevelName?wholesaleInfo.commodities[i].commodityLevelName:'',
        commodityCode:wholesaleInfo.commodities[i].commodityCode?wholesaleInfo.commodities[i].commodityCode:'',
        supplementaryCommodity:wholesaleInfo.commodities[i].supplementaryCommodity?1:0,//1stringUtil.language(1169)
        targetType:wholesaleInfo.commodities[i].targetType,//0 stringUtil.language(1049) 1 stringUtil.language(1030) 2 stringUtil.language(1031)
        targetName:wholesaleInfo.commodities[i].targetName?wholesaleInfo.commodities[i].targetName:'',//stringUtil.language(1049)
        cargoNo:wholesaleInfo.commodities[i].cargoNo?wholesaleInfo.commodities[i].cargoNo:'',//stringUtil.language(1084)
        number:wholesaleInfo.commodities[i].number,//stringUtil.language(1165)
        weight:wholesaleInfo.commodities[i].weight,//stringUtil.language(1168)
        totalWeight:wholesaleInfo.commodities[i].totalWeight,//stringUtil.language(1166)
        skinWeight:wholesaleInfo.commodities[i].skinWeight,//stringUtil.language(1167)
        reduceWeight:wholesaleInfo.commodities[i].reduceWeight,//stringUtil.language(1025)
        standard:wholesaleInfo.commodities[i].standard,//stringUtil.language(1024)
        unitPrice:wholesaleInfo.commodities[i].unitPrice,//stringUtil.language(1050)
        totalUnitPrice:wholesaleInfo.commodities[i].totalUnitPrice,//stringUtil.language(1016)
        commodityUnitSlave:wholesaleInfo.commodities[i].commodityUnitSlave?wholesaleInfo.commodities[i].commodityUnitSlave:stringUtil.language(1173),//stringUtil.language(770)
        commodityUnitScale:wholesaleInfo.commodities[i].commodityUnitScale?wholesaleInfo.commodities[i].commodityUnitScale:1,//比例
        commodityUnitMaster:wholesaleInfo.commodities[i].commodityUnitMaster?wholesaleInfo.commodities[i].commodityUnitMaster:stringUtil.language(1173),//stringUtil.language(769)
        mode:wholesaleInfo.commodities[i].mode,//0stringUtil.language(1114)，1stringUtil.language(1115)
        goodsMoney:wholesaleInfo.commodities[i].goodsMoney,//stringUtil.language(1068)
        buyerName:wholesaleInfo.commodities[i].buyerName?wholesaleInfo.commodities[i].buyerName:'',//stringUtil.language(891)名字
        describe:wholesaleInfo.commodities[i].describe?wholesaleInfo.commodities[i].describe:'',
        costs:[],//stringUtil.language(334)列表
      }
      if(wholesaleInfo.commodities[i].costs==null) wholesaleInfo.commodities[i].costs=[];
      let otherMoneyTotal=0;
      for(let j=0;j<wholesaleInfo.commodities[i].costs.length;j++){
        commoditiesTemp.costs.push({
          costClauseName:wholesaleInfo.commodities[i].costs[j].costClauseName?wholesaleInfo.commodities[i].costs[j].costClauseName:'',
          money:wholesaleInfo.commodities[i].costs[j].money,
          payee:wholesaleInfo.commodities[i].costs[j].payee//stringUtil.language(1038)方，0.stringUtil.language(1030) 1.stringUtil.language(1049) 2.stringUtil.language(1031)
        });
        otherMoneyTotal=otherMoneyTotal.add(wholesaleInfo.commodities[i].costs[j].money);
      }
      if(!(localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther)&&otherMoneyTotal){commoditiesTemp.goodsMoney=otherMoneyTotal.add(commoditiesTemp.goodsMoney);
        if(commoditiesTemp.mode)commoditiesTemp.unitPrice=commoditiesTemp.number?(commoditiesTemp.goodsMoney).div(commoditiesTemp.number):0;
        else commoditiesTemp.unitPrice=commoditiesTemp.weight?(commoditiesTemp.goodsMoney).div(arithUtil.keToWeight(commoditiesTemp.weight,0)):0;//20230603
      }else if(localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).isShowOther==2){
        commoditiesTemp.goodsMoney=otherMoneyTotal.add(commoditiesTemp.goodsMoney);
      }
      wholesaleInfoRes.commodities.push(commoditiesTemp);
    }
    if(wholesaleInfo.costs==null) wholesaleInfo.costs=[];
    for(let i=0;i<wholesaleInfo.costs.length;i++){//stringUtil.language(335)列表
      wholesaleInfoRes.costs.push({
        costClauseName:wholesaleInfo.costs[i].costClauseName?wholesaleInfo.costs[i].costClauseName:'',
        money:wholesaleInfo.costs[i].money,
        payee:wholesaleInfo.costs[i].payee//stringUtil.language(1038)方，0.stringUtil.language(1030) 1.stringUtil.language(1049) 2.stringUtil.language(1031)
      });
    }
    if(wholesaleInfo.wholesaleMoneys==null) wholesaleInfo.wholesaleMoneys=[];
    for(let i=0;i<wholesaleInfo.wholesaleMoneys.length;i++){//stringUtil.language(674)明细
      let wholesaleMoneysTemp={
        targetName:wholesaleInfo.wholesaleMoneys[i].targetName?wholesaleInfo.wholesaleMoneys[i].targetName:'',
        wholesaleCollects:[]
      };
      if(wholesaleInfo.wholesaleMoneys[i].wholesaleCollects==null) wholesaleInfo.wholesaleMoneys[i].wholesaleCollects=[];
      for(let j=0;j<wholesaleInfo.wholesaleMoneys[i].wholesaleCollects.length;j++){
        wholesaleMoneysTemp.wholesaleCollects.push({
          accountType:wholesaleInfo.wholesaleMoneys[i].wholesaleCollects[j].accountType,//0.stringUtil.language(1093) 1.stringUtil.language(1094) 2.stringUtil.language(924) 3.stringUtil.language(925)
          discountMoney:wholesaleInfo.wholesaleMoneys[i].wholesaleCollects[j].discountMoney,//stringUtil.language(1042)
          collectMoney:wholesaleInfo.wholesaleMoneys[i].wholesaleCollects[j].collectMoney//stringUtil.language(943)
        });
      }
      wholesaleInfoRes.wholesaleMoneys.push(wholesaleMoneysTemp);
    }
    return wholesaleInfoRes;
  }

  //{stringUtil.language(1071)}Excel
  export function createExcel(content,title='数据报表'){
    let resArr=new Array();
    resArr.push(`<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
      <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
      <x:Name>${title}</x:Name>
      <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
      </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
      </head><body><table>${content}</table></body></html>`);
    let printmodelStr=resArr.join('');
    var urlObject = window.URL || window.webkitURL || window;
    var downloadData = new Blob([printmodelStr]);
    var save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
    save_link.href = urlObject.createObjectURL(downloadData);
    save_link.download = title+'.xls';
    var ev = document.createEvent("MouseEvents");
    ev.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(ev);
  }